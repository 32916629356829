import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import SideDrawer from './SideDrawer';
import '../styles/menu.css';
import Menu from '../assets/menu.svg';

import LogoWithWhiteText from '../assets/logoWithWhiteText.svg';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { STRINGS } from '../utils/CONSTANTS';
import curiosityTriggers from '../assets/curiosityTrigger.svg';
import LearningImage from '../assets/activities.png';
import TipsImage from '../assets/engagemenTips.svg';
import QuestionImage from '../assets/question.svg';
import LessonPlanImage from '../assets/lesson.svg';
import { getCategoriesData } from '../apis/callAPi';
import CardComponent from './CardComponent';
import { ALLNAMES } from '../utils/NAMES';
import COLORS from '../utils/COLORS';
import Login from './Login';
import Logout from '../assets/logout.svg';
import Edit from '../assets/edit.svg';
import { useCookies } from 'react-cookie';
// import { updatingCookies } from '../utils/common-utils';
import { strings } from '../utils/strings';
import Arrow from '../assets/nextIcon.png';
import EditName from './EditName';
import { userLogout } from '../apis/auth';
import { useNavigate } from 'react-router-dom';
import CustomModal from './CustomModal';
import { routesLinks } from '../utils/RouteConstants';

function MenuBar() {
  const { userInfo } = useAuth();
  const navigate = useNavigate();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [aicardsOpen, setAiCardsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
    // navigate(routesLinks.login);
  };
  const [cookies, removeCookie] = useCookies();
  const [isHovered, setIsHovered] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navLinkStyle = {
    color: 'white',
  };
  const handleLogout = async () => {
    let response = await userLogout();
    if (response) {
      localStorage.clear();
      // updatingCookies(setCookie, 0);
      removeCookie('preferences');
      window.location.reload();
    }
  };
  const handleAssistantClick = () => {
    setShowDropdownContent(!showDropdownContent);
    setAiCardsOpen(false);
    setShowUserMenu(false);
  };
  const handleUserMenuClick = () => {
    setShowUserMenu(!showUserMenu);
    setAiCardsOpen(false);
    setShowDropdownContent(false);
  };

  const handleAiClick = () => {
    setAiCardsOpen(!aicardsOpen);
    setShowDropdownContent(false);
    setShowUserMenu(false);
  };
  const containerRef = useRef(null);
  const aiRef = useRef(null);
  const userRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        showDropdownContent &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDropdownContent(false);
      } else if (
        aicardsOpen &&
        aiRef.current &&
        !aiRef.current.contains(event.target)
      ) {
        setAiCardsOpen(false);
      } else if (
        showUserMenu &&
        userRef.current &&
        !userRef.current.contains(event.target)
      ) {
        setShowUserMenu(false);
      }
    };

    if (showDropdownContent || aicardsOpen || showUserMenu) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showDropdownContent, aicardsOpen, showUserMenu]);
  useEffect(() => {
    const handleScroll = () => {
      if (showDropdownContent) {
        setShowDropdownContent(false);
      }
      if (aicardsOpen) {
        setAiCardsOpen(false);
      }
      if (showUserMenu) {
        setShowUserMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showDropdownContent, aicardsOpen, showUserMenu]);
  useEffect(() => {
    //added some delay , same api is triggered in Home page
    setTimeout(() => {
      fetchCategoriesData();
    }, 1000);
    false && handleLogout();
    console.log(cookies);
  }, []);
  const fetchCategoriesData = async () => {
    const response = await getCategoriesData();
    if (response?.success) {
      let res = response?.data?.map((item) => {
        if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.curiosity_triggers?.name)
        ) {
          return {
            ...item,
            image: curiosityTriggers,
            action: STRINGS?.home?.curiosity_triggers?.action,
            navigation: STRINGS?.home?.curiosity_triggers?.navigation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.question_bank?.name)
        ) {
          return {
            ...item,
            image: QuestionImage,
            action: STRINGS?.home?.question_bank?.action,
            navigation: STRINGS?.home?.question_bank?.navigation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.lesson_plan?.name)
        ) {
          return {
            ...item,
            image: LessonPlanImage,
            action: STRINGS?.home?.lesson_plan?.action,
            navigation: STRINGS?.home?.lesson_plan?.navigation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.learning_activities?.name)
        ) {
          return {
            ...item,
            image: LearningImage,
            action: STRINGS?.home?.learning_activities?.action,
            navigation: STRINGS?.home?.learning_activities?.navigation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.student_tips?.name)
        ) {
          return {
            ...item,
            image: TipsImage,
            action: STRINGS?.home?.student_tips?.action,
            navigation: STRINGS?.home?.student_tips?.navigation,
          };
        }
      });
      let first = res?.filter((item) => item.displaytext != 'Lesson Plan');
      let second = res?.filter((item) => item.displaytext == 'Lesson Plan');
      res = [...second, ...first];
      setCategories(res);

      //  setCategories(res);
      let allCards = [...res];
      STRINGS?.home?.everyday_ai_cards?.map((item) => allCards.push(item));

      const indexToMove = allCards.findIndex(
        (obj) => obj.displaytext === strings.titles.question_paper,
      );
      let finalArray = [];
      if (indexToMove !== -1) {
        finalArray = [
          ...allCards.slice(0, indexToMove),
          ...allCards.slice(indexToMove + 1),
          allCards[indexToMove],
        ];
      }
      setAllCategories(finalArray);
    }
  };
  const handleNavigate = () => {
    const { teacher, home } = routesLinks;
    if (window.location.pathname == teacher) {
      navigate(home);
    } else {
      navigate(teacher);
    }
  };
  const websiteLogo = () => {
    return (
      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="custom-toggler-icon"
        onClick={() => setShowDrawer(!showDrawer)}
        style={{ border: 'none' }}
      >
        <img
          src={Menu}
          alt="Toggle Icon"
          className="d-inline-block align-top"
          style={{ objectFit: 'contain', width: '24px', height: '24px' }}
        />
      </Navbar.Toggle>
    );
  };

  const webview = () => {
    return (
      <Navbar expand="lg">
        <div className="d-flex align-items-center">
          {websiteLogo()}
          <Navbar.Brand className="text-left ml-3">
            <img
              src={LogoWithWhiteText}
              onClick={handleNavigate}
              alt="Coschool"
              className="web-logo"
            />
          </Navbar.Brand>
        </div>
        <Navbar.Collapse id="navbar-nav" style={{ marginLeft: '64px' }}>
          <Nav className="mr-auto d-none d-lg-flex">
            <div className="custom-dropdown" ref={containerRef}>
              <Nav.Link
                style={{
                  ...navLinkStyle,
                  marginRight: '24px',
                  position: 'relative',
                }}
                onClick={() => {
                  handleAssistantClick();
                }}
              >
                <div style={{ display: 'flex' }}>
                  <h4 className="assistant-menu-title">
                    {ALLNAMES?.AIASSISTANT}
                  </h4>
                  <RiArrowDropDownLine size={24} color={COLORS.WHITE} />
                </div>
              </Nav.Link>
            </div>
            <div className="custom-dropdown" ref={aiRef}>
              <Nav.Link
                style={{
                  ...navLinkStyle,
                  marginRight: '24px',
                  position: 'relative',
                }}
                onClick={() => {
                  handleAiClick();
                }}
              >
                <div style={{ display: 'flex' }}>
                  <h4
                    className="everyday-ai-menu-title"
                    style={{ color: 'white' }}
                  >
                    {ALLNAMES?.EVERYDAYAI}
                  </h4>
                  <RiArrowDropDownLine size={24} color={COLORS.WHITE} />
                </div>
              </Nav.Link>
            </div>
            {/* <Nav.Link
              style={{ ...navLinkStyle, marginRight: '24px' }}
              href="/about-us"
            >
              <h4 className="assistant-menu-title">{ALLNAMES?.ABOUT_US}</h4>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
        <Nav className="ml-auto">
          {userInfo?.name ? (
            <div>{loginView()}</div>
          ) : (
            <div>{loginButton()}</div>
          )}
        </Nav>
      </Navbar>
    );
  };

  const loginView = () => {
    return (
      <div ref={userRef}>
        <Nav.Link
          className="user-name-container"
          onClick={() => {
            handleUserMenuClick();
          }}
        >
          <div className="assistant-menu-title">{userInfo?.name}</div>
          <RiArrowDropDownLine size={24} color={'#B1E454'} />
        </Nav.Link>
        {showUserMenu && (
          <div style={{ position: 'absolute', right: '16px' }}>
            <div
              className="user-menu"
              onClick={() => {
                setShowUserMenu(false);
                setEditModal(true);
              }}
            >
              <img src={Edit} className="user-menu-icon" />
              <div className="user-menu-item">{'Edit Name'}</div>
            </div>
            <div
              className="user-menu"
              onClick={() => {
                handleLogout();
              }}
            >
              <img src={Logout} className="user-menu-icon" />
              <div className="user-menu-item">{'Logout'}</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const loginButton = () => {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleShowModal}
        className={
          isHovered && isDesktop
            ? `cus-btn-container-hover`
            : `cus-btn-container`
        }
      >
        <div className="button-text">{`Login`}</div>
        {isHovered && isDesktop && (
          <img src={Arrow} alt="Login" className="arrow-icon" />
        )}
      </div>
    );
  };
  const mobileView = () => {
    return (
      <Navbar expand="lg">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            {websiteLogo()}
            <Navbar.Brand className="text-left ml-2">
              <img
                src={LogoWithWhiteText}
                onClick={handleNavigate}
                alt="Coschool"
                className="mobile-logo"
              />
            </Navbar.Brand>
          </div>
          <Nav className="ms-auto">
            {userInfo?.name ? (
              <div>{loginView()}</div>
            ) : (
              <div>{loginButton()}</div>
            )}
          </Nav>
        </div>
      </Navbar>
    );
  };
  const CardList = ({ cards, toStyle, dropdownSet, type }) => {
    let response = [...cards];
    if (type == 'ai assistant') {
      const indexToMove = cards.findIndex(
        (obj) => obj.displaytext === strings.titles.question_paper,
      );
      response = [];
      if (indexToMove !== -1) {
        response = [
          ...cards.slice(0, indexToMove),
          ...cards.slice(indexToMove + 1),
          cards[indexToMove],
        ];
      }
    }
    return (
      <div
        style={{ display: 'flex', gap: '10px', backgroundColor: 'transparent' }}
      >
        {response?.map((item, index) => (
          <CardComponent
            module={STRINGS?.home?.Menu}
            key={index}
            data={item}
            cardHighlightClassName={toStyle}
            setDropdownOpen={dropdownSet}
            setShowWarning={setShowWarningModal}
          />
        ))}
      </div>
    );
  };
  return (
    <Container>
      {isDesktop ? webview() : mobileView()}
      <SideDrawer
        show={showDrawer}
        onHide={() => {
          setShowDrawer(false);
        }}
        allCategories={allCategories}
      />
      {showDropdownContent && isDesktop && (
        <div className="menu-dropdown-content">
          <CardList
            cards={categories}
            toStyle={'card-assistant-menu'}
            dropdownSet={setShowDropdownContent}
            type={'ai assistant'}
          />
        </div>
      )}
      {aicardsOpen && isDesktop && (
        <div className="menu-dropdown-content">
          <CardList
            cards={STRINGS?.home?.everyday_ai_cards}
            toStyle={'card-everyday'}
            dropdownSet={setAiCardsOpen}
          />
        </div>
      )}
      {showModal && (
        <Login
          show={showModal}
          onHide={() => {
            setShowModal(false);
            // navigate(routesLinks.teacher);
          }}
        />
      )}
      {editModal && (
        <EditName
          show={editModal}
          onHide={() => {
            setEditModal(false);
          }}
        />
      )}
      {showWarningModal && (
        <CustomModal
          showModal={showWarningModal}
          onHide={() => {
            setShowWarningModal(false);
          }}
        />
      )}
    </Container>
  );
}

export default MenuBar;
