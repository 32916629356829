/* eslint-disable */

import React, { useEffect, useRef } from "react";
import styles from "../../pages/UnifiedHome/styles/school-enquiry.module.css";
import NavBar from "./NavBar";
import SchoolEnquiryForm from "./SchoolEnquiryForm";
import Vin from "../../assets/common-home/vin.svg";
import { useLocation } from "react-router-dom";
import { saveBrowserDetails } from "../../utils/common-utils";
import ReactPlayer from "react-player";

const vinDesc = `Congratulations on taking the first step towards becoming an AI-powered school. What's next? Fill out the details below and our school team will reach out to you.`;
const VIDEO_URL =
  "https://s3.ap-south-1.amazonaws.com/dev-ui.innerscore.com/SchoolAI+by+Coschool+-+3D+Animation+Video+-+5th+July+2024+-+For+Website.webm";

export default function SchoolEnquiry() {
  const location = useLocation();
  const playerRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    handlePlay();
    saveBrowserDetails(searchParams);
  }, []);

  const handlePlay = (isEnd = false) => {
    if (playerRef?.current) {
      if (isEnd) {
        playerRef.current.seekTo(0);
      }
      const player = playerRef.current.getInternalPlayer();
      if (player) {
        player.play();
      }
    }
  };

  return (
    <div className={styles.container}>
      <NavBar />
      <VinDesc />
      <Player
        playerRef={playerRef}
        handleVideoEnd={() => {
          handlePlay(true);
        }}
      />
      <SchoolEnquiryForm />
    </div>
  );
}

const VinDesc = () => (
  <div className={styles["vin-container"]}>
    <img src={Vin} className={styles["vin-img"]} alt="Vin" />
    <div className={styles.vin}>
      <div className={styles["vin-desc"]}>{vinDesc}</div>
    </div>
  </div>
);

const Player = ({ playerRef, handleVideoEnd }) => {
  return (
    <div className={styles["video-container"]}>
      <ReactPlayer
        ref={playerRef}
        url={VIDEO_URL}
        playing={true}
        width="650px"
        height="100%"
        controls={true}
        muted={true}
        playsinline={true}
        onEnded={handleVideoEnd}
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
              playsInline: true,
            },
          },
        }}
      />
    </div>
  );
};
