// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-left {
  margin-right: 12px;
  margin-top: 12px;
}

.icon-right {
  margin-right: 8px;
}

.doMyTask {
  height: "60px";
  width: "158px";
  background-color: "#919191";
  color: "#353535";
}

.doMyTaskActive {
  height: "60px";
  width: "158px";
  background-color: "#7ABC40";
  color: "black";
}
`, "",{"version":3,"sources":["webpack://./src/components/componentStyles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,2BAA2B;EAC3B,cAAc;AAChB","sourcesContent":[".icon-left {\n  margin-right: 12px;\n  margin-top: 12px;\n}\n\n.icon-right {\n  margin-right: 8px;\n}\n\n.doMyTask {\n  height: \"60px\";\n  width: \"158px\";\n  background-color: \"#919191\";\n  color: \"#353535\";\n}\n\n.doMyTaskActive {\n  height: \"60px\";\n  width: \"158px\";\n  background-color: \"#7ABC40\";\n  color: \"black\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
