import React from 'react';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import COLORS from '../utils/COLORS';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const prevPage = () => {
    onPageChange(currentPage - 1);
  };

  const nextPage = () => {
    onPageChange(currentPage + 1);
  };

  const goToPage = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const pageRange = 3;
  const minPage = Math.max(1, currentPage - pageRange);
  const maxPage = Math.min(totalPages, currentPage + pageRange);

  const pageNumbers = Array.from(
    { length: maxPage - minPage + 1 },
    (_, index) => minPage + index,
  );

  return (
    <div style={{ color: COLORS.WHITE }}>
      <button
        onClick={prevPage}
        disabled={currentPage === 1}
        style={buttonStyles}
      >
        <RiArrowLeftSLine size={IconSize} style={pointerEvents} />
      </button>
      {currentPage > pageRange + 1 && (
        <>
          <button onClick={() => goToPage(1)} style={buttonStyles}>
            1
          </button>
          {currentPage > pageRange + 2 && <span>...</span>}
        </>
      )}
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => goToPage(pageNumber)}
          disabled={pageNumber === currentPage}
          style={{
            ...buttonStyles,
            textDecoration: pageNumber === currentPage ? 'underline' : 'none',
          }}
        >
          {pageNumber}
        </button>
      ))}
      {currentPage + pageRange < totalPages && (
        <>
          {currentPage + pageRange < totalPages && <span>...</span>}
          <button onClick={() => goToPage(totalPages)} style={buttonStyles}>
            {totalPages}
          </button>
        </>
      )}{' '}
      <button
        onClick={nextPage}
        disabled={currentPage === totalPages}
        style={buttonStyles}
      >
        <RiArrowRightSLine size={IconSize} style={pointerEvents} />
      </button>
    </div>
  );
};

export default Pagination;
const buttonStyles = {
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  color: COLORS.WHITE,
};
const IconSize = 20;
const pointerEvents = { pointerEvents: 'none' };
