import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoArrowForward } from 'react-icons/io5';
import { STRINGS } from '../utils/CONSTANTS';

const CardComponent = ({
  module,
  data,
  cardHighlightClassName,
  setDropdownOpen,
  setShowWarning,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    //reloading page when click on navigation
    if (
      data?.displaytext
        ?.toLowerCase()
        .includes(STRINGS?.home?.question_bank?.name)
    ) {
      setShowWarning(true);
    } else {
      navigate(`${data?.navigation}`);
      setDropdownOpen(true);
      window.location.reload(false);
    }
  };
  return (
    <Card className={cardHighlightClassName} onClick={handleClick}>
      <Card.Header className="card-header">
        <img
          className="d-block"
          src={data?.image}
          alt="Second slide"
          style={{ marginLeft: '-24px' }}
        />
      </Card.Header>
      <Card.Body className="card-body">
        <Card.Title className="card-title">{data?.displaytext}</Card.Title>
        <Card.Subtitle className="card-subtitle">
          {data?.description}
        </Card.Subtitle>
      </Card.Body>
      {module != STRINGS?.home?.Menu && (
        <Card.Footer className="generate">
          {data?.action}
          <IoArrowForward
            style={{ margin: 10, fontWeight: 'bold' }}
            size={18}
          />
        </Card.Footer>
      )}
    </Card>
  );
};
export default CardComponent;
