import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/global.css';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container>
      <footer className="footer-container">
        <span className="footer">
          &copy; Copyright {year}, People Combine Basics Private Limited, All
          Rights Reserved
        </span>
      </footer>
    </Container>
  );
};
export default Footer;
