/* eslint-disable */

import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/homePage";
import PromptsList from "./pages/promptsList";
import CuriosityTriggers from "./pages/curiosityPage";
import LearnFromExperts from "./pages/aiExpert";
import ScrollToTop from "./components/ScrollToTop";
import PromptOfTheDay from "./pages/promptOfTheDay";
import { Helmet } from "react-helmet";
import { strings } from "./utils/strings";
import { useAuth } from "./context";
import UnifiedHomePage from "./pages/UnifiedHome/index";
import SchoolEnquiry from "./components/UnifiedHome/school-enquiry";
import AboutUs from "./components/UnifiedHome/about-us";
import Careers from "./components/UnifiedHome/careers";
import { routesLinks } from "./utils/RouteConstants";
import PrivacyPolicy from "./pages/UnifiedHome/privacyAndterms/privacy-policy";
import TermsAndConditions from "./pages/UnifiedHome/privacyAndterms/terms-conditions";

const App = () => {
  const location = useLocation(); // Get the location using useLocation
  const { userInfo, logout } = useAuth();

  useEffect(() => {
    handleUpdateReferrer();
    const handleCustomEvent = (event) => {
      const { message } = event.detail;
      console.log(event.detail, userInfo);
      if (message == "Logout Event") {
        logout();
      }
    };
    document.addEventListener("customLogoutEvent", handleCustomEvent);
    return () => {
      document.removeEventListener("customLogoutEvent", handleCustomEvent);
    };
  }, []);

  const handleUpdateReferrer = async () => {
    let ref = document.referrer;
    if (ref != process.env.REACT_APP_API_URL && ref != "") {
      console.log("Referrer", ref);
      await localStorage.setItem("externalRef", document.referrer);
      await localStorage.setItem("studentRef", document.referrer);
    } else {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.get("utm_source")) {
        const utmSource = urlParams.get("utm_source");
        const utmMedium = urlParams.get("utm_medium");
        const utmCampaign = urlParams.get("utm_campaign");
        const utmId = urlParams.get("utm_id");
        const utmTerm = urlParams.get("utm_term");
        const utmContent = urlParams.get("utm_content");
        let params = {
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_id: utmId,
          utm_term: utmTerm,
          utm_content: utmContent,
        };
        await localStorage.setItem("utm_params", JSON.stringify(params));
      }
    }
  };

  const {
    home,
    daily_prompt,
    prompt_library,
    curiosity_triggers,
    learning_activities,
    classroom_questions,
    enagagement_tips,
  } = strings?.seo_mapping;
  return (
    <>
      <ScrollToTop location={location} />
      <Routes>
        <Route path={routesLinks.home} element={<UnifiedHomePage />} exact />
        <Route
          path={routesLinks.teacher}
          element={
            <Layout backgroundImage="home">
              <Helmet>
                <title>{home?.title}</title>
                <meta name="description" content={home?.description} />
                <meta name="keywords" content={home?.keywords.join(",")} />
              </Helmet>
              <Home />
            </Layout>
          }
          exact
        />
        <Route
          path="/feedback"
          element={
            <Layout backgroundImage="home" show={true}>
              <Helmet>
                <title>{home?.title}</title>
                <meta name="description" content={home?.description} />
                <meta name="keywords" content={home?.keywords.join(",")} />
              </Helmet>
              <Home />
            </Layout>
          }
          exact
        />
        <Route
          path={routesLinks.promptoftheday}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{daily_prompt?.title}</title>
                <meta name="description" content={daily_prompt?.description} />
                <meta
                  name="keywords"
                  content={daily_prompt?.keywords.join(",")}
                />
              </Helmet>
              <PromptOfTheDay />
            </Layout>
          }
        />
        <Route
          path={routesLinks.promptlibrary}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{prompt_library?.title}</title>
                <meta
                  name="description"
                  content={prompt_library?.description}
                />
                <meta
                  name="keywords"
                  content={prompt_library?.keywords.join(",")}
                />
              </Helmet>
              <PromptsList />
            </Layout>
          }
        />
        <Route
          path={routesLinks.curiositytriggers}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{curiosity_triggers?.title}</title>
                <meta
                  name="description"
                  content={curiosity_triggers?.description}
                />
                <meta
                  name="keywords"
                  content={curiosity_triggers?.keywords.join(",")}
                />
              </Helmet>
              <CuriosityTriggers />
            </Layout>
          }
        />
        <Route
          path={routesLinks.learningactivities}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{learning_activities?.title}</title>
                <meta
                  name="description"
                  content={learning_activities?.description}
                />
                <meta
                  name="keywords"
                  content={learning_activities?.keywords.join(",")}
                />
              </Helmet>
              <CuriosityTriggers />
            </Layout>
          }
        />
        <Route
          path={routesLinks.classroomquestions}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{classroom_questions?.title}</title>
                <meta
                  name="description"
                  content={classroom_questions?.description}
                />
                <meta
                  name="keywords"
                  content={classroom_questions?.keywords.join(",")}
                />
              </Helmet>
              <CuriosityTriggers />
            </Layout>
          }
        />
        <Route
          path={routesLinks.engagementtips}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{enagagement_tips?.title}</title>
                <meta
                  name="description"
                  content={enagagement_tips?.description}
                />
                <meta
                  name="keywords"
                  content={enagagement_tips?.keywords.join(",")}
                />
              </Helmet>
              <CuriosityTriggers />
            </Layout>
          }
        />
        <Route
          path={routesLinks.lessonplan}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{strings?.seo_mapping?.lesson_plan?.title}</title>
                <meta
                  name="description"
                  content={strings?.seo_mapping?.lesson_plan?.description}
                />
                <meta
                  name="keywords"
                  content={strings?.seo_mapping?.lesson_plan?.keywords.join(
                    ",",
                  )}
                />
              </Helmet>
              <CuriosityTriggers />
            </Layout>
          }
        />
        <Route
          path={routesLinks.aiexpert}
          element={
            <Layout backgroundImage="other">
              <Helmet>
                <title>{strings?.seo_mapping?.ai_expert?.title}</title>
                <meta
                  name="description"
                  content={strings?.seo_mapping?.ai_expert?.description}
                />
                <meta
                  name="keywords"
                  content={strings?.seo_mapping?.ai_expert?.keywords.join(",")}
                />
              </Helmet>
              <LearnFromExperts />
            </Layout>
          }
        />
        <Route
          path={routesLinks.login}
          element={
            <Layout backgroundImage="home" isLoginRoute={true}>
              <Home />
            </Layout>
          }
        />
        <Route path="/unified-login" element={<UnifiedHomePage />} exact />
        <Route
          path={routesLinks.schoolpartner}
          element={<SchoolEnquiry />}
          exact
        />
        <Route path={routesLinks.aboutus} element={<AboutUs />} exact />
        <Route path={routesLinks.careers} element={<Careers />} exact />
        <Route path={routesLinks.privacypolicy} element={<PrivacyPolicy />} />
        <Route
          path={routesLinks.termsandconditions}
          element={<TermsAndConditions />}
        />
        <Route path="*" element={<Navigate to={routesLinks.home} />} />
      </Routes>
    </>
  );
};

export default App;
