import { handleRefreshToken } from '../apis/auth';
import { showToast } from '../components/CustomToast';
import defaultAPIClient from './Client';
import { getBrowserFingerprint } from './common-utils';

const customEvent = new CustomEvent('customLogoutEvent', {
  detail: { message: 'Logout Event' },
});

async function callApi(
  endpoint,
  method,
  body,
  { APIClient = defaultAPIClient } = {},
) {
  let userInfo = {};
  let userToken = '';
  try {
    userInfo = await localStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);
    if (userInfo == undefined) {
      userToken = await localStorage.getItem('token');
    }
  } catch (e) {}
  if (endpoint?.includes('/logout')) {
    APIClient.defaults.headers.common['refreshtoken'] = userInfo?.refreshToken;
  }

  endpoint = process.env.REACT_APP_API_URL + endpoint;

  let currentDate = new Date().toISOString();
  let expirationDate = userInfo?.expirationDate;

  if (currentDate > expirationDate) {
    let response = await handleRefreshToken(userInfo?.refreshToken);
    if (response?.idToken) {
      APIClient.defaults.headers.common['Authorization'] = response?.idToken;
    } else {
      APIClient.defaults.headers.common['Authorization'] =
        userInfo?.idToken || userToken;
    }
  } else {
    APIClient.defaults.headers.common['Authorization'] =
      userInfo?.idToken || userToken;
  }

  APIClient.defaults.headers.common['browser-signature'] =
    await getBrowserFingerprint();
  APIClient.defaults.headers.common['http_referrer'] =
    `${window.location.origin}${window.location.pathname}`;
  APIClient.defaults.headers.common['x-user-id'] = userInfo?.userId;
  if (endpoint?.includes('co-teacher/users/update-status')) {
    let referrer = await localStorage.getItem('externalRef');
    if (referrer) {
      APIClient.defaults.headers.common['external-referrer'] = referrer;
    } else {
      let data = await localStorage.getItem('utm_params');
      try {
        data = JSON.parse(data);
      } catch (e) {
        data = {};
      }
      APIClient.defaults.headers.common['x-utm-data'] = JSON.stringify(data);
    }
    await localStorage.removeItem('externalRef');
    await localStorage.removeItem('utm_params');
  }
  return APIClient[method](endpoint, body)
    .then((response) => {
      const data = response?.data;
      let responseData = { success: true, data: data };
      return responseData;
    })
    .catch((error) => {
      if (error.message == 'Network Error') {
        return {
          success: false,
          errors: 'Please Check your NetWork Connection and try again later!!',
        };
      }
      if (error.response) {
        if (error.response.status == 401) {
          if (
            error.response.data.message?.includes('Error: Error: Token expired')
          ) {
            document.dispatchEvent(customEvent);
            showToast('Token expired. Please login again !!');
            return {
              success: false,
              data: error.response.data,
              status: error.response.status,
            };
          }
        }
        if (error.response.status == 429) {
          return {
            success: false,
            data: error.response.data,
            status: error.response.status,
          };
        }
      }
      if (error.request) {
        return {
          success: false,
          errors: error?.message,
        };
      }
      console.log('error', error);
      return {
        success: false,
        errors: error?.message,
      };
    });
}
export default {
  get: (url, body) => {
    return callApi(url, 'get', { params: body });
  },
  post: (url, body, options) => {
    return callApi(url, 'post', body, options);
  },
  put: (url, body, options) => {
    return callApi(url, 'put', body, options);
  },
};
