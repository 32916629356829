import socketIOClient from 'socket.io-client';
import { showToast } from '../components/CustomToast';
import { handleRefreshToken } from './auth';
import { strings } from '../utils/strings';
import { getBrowserFingerprint } from '../utils/common-utils';

const customEvent = new CustomEvent('customLogoutEvent', {
  detail: { message: 'Logout Event' },
});

let controller; // Define the controller variable

const removeFirstAndLastQuotes = (inputString) => {
  if (inputString.startsWith('"') && inputString.endsWith('"')) {
    return inputString.slice(1, -1);
  }
  return inputString;
};

export const fetchStreamingResponse = async (
  handleStreamingText,
  handleRedisData,
  setIsComplete,
  data,
  screenType,
  setSocket,
  setHitCount,
) => {
  try {
    let isLessonPlan = screenType == strings?.titles?.lesson_plan;
    let isEngagementTip = screenType == strings?.titles?.engagement_tips;
    controller = new AbortController(); // Create a new controller
    const signal = controller.signal;

    const socket = socketIOClient(process.env.REACT_APP_API_URL);
    setSocket(socket);
    console.log('socket connection established !!');
    let endpoint = 'co-teacher/promptV1/genarate';
    if (isLessonPlan) {
      endpoint = `co-teacher/promptV1/lesson-plan/genarate`;
    } else if (isEngagementTip) {
      endpoint = 'co-teacher/prompts/genarate';
    }

    let userId = await localStorage.getItem('userId');
    if (userId) {
      setHitCount(0);
    }
    let token = await localStorage.getItem('token');
    let isRedisStreaming = false;
    let userInfo = {};
    try {
      userInfo = await localStorage.getItem('userInfo');
      userInfo = JSON.parse(userInfo);
    } catch (e) {}
    let currentDate = new Date().toISOString();
    let expirationDate = userInfo?.expirationDate;

    if (currentDate > expirationDate) {
      let response = await handleRefreshToken(userInfo?.refreshToken);
      if (response?.idToken) {
        token = response?.idToken;
      }
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-id': userId,
          Authorization: token,
          'browser-signature': await getBrowserFingerprint(),
        },
        body: JSON.stringify(data),
        signal,
      },
    );

    console.log('Hits Count:', response.headers.get('hits-count'));
    if (!userId) {
      setHitCount(response.headers.get('hits-count'));
    }
    if (response.status == 200 || response.status == 201) {
      const reader = response.body.getReader();

      let i = 0;
      let condition = true;
      while (condition) {
        const { done, value } = await reader.read();
        if (done) {
          if (!isRedisStreaming) {
            setIsComplete(false);
          }
          disconnectSocket(socket);
          break;
        }
        let newData = new TextDecoder().decode(value);

        if (i == 0 && newData?.toString()?.split(' ')?.length > 2) {
          isRedisStreaming = true;
          data = removeFirstAndLastQuotes(newData?.toString());
          handleRedisData(data);
        } else {
          handleStreamingText(newData);
        }
        i++;
      }
    } else if (response.status == 429 || response.status == 403) {
      disconnectSocket(socket);
      setIsComplete(false);
      return {
        status: response?.status,
        message: 'Maximum attempts exceeded! Please log in to continue.',
      };
    } else if (response.status == 401) {
      document.dispatchEvent(customEvent);
      setIsComplete(false);
      showToast('Token expired. Please login again !!');
    } else {
      setIsComplete(false);
      disconnectSocket(socket);
      return;
    }
  } catch (error) {
    console.error('Error fetching streaming data:', error);
  }
};

export const disconnectSocket = (socket) => {
  if (socket) {
    console.log('socket connection disconnected successfully !!');
    socket.disconnect();
  }
};

export const cancelFetchRequest = () => {
  if (controller) {
    controller.abort();
    controller = null; // Reset the controller
    console.log('Fetch request canceled');
  }
};
