import React, { useState, useEffect } from 'react';
import '../styles/feedback.css'; // Create a CSS file for styling
import ISButton from './ISButton';
import { saveTicket } from '../apis/callAPi';
import { useAuth } from '../context';
import { strings } from '../utils/strings';
import { routesLinks } from '../utils/RouteConstants';

const FeedbackModal = ({ onclose }) => {
  const { userInfo } = useAuth();
  const [feedback, setFeedback] = useState('');
  const [isButtonEnabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [moduleName, setModuleName] = useState('Home');

  useEffect(() => {
    setFeedback('');
    setIsButtonDisabled(false);
    setMessage('');
  }, []);

  useEffect(() => {
    let page = window.location.href;
    getPageTitle(page);
  }, []);
  const getPageTitle = (page) => {
    const {
      curiositytriggers,
      learningactivities,
      classroomquestions,
      engagementtips,
      lessonplan,
    } = routesLinks;
    if (page?.includes(curiositytriggers)) {
      setModuleName(strings?.titles?.curiosity_triggers);
    } else if (page?.includes(learningactivities)) {
      setModuleName(strings?.titles?.learning_activities);
    } else if (page?.includes(classroomquestions)) {
      setModuleName(strings?.titles?.question_paper);
    } else if (page?.includes(engagementtips)) {
      setModuleName(strings?.titles?.engagement_tips);
    } else if (page?.includes(lessonplan)) {
      setModuleName(strings?.titles?.lesson_plan);
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };
  const handleSaveFeedback = async () => {
    setIsButtonDisabled(true);
    const { success } = await saveTicket(moduleName, feedback, userInfo);
    if (success) {
      setMessage(`Thank you, we're on it!`);
      setTimeout(() => {
        onclose();
      }, 3000);
    } else {
      setIsButtonDisabled(false);
    }
  };
  return (
    <div className="feedback-modal">
      <div className="feedback-modal-content">
        <p className="modal-header">{`Tell us how we can raise the bar`}</p>
        <textarea
          placeholder="Type your feedback here..."
          value={feedback}
          onChange={handleFeedbackChange}
        />
        {isButtonEnabled && <div className="message">{message}</div>}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ISButton
            label="Cancel"
            handleClick={() => {
              onclose();
            }}
            isEnable={true}
            containerStyle="feedback-cancel-btn"
          />
          <ISButton
            label="Submit"
            handleClick={() => {
              feedback?.length > 0 && !isButtonEnabled && handleSaveFeedback();
            }}
            isEnable={feedback?.length > 0 && !isButtonEnabled}
            containerStyle={
              feedback?.length > 0
                ? 'feedback-submit-btn'
                : 'feedback-submit-btn-disable'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
