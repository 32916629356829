import React, { useState, useEffect } from 'react';
import '../styles/mobile-card.css';
import Arrow from '../assets/nextIcon.png';
import { useNavigate } from 'react-router-dom';
import IconTint from 'react-icon-tint';
import { STRINGS } from '../utils/CONSTANTS';

const MobileCard = ({ item, card, type, index, setShowModal }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Adjust the threshold (e.g., 'window.innerHeight / 3') as needed
    if (window.scrollY > (index + 1) * (window.innerHeight / 3)) {
      setIsVisible(true);
    } else {
      setIsVisible(false); // Reset visibility when scrolling back up
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`assistant-mobile-card`}
      onClick={() => {
        if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.question_bank?.name)
        ) {
          setShowModal(true);
        } else {
          navigate(`${item?.navigation}`);
        }
      }}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
        ...(card === 'everydayAi'
          ? { borderBottom: '1px solid #ffbfb7' }
          : null),
        opacity: isVisible ? 1 : 0, // Set opacity based on visibility
        transform: isVisible ? 'translateY(0)' : 'translateY(50px)', // Slide in when visible
        transition: 'opacity 0.5s ease, transform 0.5s ease', // Transition effect
      }}
    >
      <img
        src={item.image}
        alt="Image"
        className={`assistant-mobile-card-img-top`}
      />
      <div className={`mobile-card-body`}>
        <div>
          <h5 className={'assistant-mobile-card-title'}>{item?.displaytext}</h5>
          <p className={'assistant-mobile-card-text'}>{item?.description}</p>
        </div>
        <div className="mobile-bottom-container">
          <span className={`mobile-bottom-text`} style={{ color: 'white' }}>
            {item?.action}
          </span>
          <IconTint
            src={Arrow}
            color={
              type === 'ai assistant' && card !== 'everydayAi'
                ? '#7abc40'
                : '#ffbfb7'
            }
            alt="Image"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileCard;
