import Http from '../utils/Http';

export const savePreferences = async (body) => {
  let response = await Http.post(
    `co-teacher/userPreference`,
    JSON.stringify(body),
  );
  return response;
};

export const getPreferences = async () => {
  let response = await Http.get(`co-teacher/userPreference`);
  return response;
};
