import Http from '../utils/Http';

const getCategoriesData = async () => {
  let res = await Http.get('co-teacher/categories/');
  return res;
};
const getPromptOfTheDay = async () => {
  let res = await Http.get('co-teacher/prompts/promptOftheDay/');
  return res;
};
const getAllPrompts = async (limit, pageNumber, type) => {
  let res = await Http.get(
    `co-teacher/prompts/promptLibrary?pageLimit=${limit}&offset=${pageNumber}&subCategory=${type}`,
  );
  return res;
};
const getExpertLearnings = async (limit) => {
  let res = await Http.get(`co-teacher/prompts/aiExpert?pageLimit=${limit}`);
  return res;
};
const getCurriculum = async () => {
  let res = await Http.get('api/curriculum');
  return res;
};
const getGrades = async (id) => {
  let res = await Http.get(`api/grades/${id}`);
  return res;
};
const getSubjects = async (id) => {
  let res = await Http.get(`api/subjects/${id}`);
  return res;
};
const getTopics = async (id) => {
  let res = await Http.get(`api/topics/${id}`);
  return res;
};
const sendToWhatsapp = async (data, fileName, curriculum, title) => {
  let inputData = {
    content: data,
    fileName: fileName,
    curriculum,
    title,
  };
  let res = await Http.post(
    'co-teacher/whatsapp-integration/v1',
    JSON.stringify(inputData),
  );
  return res;
};
const performTask = async (body) => {
  let res = await Http.post(`co-teacher/prompts/genaratev1`, body);
  return res;
};
const getLearningVideos = async (filter) => {
  let res = await Http.post(`api/fetchVideo`, filter);
  return res;
};
const getLearningArticles = async (filter) => {
  let res = await Http.post(`api/fetchArticle`, filter);
  return res;
};
const getLearningPodcast = async (filter) => {
  let res = await Http.post(`api/fetchPodcast`, filter);
  return res;
};
const getVideoCategories = async () => {
  let res = await Http.get(`api/learn/video/category`);
  return res;
};
const getArticleCategories = async () => {
  let res = await Http.get(`api/learn/article/category`);
  return res;
};
const getPodcastCategories = async () => {
  let res = await Http.get(`api/learn/podcast/category`);
  return res;
};
const loginApi = async (body) => {
  let res = await Http.post(`co-teacher/users/login`, body);
  return res;
};
const verifyLoginCode = async (body) => {
  let res = await Http.post(`co-teacher/users/verify`, body);
  return res;
};
const scenariosList = async (grades, category) => {
  let res = await Http.get(
    `co-teacher/scenario?grade=${grades}&category=${category}`,
  );
  return res?.data;
};
const updateUserName = async (body) => {
  let res = await Http.put(`co-teacher/users/update-status`, body);
  return res?.data;
};

const saveTicket = async (pageTitle, description, userInfo) => {
  let body = {
    userName: userInfo?.name || undefined,
    description: description,
    phoneNumber: userInfo?.phoneNumber?.replace('+91', '') || undefined,
    title: pageTitle,
  };
  let res = await Http.post(`co-teacher/prompts/zoho/ticket`, body);
  return res;
};
const promptCategory = async () => {
  let res = await Http.get(`api/target-audience`);
  return res?.data;
};
const promptSubCategory = async (id) => {
  let res = await Http.get(`api/tip-categories/${id}`);
  return res?.data;
};
const generateEvent = async (data) => {
  let res = await Http.post(`co-teacher/prompts/event`, data);
  return res?.data;
};
const getSubTopics = async (id) => {
  let res = await Http.get(`api/sub-topics/${id}`);
  return res;
};
const getJobsList = async () => {
  let res = await Http.get(`co-teacher/promptV1/ceipal/jobs`);
  return res;
};
const saveBrowserHistory = async (data) => {
  try {
    let res = await Http.post(`co-teacher/user-activity/tracking`, data);
    return res;
  } catch (err) {
    console.log('Error while saving', err);
  }
};
export {
  getCategoriesData,
  getPromptOfTheDay,
  getAllPrompts,
  getExpertLearnings,
  sendToWhatsapp,
  getCurriculum,
  getGrades,
  getSubjects,
  getTopics,
  performTask,
  getLearningVideos,
  getLearningArticles,
  getLearningPodcast,
  getVideoCategories,
  getArticleCategories,
  getPodcastCategories,
  loginApi,
  verifyLoginCode,
  scenariosList,
  updateUserName,
  saveTicket,
  promptCategory,
  promptSubCategory,
  generateEvent,
  getSubTopics,
  getJobsList,
  saveBrowserHistory,
};
