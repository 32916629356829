// CustomButton.js
import React from 'react';
import { Button } from 'react-bootstrap';
import './componentStyles.css';
import IconTint from 'react-icon-tint';

const CustomButton = ({
  variant,
  children,
  onClick,
  styleComponent,
  leftIcon,
  rightIcon,
  iconColor,
}) => {
  return (
    <Button
      variant={variant}
      style={{
        ...styleComponent,
        justifyContent: 'center',
        display: 'flex',
      }}
      onClick={onClick}
    >
      {leftIcon && (
        <div>
          <IconTint src={leftIcon} color={iconColor} className="icon-left" />
        </div>
      )}
      <div style={{ marginTop: leftIcon && '12px' }}>{children}</div>
      {rightIcon && (
        <div>
          <IconTint src={rightIcon} color={iconColor} className="icon-right" />
        </div>
      )}
    </Button>
  );
};

export default CustomButton;
