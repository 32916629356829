/* eslint-disable */
import React, { useState, useEffect } from "react";
import NavBar from "../../components/UnifiedHome/NavBar";
import VinDesc from "../../components/UnifiedHome/VinDesc";
import CardItem from "../../components/UnifiedHome/CardItem";
import { useMediaQuery } from "react-responsive";
import Lottie from "react-lottie";
import defaultStyles from "./styles/unified.module.css";
import BGVideo from "../../assets/shadow.mp4";
import StudentBg from "../../assets/common-home/studentBg.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BORDER_LOTTIE_OPTIONS,
  MAX_MOBILE_WIDTH,
  VIN_ANIMATION_OPTIONS,
} from "../../components/UnifiedHome/helpers";
import Footer from "../../components/UnifiedHome/Footer";
import VinIdle from "../../assets/common-home/vin-animation.json";
import VinTeacher from "../../assets/common-home/vin-animation-teacher.json";
import VinStudent from "../../assets/common-home/vin-animation-student.json";
import VinSchool from "../../assets/common-home/vin-animation-school.json";
import { saveBrowserDetails } from "../../utils/common-utils";

export default function index() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  const [styles, setStyles] = useState(defaultStyles);
  const [isTeacherHovered, setIsTeacherHovered] = useState(false);
  const [isStudentHovered, setIsStudentHovered] = useState(false);
  const [isSchoolHovered, setIsSchoolHovered] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    saveBrowserDetails(searchParams);
  }, []);

  useEffect(() => {
    setIsTeacherHovered(false);
    setIsStudentHovered(false);
    setIsSchoolHovered(false);
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    const loadStyles = async () => {
      let newStyle = "";
      if (isTeacherHovered) {
        newStyle = await import("./styles/teacher-hover.module.css");
      } else if (isStudentHovered) {
        newStyle = await import("./styles/student-hover.module.css");
      } else if (isSchoolHovered) {
        newStyle = await import("./styles/school-hover.module.css");
      } else {
        newStyle = await import("./styles/unified.module.css");
      }
      if (isMounted) {
        setStyles(newStyle.default);
      }
    };

    loadStyles();
    return () => {
      isMounted = false;
    };
  }, [isTeacherHovered, isStudentHovered, isSchoolHovered]);

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      videoElement.oncanplaythrough = () => {
        setVideoLoaded(true);
      };
    }
    return () => {
      setVideoLoaded(false);
    };
  }, [isTeacherHovered, isStudentHovered]);

  let animationData = VinIdle;
  if (isTeacherHovered) {
    animationData = VinTeacher;
  } else if (isStudentHovered) {
    animationData = VinStudent;
  } else if (isSchoolHovered) {
    animationData = VinSchool;
  }

  return (
    <div className={styles["container"]}>
      {(isTeacherHovered || isStudentHovered) && (
        <video
          id="background-video"
          className={styles["background-video"]}
          autoPlay
          loop
          muted
          playsInline
          style={{ display: videoLoaded ? "block" : "none" }}
        >
          <source
            src={isStudentHovered ? StudentBg : BGVideo}
            type="video/mp4"
          />
        </video>
      )}
      <NavBar isTeacherHovered={isTeacherHovered} />
      <VinDesc styles={styles} />
      <div className={styles["card-row"]}>
        <Box styles={styles} color={"teacher"} />
        <CardItem
          color="teacher"
          setValue={setIsTeacherHovered}
          styles={styles}
        />
        <VinAnimation
          styles={styles}
          isMobile={isMobile}
          animationData={animationData}
        />
        <CardItem
          color="student"
          setValue={setIsStudentHovered}
          styles={styles}
        />
        <Box styles={styles} color={"student"} />
      </div>
      <div className={styles["row-center"]}>
        <div className={styles["school-animation"]}>
          {isSchoolHovered && (
            <div className={styles["lottie-top"]}>
              <Lottie
                options={BORDER_LOTTIE_OPTIONS}
                height={10}
                width={isMobile ? "50%" : "420px"}
              />
            </div>
          )}
          <SchoolCard
            navigate={navigate}
            setIsSchoolHovered={setIsSchoolHovered}
            styles={styles}
          />
          {isSchoolHovered && (
            <div className={styles["lottie-bottom"]}>
              <Lottie
                options={BORDER_LOTTIE_OPTIONS}
                height={10}
                width={isMobile ? "50%" : "420px"}
              />
            </div>
          )}
        </div>
      </div>
      <Footer styles={styles} />
    </div>
  );
}

const VinAnimation = ({ styles, isMobile, animationData }) => {
  return (
    <div className={styles["vin-img-container"]}>
      <Lottie
        options={{ ...VIN_ANIMATION_OPTIONS, animationData }}
        height={isMobile ? 96 : 240}
        width={isMobile ? 96 : 300}
      />
    </div>
  );
};

const Box = ({ color, styles }) => (
  <div className={`${styles[`${color}-box`]}`} />
);

const SchoolCard = ({ setIsSchoolHovered, styles }) => {
  return (
    <CardItem color="school" setValue={setIsSchoolHovered} styles={styles} />
  );
};
