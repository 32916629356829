export const routesLinks = {
  /** Home Page */
  home: '/',
  aboutus: '/about-us',
  careers: '/careers',
  privacypolicy: '/privacy-policy',
  termsandconditions: '/terms-and-conditions',
  schoolpartner: '/school-partner',
  /** Ai Assistant */
  teacher: '/teacher',
  login: '/teacher/login',
  lessonplan: '/teacher/lesson-plan',
  learningactivities: '/teacher/learning-activities',
  engagementtips: '/teacher/engagement-tips',
  curiositytriggers: '/teacher/curiosity-triggers',
  classroomquestions: '/teacher/classroom-questions',
  promptoftheday: '/teacher/prompt-of-the-day',
  promptlibrary: '/teacher/prompt-library',
  aiexpert: '/teacher/ai-expert',
};
