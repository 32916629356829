import React, { useEffect, useState } from 'react';
import FeedBackIcon from '../assets/feedback/feedback.svg';
import '../styles/feedback.css'; // You can create a CSS file for styling
import FeedbackModal from './FeedbackModal';
import { useMediaQuery } from 'react-responsive';

const Feedback = ({ show }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(show || false); // State for showing the modal

  useEffect(() => {}, [showModal, show]);

  const handleHover = () => {
    isDesktop && setHovered(true);
  };

  const handleMouseLeave = () => {
    isDesktop && setHovered(false);
  };

  return (
    <>
      <div className="feedback-button">
        <div
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            setShowModal(true);
          }}
          className={
            hovered && isDesktop
              ? `feedback-btn-container-hover`
              : `feedback-btn-container`
          }
        >
          {hovered && isDesktop && (
            <div className="feedback-btn-text">{`Feedback`}</div>
          )}
          <img src={FeedBackIcon} alt="Feedback" className="feedback-img" />
        </div>
      </div>

      {showModal && (
        <FeedbackModal
          onclose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default Feedback;
