// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Comfortaa", cursive;
}

main {
  min-height: 80vh;
}

horizontal-list {
  display: flex;
  list-style-type: none;
  padding: 0;
}

button-header {
  background-color: "black";
  color: "white";
  cursor: "pointer";
  border: "none";
  padding: "0.5rem 1rem";
}
.custom-modal .modal-content {
  width: 380px;
  border-radius: 10px;
}
.hide-caret-toggle::after {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,cAAc;EACd,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap\");\n\nbody {\n  font-family: \"Comfortaa\", cursive;\n}\n\nmain {\n  min-height: 80vh;\n}\n\nhorizontal-list {\n  display: flex;\n  list-style-type: none;\n  padding: 0;\n}\n\nbutton-header {\n  background-color: \"black\";\n  color: \"white\";\n  cursor: \"pointer\";\n  border: \"none\";\n  padding: \"0.5rem 1rem\";\n}\n.custom-modal .modal-content {\n  width: 380px;\n  border-radius: 10px;\n}\n.hide-caret-toggle::after {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
