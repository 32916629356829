// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_nav-link__4owJr {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #221265;
}
.navbar_active__s1cvR {
  font-weight: 600;
}
.navbar_logo__t3FUZ {
  object-fit: contain;
  height: 36px;
}
@media (max-width: 768px) {
  .navbar_logo__t3FUZ {
    object-fit: contain;
    height: 21px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/UnifiedHome/styles/navbar.module.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE;IACE,mBAAmB;IACnB,YAAY;EACd;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap\");\n\n.nav-link {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  text-align: left;\n  color: #221265;\n}\n.active {\n  font-weight: 600;\n}\n.logo {\n  object-fit: contain;\n  height: 36px;\n}\n@media (max-width: 768px) {\n  .logo {\n    object-fit: contain;\n    height: 21px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-link": `navbar_nav-link__4owJr`,
	"active": `navbar_active__s1cvR`,
	"logo": `navbar_logo__t3FUZ`
};
export default ___CSS_LOADER_EXPORT___;
