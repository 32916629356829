/* eslint-disable */
import React from "react";
import styles from "../../pages/UnifiedHome/styles/careers.module.css";
import NavBar from "./NavBar";
import Underline from "../../assets/common-home/underline.svg";
import {
  COMPANY_IMAGE,
  CareerCards,
  CarretPageText,
  MAX_MOBILE_WIDTH,
  jobsLink,
} from "./helpers";
// import JobPostings from "./JobPostings";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import BottomNav from "./BottomNav";
export default function Careers() {
  const { title, subTitle, desc1, desc2 } = CarretPageText;

  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const exploreOpenRolesRef = useRef(null);
  // const handleClick = () => {
  //   exploreOpenRolesRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  const handleClick = () => {
    window.open(jobsLink, "_blank", "noopener,noreferrer");
  };
  return (
    <div className={styles.container}>
      <NavBar />
      <img src={COMPANY_IMAGE} alt="Careers" className={styles["career-img"]} />

      <div className={styles["sub-container"]}>
        <div className={styles["view-ops-container"]} onClick={handleClick}>
          <div className={styles["ops-text"]}>View Job Opportunities</div>
        </div>
        <div className={styles["right"]}>
          <div className={styles["sub-title"]}>{subTitle}</div>
          <div className={styles["description"]}>{desc1}</div>
          <div className={styles["description"]} style={{ marginTop: "24px" }}>
            {desc2}
          </div>
        </div>
      </div>
      <div className={styles["main-title"]}>
        Why Coschool
        <img src={Underline} className={styles["under-curve"]} />
      </div>
      <div className={styles["cards"]}>
        <Card item={CareerCards[0]} />
        <Card item={CareerCards[1]} />
        <Card item={CareerCards[2]} />
      </div>
      <div className={styles["cards"]}>
        <Card item={CareerCards[3]} />
        <Card item={CareerCards[4]} />
      </div>
      {/* {isMobile ? (
        <div
          className={styles["main-title"]}
          style={{ fontSize: "24px" }}
          ref={exploreOpenRolesRef}
        >
          Current Job Openings
        </div>
      ) : (
        <div className={styles["main-title"]} ref={exploreOpenRolesRef}>
          Explore Open Roles
        </div>
      )} */}
      {/* <JobPostings /> */}
      <div className={styles["main-title"]}>Impact Starts From Day One!</div>
      <BottomNav />
    </div>
  );
}

const Card = ({ item }) => {
  return (
    <div className={styles["card"]}>
      <div className={styles["card-title"]}>{item.title}</div>
      <div className={styles["card-description"]}>{item.desc}</div>
    </div>
  );
};
