import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import '../styles/dropdown.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoCloseCircleOutline } from 'react-icons/io5';

const CustomDropdown = (props) => {
  let {
    selectedValue,
    placeholder,
    handleSelect,
    data,
    parentData = {},
    containerStyle,
    contentStyle,
  } = props;
  let activeStyle = selectedValue != '' ? 'active' : 'inactive';
  const [error, setError] = useState('');
  const [dropdownClickState, setDropdownClickState] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState(data || []);
  useEffect(() => {
    setSearchedData(data);
  }, [data]);
  useEffect(() => {
    if (dropdownClickState[placeholder]) {
      const message = generateErrorMessage(placeholder, parentData);
      setError(message);
    }
  }, [parentData, placeholder]);
  const generateErrorMessage = (placeholder, parentData) => {
    switch (placeholder) {
      case 'Grade':
        return parentData?.id ? '' : 'Please select curriculum first';
      case 'Subject':
        return parentData?.curriculum?.id
          ? parentData?.grade?.id
            ? ''
            : 'Please select grade first'
          : 'Please select curriculum and grade first';
      case 'Chapter':
        return parentData?.curriculum?.id
          ? parentData?.grade?.id
            ? parentData?.subject?.id
              ? ''
              : 'Please select subject first'
            : 'Please select grade and subject first'
          : 'Please select curriculum, grade, and subject first';
      case 'Subtopic':
        return parentData?.curriculum?.id
          ? parentData?.grade?.id
            ? parentData?.subject?.id
              ? parentData?.chapter?.id
                ? ''
                : 'Please select chapter first'
              : 'Please select subject and chapter first'
            : 'Please select grade, subject, and chapter first'
          : 'Please select curriculum, grade, and subject first';
      default:
        return '';
    }
  };
  const handleWarning = (e) => {
    if (e.target.id === 'toggle-menu')
      setDropdownClickState((prev) => ({
        ...prev,
        [placeholder]: true,
      }));
    {
      const message = generateErrorMessage(placeholder, parentData);
      setError(message);
    }
  };
  const handleDropdownSearch = (e) => {
    let value = e.target.value;
    setSearchValue(value);
    let filteredData = [...data];
    filteredData = filteredData?.filter((toFilter) => {
      return toFilter?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setSearchedData(filteredData);
  };
  return (
    <Dropdown onClick={handleWarning}>
      <Dropdown.Toggle
        className={`dropdown-style  ${containerStyle} ${activeStyle}`}
        style={{
          backgroundColor: !containerStyle ? '#262626' : '#363636',
          whiteSpace: 'normal',
          textAlign: 'left',
          color:
            selectedValue == undefined || selectedValue == ''
              ? '#919191'
              : 'white',
          border: error ? '1px solid #EB5757' : 'none',
        }}
        id="toggle-menu"
      >
        {selectedValue || placeholder}
      </Dropdown.Toggle>
      <text style={{ color: '#EB5757', fontSize: '12px', fontWeight: 400 }}>
        {error}
      </text>
      {data?.length > 0 && (
        <Dropdown.Menu
          style={{ maxHeight: '400px', overflowY: 'auto' }}
          className={`dropdown-content ${contentStyle}`}
        >
          <InputGroup className="search-box">
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={handleDropdownSearch}
              className="seach-area"
            />
            <IoCloseCircleOutline
              size={20}
              onClick={() => {
                setSearchValue('');
                setSearchedData(data);
              }}
            />
          </InputGroup>
          {searchedData?.map((item, index) => {
            let isActive = item?.title == selectedValue;
            return (
              <Dropdown.Item
                key={index}
                style={{
                  backgroundColor: isActive ? 'white' : '#262626',
                  color: isActive ? '#262626' : 'white',
                  whiteSpace: 'normal',
                }}
                onClick={() => {
                  handleSelect(item);
                  setSearchValue('');
                  setSearchedData(data);
                }}
              >
                {item?.title}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default CustomDropdown;
