import Http from '../utils/Http';
import axios from 'axios';
import { getExpirationDate } from '../utils/common-utils';

export const sendOTP = async (mobileNumber, countryCode) => {
  let data = {
    phoneNumber: `${countryCode}${mobileNumber}`,
  };
  let res = await Http.post(`co-teacher/users/login`, data);
  return res;
};
export const verifyOTP = async (userName, code, session) => {
  let data = {
    userName: userName,
    code: code,
    session: session,
  };
  let res = await Http.post(`co-teacher/users/verify`, data);
  return res;
};
export const updateUserName = async (userName) => {
  try {
    let data = {
      name: userName,
    };
    let res = await Http.put(`co-teacher/users/update-status`, data);
    console.log(res);
    return res;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const userLogout = async () => {
  let res = await Http.post(`co-teacher/users/logout`);
  return res;
};

export const saveSchoolEnquiry = async (body) => {
  let res = await Http.post(`co-teacher/school-enquiry`, body);
  return res;
};
export const SendOTPForSchoolEnquiry = async (mobileNumber) => {
  let body = {
    phoneNumber: `+91${mobileNumber}`,
  };
  let res = await Http.post(`co-teacher/school-enquiry/login`, body);
  return res;
};

export const VerifyForSchoolEnquiry = async (data, code) => {
  let body = {
    userName: data?.authId || '',
    code: code,
    session: data?.session || '',
  };
  let res = await Http.post(`co-teacher/school-enquiry/verify`, body);
  return res;
};

export const handleRefreshToken = (refreshToken) => {
  return new Promise((resolve) => {
    try {
      const customHeaders = {
        refreshtoken: refreshToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + 'co-teacher/users/tokens',
          {},
          {
            headers: customHeaders,
          },
        )
        .then(async (response) => {
          let expiresIn, idToken;
          if (response?.data) {
            ({ expiresIn, idToken } = response.data);
          }
          let savedData = await localStorage.getItem('userInfo');
          try {
            savedData = JSON.parse(savedData);
          } catch (e) {
            savedData = {};
          }
          savedData = savedData || {};
          savedData = {
            ...savedData,
            idToken,
            expirationDate: getExpirationDate(expiresIn),
          };
          await localStorage.setItem('userInfo', JSON.stringify(savedData));
          await localStorage.setItem('token', idToken);
          resolve(response?.data); // Resolve with the response data
        })
        .catch((error) => {
          console.error('Bad Request:', error.response.data);
          resolve(); // Resolve without any data
        });
    } catch (e) {
      console.log(e);
    }
  });
};
