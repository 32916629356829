import React, { useState } from 'react';
import { Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Menu.css';
import CloseImage from '../assets/close.png';
// import AboutUs from '../assets/about-us.svg';
// import { strings } from '../utils/strings';
import { useNavigate } from 'react-router-dom';
import CustomModal from './CustomModal';
import { STRINGS } from '../utils/CONSTANTS';
import { routesLinks } from '../utils/RouteConstants';

const SideDrawer = ({ show, onHide, allCategories }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleClick = (item) => {
    //reloading page when click on navigation
    navigate(`${item?.navigation}`);
    onHide();
    window.location.reload(false);
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      style={{
        backgroundColor: '#353535',
        color: '#FFFFFF',
        paddingTop: '20px',
        width: '100%',
      }}
    >
      <Offcanvas.Header>
        <div
          style={{
            display: 'flex',
            gap: '48px',
            alignItems: 'center',
            marginLeft: '16px',
          }}
        >
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onHide();
            }}
          >
            <img src={CloseImage} />
          </div>
          <div className="menu-text">{'Menu'}</div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="flex-column">
          {allCategories?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (
                    item?.displaytext
                      ?.toLowerCase()
                      .includes(STRINGS?.home?.question_bank?.name)
                  ) {
                    setShowModal(true);
                  } else {
                    handleClick(item);
                  }
                }}
              >
                <Nav.Link as={Link}>
                  <div
                    className="small-screen-card"
                    style={{
                      animation:
                        index <= 10
                          ? `slideInText ${index * 0.1 + 0.5}s ease forwards`
                          : `slideInText 1s ease forwards`,
                    }}
                  >
                    <div className="div">
                      <img
                        className="img"
                        src={item?.image}
                        alt="Second slide"
                      />
                      <div>
                        <h5 className="h5">{item?.displaytext}</h5>
                        <h6 className="h6">{item?.description}</h6>
                      </div>
                    </div>
                  </div>
                </Nav.Link>
              </div>
            );
          })}
          {/* <Nav.Link href="/about-us">
            <div className="small-screen-card">
              <div className="div">
                <img className="img" src={AboutUs} />
                <div>
                  <h5 className="h5">{strings.about.title}</h5>
                </div>
              </div>
            </div>
          </Nav.Link> */}
          {showModal && (
            <CustomModal
              showModal={showModal}
              onHide={() => {
                setShowModal(false);
                navigate(routesLinks.home);
              }}
            />
          )}
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SideDrawer;
