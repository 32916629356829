import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { COUNTRY_CODES } from './CONSTANTS';
import { saveBrowserHistory } from '../apis/callAPi';
import Facebook from '../assets/bottom-nav/facebook.svg';
import Twitter from '../assets/bottom-nav/twitter.svg';
import Instagram from '../assets/bottom-nav/insta.svg';
import Youtube from '../assets/bottom-nav/youtube.svg';
import LinkedIn from '../assets/bottom-nav/linkedin.svg';

export const getUserId = async () => {
  let userId = null;
  try {
    userId = await localStorage.getItem('userId');
  } catch (e) {
    console.log(e);
  }
  return userId;
};
export const getBrowserFingerprint = async () => {
  const visitorId = await getCurrentBrowserFingerPrint();
  return visitorId;
};

export const mobileNumberValidation = (input, countryCode) => {
  let res = COUNTRY_CODES?.filter((item) => {
    return (
      countryCode == item?.countryCode &&
      input?.length == item?.mobileNumberLength
    );
  });
  return res?.length > 0;
};

export const updatingCookies = async (setCookie, attempts) => {
  setCookie(
    'myCookies',
    {
      noOfAttempts: attempts,
      lastAttempted: new Date(),
    },
    {
      path: '/',
    },
  );
};
export const ENABLED_SUBJECTS = {
  'Grade 1': ['english', 'mathematics'],
  'Grade 2': ['english', 'mathematics'],
  'Grade 3': ['english', 'mathematics', 'evs'],
  'Grade 4': ['english', 'mathematics', 'evs'],
  'Grade 5': ['english', 'mathematics', 'evs'],
  'Grade 6': ['english', 'mathematics', 'science', 'social science'],
  'Grade 7': ['english', 'mathematics', 'science', 'social science'],
  'Grade 8': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 9': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 10': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 11': [
    'english',
    'mathematics',
    'economics',
    'physics',
    'chemistry',
    'business studies',
    'biology',
  ],
  'Grade 12': [
    'english',
    'mathematics',
    'economics',
    'physics',
    'chemistry',
    'business studies',
    'biology',
  ],
};

export const getExpirationDate = (secondsToAdd) => {
  const currentDate = new Date();
  let minutes = secondsToAdd / 60;
  minutes = minutes - 2;
  currentDate.setSeconds(currentDate.getSeconds() + minutes * 60);
  const formattedDate = currentDate.toISOString();
  return formattedDate;
};

export const saveBrowserDetails = async (searchParams) => {
  const browserParams = {};
  for (let [key, value] of searchParams.entries()) {
    browserParams[key] = value;
  }
  await saveBrowserHistory(browserParams);
};

export const bottomNavIcons = [
  {
    icon: Facebook,
    action: 'https://www.facebook.com/coschoolai1?mibextid=ZbWKwL',
  },
  {
    icon: Twitter,
    action: 'https://x.com/coschoolai?s=09',
  },
  {
    icon: Instagram,
    action: 'https://www.instagram.com/coschoolai?igsh=ZWxvbWlwa2NmYnJh',
  },
  {
    icon: LinkedIn,
    action: 'https://www.linkedin.com/company/coschoolai/',
  },
  {
    icon: Youtube,
    action: 'https://youtube.com/@coschoolai?si=u5h_ExBavtI1NyDn',
  },
];

export const bottomNavCompanyInfo = [
  {
    name: 'About Us',
    path: '/about-us',
  },
  {
    name: 'Contact us',
    path: '/school-partner',
  },
  {
    name: 'Careers',
    path: '/careers',
  },
  {
    name: 'Blog',
    externalPath: 'https://coschool.medium.com/',
  },
];

export const bottomNavSupportInfo = [
  {
    name: 'Chat on Whatsapp',
    externalPath: 'https://wa.me/918977044305?text=Hello',
  },
  {
    name: 'Report a bug',
    path: '/feedback',
  },
];
export const locations = {
  main_office: 'https://maps.app.goo.gl/ETVGBdjc9sz1fwAK9',
  jubilee_office: 'https://maps.app.goo.gl/79LZYFynTs3f1pHj6',
};
