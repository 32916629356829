import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getTopics, scenariosList, getSubTopics } from '../apis/callAPi';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { strings } from '../utils/strings';
import Dice from '../assets/dices.png';
import Mood from '../assets/moodhumorous.png';
import Hat from '../assets/schoolclassical.png';
import Brains from '../assets/cognitionThoughtProvoking.svg';
import Streaming from '../components/Streaming';
//import { useCookies } from 'react-cookie';
import { useAuth } from '../context';
import ISDropdown from '../components/ISDropdown';
import '../styles/curiosity.css';
import UserPreference from '../components/UserPreferences';
import ISButton from '../components/ISButton';
import IconTint from 'react-icon-tint';
import { useMediaQuery } from 'react-responsive';
import { cancelFetchRequest } from '../apis/streaming';
import { routesLinks } from '../utils/RouteConstants';
import { MASTER_CATGORIES } from '../utils/mastercategories';
import { saveBrowserDetails } from '../utils/common-utils';

const CURIOSITYTRIGGERS = [
  'Thought Provoking',
  'Gamified',
  'Humorous',
  'Classical',
];

const TIPS_CATEGORIES = [
  { title: 'Behavioural Scenarios' },
  { title: 'Learning Scenarios' },
  { title: 'Emotional Scenarios' },
];
let navigationId = '';
const normalizeString = (str) => str?.trim()?.toLowerCase().replace(/\s+/g, '');
const CuriosityTriggers = () => {
  const { userInfo } = useAuth();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [classType, setClassType] = useState(CURIOSITYTRIGGERS[0]);
  const [variationsList, setVariationsList] = useState([]);
  const [syllabus, setSyllabus] = useState({});
  const [topicsData, setTopicsData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [topic, setTopic] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    TIPS_CATEGORIES?.[0],
  );
  const [selectedSenario, setSelectedSenario] = useState({});
  const [senariosData, setSenariosData] = useState([]);
  const [error, setError] = useState('');
  const [streamingInput, setStreamingInput] = useState({});
  const [handleReset, setHandleReset] = useState(false);
  const [subTopicsData, setSubTopicsData] = useState([]);
  const [selectedSubTopic, setSelectedSubTopic] = useState({});
  const [handleCancel, setHandleCancel] = useState(false);
  const [hitCount, setHitCount] = useState(0);
  const [displayButton, setDisplayButton] = useState(true);
  const [syllabusData, setSyllabusData] = useState({});

  //const [cookies] = useCookies();
  const { state: input } = useLocation();
  const [moduleName, setModuleName] = useState(input?.pageTitle || '');

  const { engagementtips, classroomquestions, learningactivities } =
    routesLinks;
  let isEngagementTip = window.location?.href?.includes(engagementtips);
  let isQuestionBank = window.location?.href?.includes(classroomquestions);
  let isLearningActivities =
    window.location?.href?.includes(learningactivities);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const browserParams = {};
  for (let [key, value] of searchParams.entries()) {
    browserParams[key] = value;
  }

  const Icons = {
    Classical: Hat,
    'Thought Provoking': Brains,
    Humorous: Mood,
    Gamified: Dice,
  };
  useEffect(() => {
    let page = window.location.href;
    if (page) {
      const matchedCategory = MASTER_CATGORIES.find((category) =>
        page?.includes(category.path),
      );
      navigationId = matchedCategory?._id;
      setModuleName(matchedCategory?.displaytext);
    }
    toSetPageTitle(page);
    if (page?.includes(routesLinks?.curiositytriggers)) {
      setVariationsList(
        CURIOSITYTRIGGERS?.filter((item) => item != 'Gamified'),
      );
    } else {
      if (page?.includes(routesLinks?.lessonplan)) {
        setClassType(CURIOSITYTRIGGERS[2]);
      }
      setVariationsList(CURIOSITYTRIGGERS);
    }
  }, []);
  useEffect(() => {
    if (browserParams?.flavour) {
      let data = variationsList?.find((item) =>
        normalizeString(item)?.includes(
          normalizeString(browserParams?.flavour),
        ),
      );
      setClassType(data);
    }
  }, [variationsList]);
  useEffect(() => {
    if (browserParams?.category) {
      let data = TIPS_CATEGORIES?.find((item) =>
        normalizeString(item?.title)?.includes(
          normalizeString(browserParams?.category),
        ),
      );
      setSelectedCategory(data);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      saveBrowserDetails(searchParams);
    }, 1000);
  }, []);
  const toSetPageTitle = (page) => {
    if (page?.includes(routesLinks?.curiositytriggers)) {
      setModuleName(strings?.titles?.curiosity_triggers);
    } else if (page?.includes(routesLinks?.learningactivities)) {
      setModuleName(strings?.titles?.learning_activities);
    } else if (page?.includes(routesLinks?.classroomquestions)) {
      setModuleName(strings?.titles?.question_paper);
    } else if (page?.includes(routesLinks?.engagementtips)) {
      setModuleName(strings?.titles?.engagement_tips);
    } else if (page?.includes(routesLinks?.lessonplan)) {
      setModuleName(strings?.titles?.lesson_plan);
    }
  };

  useEffect(() => {
    setError('');
    let viewObj = {
      hitType: 'pageview',
      page: window.location.pathname,
      title: moduleName,
    };
    ReactGA.send(viewObj);
    setSelectedTopic({});
    setTopic('');
    setTopicsData([]);
    if (syllabus?.subject) {
      fetchTopics(syllabus?.subject?.id);
    }
  }, [syllabus]);

  useEffect(() => {
    if (syllabus?.grade?.name && selectedCategory?.title && isEngagementTip) {
      setSelectedSenario({});
      fetchScenarios(syllabus?.grade?.name, selectedCategory?.title);
    }
  }, [syllabus, selectedCategory]);

  useEffect(() => {
    if (selectedTopic?.id) {
      setSubTopicsData([]);
      setSelectedSubTopic({});
      syllabus?.subject?.name != 'English' && fetchSubTopics(selectedTopic?.id);
    } else if (selectedTopic?.id == undefined) {
      setSubTopicsData([]);
      setSelectedSubTopic({});
    }
  }, [selectedTopic]);
  const fetchSubTopics = async (id) => {
    const { success, data } = await getSubTopics(id);
    if (success) {
      setSubTopicsData(data?.data || []);
      if (browserParams?.subtopic) {
        let filteredData = data?.data?.find((item) =>
          normalizeString(item?.title)?.includes(
            normalizeString(browserParams?.subtopic),
          ),
        );
        filteredData = {
          title: filteredData?.name,
          name: filteredData?.name,
          id: filteredData?.id,
        };
        handleSubTopicSelect(filteredData);
      }
    }
  };

  const fetchScenarios = async (grade, category) => {
    let response = await scenariosList(grade, category);
    response = response?.[0]?.scenario?.map((item) => {
      return {
        id: item,
        title: item,
      };
    });
    setSenariosData(response);
    if (browserParams?.scenario) {
      let data = response?.find((item) =>
        normalizeString(item?.title)?.includes(
          normalizeString(browserParams?.scenario),
        ),
      );
      handleSelelectSenario(data);
    }
  };

  const fetchTopics = async (subjectId) => {
    let response = await getTopics(subjectId);
    if (response?.success) {
      setTopicsData(response?.data?.data);
      if (browserParams?.topic) {
        let data = response?.data?.data?.find((item) =>
          normalizeString(item?.title)?.includes(
            normalizeString(browserParams?.topic),
          ),
        );
        data = { title: data?.name, name: data?.name, id: data?.id };
        handleTopicSelect(data);
      }
    }
  };

  const handleTopicSelect = (selectedValue) => {
    if (!isComplete) {
      setHandleReset(true);
      setSelectedTopic(selectedValue);
      setTopic(selectedValue?.title);
    }
  };

  const handleSubTopicSelect = (selectedValue) => {
    if (!isComplete) {
      setHandleReset(true);
      setSelectedSubTopic(selectedValue);
    }
  };

  const handleSelelectSenario = (selectedValue) => {
    if (!isComplete) {
      setHandleReset(true);
      setSelectedSenario(selectedValue);
      setSyllabus(syllabus);
    }
  };
  const handleSelectCategory = (selectedValue) => {
    if (!isComplete) {
      setHandleReset(true);
      setSyllabus(syllabus);
      setSelectedCategory(selectedValue);
    }
  };

  const getSyllusMaping = () => {
    let data = {
      curriculum: {
        id: syllabus?.curriculum?.id,
        name: syllabus?.curriculum?.name,
      },
      grade: {
        id: syllabus?.grade?.id,
        name: syllabus?.grade?.name,
      },
      subject: {
        id: syllabus?.subject?.id,
        name: syllabus?.subject?.name,
      },
      topic: {
        id: selectedTopic?.id,
        name: selectedTopic?.name,
      },
      subTopic: {
        id: selectedSubTopic?.id,
        name: selectedSubTopic?.name,
      },
      variation: classType,
      flaours: classType,
    };
    if (isEngagementTip) {
      data = {
        gradeName: syllabus?.grade?.name,
        scenario: selectedSenario?.title,
        category: selectedCategory?.title,
      };
    }
    return data;
  };

  const getTaskTitle = () => {
    if (moduleName == strings.titles.lesson_plan) {
      return 'Create';
    } else if (moduleName == strings.titles.learning_activities) {
      return 'Design';
    } else if (moduleName == strings.titles.curiosity_triggers) {
      return 'Generate';
    } else if (moduleName == strings.titles.engagement_tips) {
      return 'Discover';
    } else if (moduleName == strings.titles.question_paper) {
      return 'Build';
    } else {
      return strings.curiosity.do_my_task;
    }
  };

  const variationsButton = (item) => {
    return (
      <div
        className="variation-item"
        style={{
          borderColor: item == classType ? '#7abc40' : '#b0b0b0',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!isComplete) {
            setHandleReset(true);
            setClassType(item);
          }
        }}
      >
        <IconTint
          src={Icons[item]}
          color={item == classType ? '#7abc40' : '#b0b0b0'}
        />
        <div style={{ color: item == classType ? '#7abc40' : '#b0b0b0' }}>
          {item}
        </div>
      </div>
    );
  };
  let isButtonEnabled = selectedSubTopic?.id != undefined;
  if (isEngagementTip) {
    isButtonEnabled = selectedSenario?.title != undefined;
  } else if (isLearningActivities || subTopicsData?.length == 0) {
    isButtonEnabled = selectedTopic?.id != undefined;
  }
  let noOfAttempts = hitCount;
  //parseInt(cookies['myCookies']?.noOfAttempts || 0);
  let isAllowAccess =
    userInfo?.name || noOfAttempts < strings?.default?.max_attempts;

  let ActiveColor =
    isButtonEnabled && isAllowAccess && !isQuestionBank
      ? 'active-task-btn'
      : 'task-btn';

  return (
    <Container>
      <div className="curiosity-main">
        <p className="header-text">{moduleName}</p>
        <UserPreference
          syllabus={syllabus}
          handleSyllubus={(data) => {
            setHandleReset(true);
            setSyllabus(data);
          }}
          screenName={moduleName}
          isStreaming={isComplete}
          setSyllabusData={setSyllabusData}
          syllabusData={syllabusData}
          browserParams={browserParams}
        />
        {!isEngagementTip && (
          <div className={isDesktop ? 'question-dropdown-container' : ''}>
            <div className={isDesktop ? 'question-dropdown' : ''}>
              <ISDropdown
                placeholder={'Chapter'}
                selectedValue={topic}
                handleSelect={handleTopicSelect}
                data={topicsData}
                parentData={syllabusData}
                containerStyle={'question-dropdown'}
                contentStyle={'question-dropdown-content'}
              />
            </div>
            {!isLearningActivities && syllabus?.subject?.name != 'English' && (
              <div className={isDesktop ? 'question-dropdown' : ''}>
                <ISDropdown
                  placeholder={'Subtopic'}
                  selectedValue={selectedSubTopic?.title || ''}
                  handleSelect={handleSubTopicSelect}
                  data={subTopicsData}
                  parentData={{ ...syllabusData, chapter: selectedTopic }}
                  containerStyle={'question-dropdown'}
                  contentStyle={'question-dropdown-content'}
                />
              </div>
            )}
          </div>
        )}

        {isEngagementTip && (
          <ISDropdown
            placeholder={'Category'}
            selectedValue={selectedCategory?.title}
            handleSelect={handleSelectCategory}
            data={TIPS_CATEGORIES}
            containerStyle={'tip-category-dropdown'}
            contentStyle={'tip-category-content'}
          />
        )}

        {isEngagementTip && (
          <ISDropdown
            placeholder={'Scenarios'}
            selectedValue={selectedSenario?.title || ''}
            handleSelect={handleSelelectSenario}
            data={senariosData}
            containerStyle={'tip-category-dropdown'}
            contentStyle={'tip-category-content'}
          />
        )}

        {!isQuestionBank && !isEngagementTip ? (
          <div>
            <p className="variation">{strings.curiosity.select_variation}</p>
            <div className={'variation-container'}>
              {variationsList?.map((item, index) => {
                return <div key={index}>{variationsButton(item)}</div>;
              })}
              ;
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: '16px' }} />
        )}
        {displayButton && (
          <ISButton
            label={isComplete == true ? 'Cancel' : getTaskTitle()}
            handleClick={() => {
              if (isButtonEnabled && isAllowAccess && isComplete == false) {
                setHandleCancel(false);
                setHandleReset(false);
                setStreamingInput(getSyllusMaping());
                setIsComplete(true);
              } else {
                cancelFetchRequest();
                setHandleCancel(true);
              }
            }}
            isEnable={isButtonEnabled && isAllowAccess}
            containerStyle={`${ActiveColor} btn-extra-height`}
          />
        )}

        {userInfo?.name == undefined && (
          <>
            {noOfAttempts == strings?.default?.max_attempts || error != '' ? (
              <p className="warning-title" style={{ color: '#919191' }}>
                {strings.default.user_attemps || error}
              </p>
            ) : (
              <p className="warning-title" style={{ color: '#919191' }}>
                {`${strings?.default?.max_attempts - noOfAttempts} 
                ${
                  strings?.default?.max_attempts - noOfAttempts > 1
                    ? 'more generations'
                    : 'generation'
                } left ! ${strings?.default?.unleash_message}`}
              </p>
            )}
          </>
        )}
        {/* {(noOfAttempts == strings?.default?.max_attempts || error != '') &&
          userInfo?.name == undefined && (
            <p className="warning-title" style={{ color: '#919191' }}>
              {strings.default.user_attemps || error}
            </p>
          )}
        {(noOfAttempts == 0 || error != '') && userInfo?.name == undefined && (
          <p className="warning-title" style={{ color: '#919191' }}>
            {`${strings?.default?.max_attempts - noOfAttempts
              } more generations left! ${strings?.default?.unleash_message}`}
          </p>
        )}
        {(noOfAttempts == 1 || error != '') && userInfo?.name == undefined && (
          <p className="warning-title" style={{ color: '#919191' }}>
            {`${strings?.default?.max_attempts - noOfAttempts
              } more generations left! ${strings?.default?.unleash_message}`}
          </p>
        )}
        {(noOfAttempts == 2 || error != '') && userInfo?.name == undefined && (
          <p className="warning-title" style={{ color: '#919191' }}>
            {`${strings?.default?.max_attempts - noOfAttempts
              } generation left! ${strings?.default?.unleash_message}`}
          </p>
        )} */}
        <Streaming
          setHitCount={(count) => {
            setHitCount(count || 0);
          }}
          syllubusMapping={streamingInput}
          handleReset={handleReset}
          categoryId={navigationId}
          prompt={''}
          isComplete={isComplete}
          setIsComplete={(status) => {
            setIsComplete(status);
          }}
          screenType={moduleName}
          handleError={(data) => {
            setError(data);
          }}
          handleCancel={handleCancel}
          displayButton={displayButton}
          setDisplayButton={(display) => {
            setDisplayButton(display);
          }}
        />
      </div>
    </Container>
  );
};

export default CuriosityTriggers;
