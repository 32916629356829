import axios from 'axios';

const baseURL = '';

const defaultConfig = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    common: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
  },
  responseType: 'json',
};

export function getAPIClient(customConfig = {}) {
  const object = {
    ...defaultConfig,
    ...customConfig,
  };
  return axios.create(object);
}

const defaultAPIClient = getAPIClient();

export default defaultAPIClient;
