import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
// import ReactPlayer from 'react-player';
import { generateEvent, getExpertLearnings } from '../apis/callAPi';
import { ALLNAMES } from '../utils/NAMES';
import '../styles/aiExpert.css';
// import { useMediaQuery } from 'react-responsive';
import { strings } from '../utils/strings';
import videoImage from '../assets/video_aiExpert.png';
import articleImage from '../assets/articles.png';
import podcastImage from '../assets/podcast.png';
import Spinner from 'react-bootstrap/Spinner';
import COLORS from '../utils/COLORS';
import VideoPlayer from '../components/VideoPlayer';
const learnings = 9;
const LearnFromExperts = () => {
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  const [displayedVideos, setDisplayedVideos] = useState(learnings);
  const [videosData, setVideosData] = useState([]);
  const [showCount, setShowCount] = useState(0);
  const [videoDescriptions, setVideoDescriptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLearnings(displayedVideos);
  }, [displayedVideos]);
  const fetchLearnings = async (recordCount) => {
    setIsLoading(true);
    try {
      let response = [];
      response = await getExpertLearnings(recordCount);
      response = response?.data;
      response = response?.data?.map((item) => {
        if (item.type === strings?.ai_expert?.video) {
          return { ...item, learnImage: videoImage };
        } else if (item.type === strings?.ai_expert?.article) {
          return { ...item, learnImage: articleImage };
        } else if (item.type === strings?.ai_expert?.podcast) {
          return { ...item, learnImage: podcastImage };
        }
      });
      setVideosData(response);
    } catch (e) {
      setVideosData([]);
      console.log(e);
    }
    setIsLoading(false);
  };

  const toggleDescription = (videoIndex) => {
    setVideoDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [videoIndex]: !prevDescriptions[videoIndex],
    }));
  };
  const cleanHtmlTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };
  const moreOrLess = () => {
    return (
      <div>
        {videosData?.length > learnings - 1 &&
          showCount < videosData?.length && (
            <button
              onClick={() => {
                setShowCount(displayedVideos);
                setDisplayedVideos(displayedVideos + learnings);
              }}
              className="show-moreLess"
            >
              {'Show More'}
            </button>
          )}
        {displayedVideos > learnings && (
          <button
            onClick={() => {
              setDisplayedVideos(learnings);
              setShowCount(0);
            }}
            className="show-moreLess"
          >
            {'Show less'}
          </button>
        )}
      </div>
    );
  };
  const handleEventCreation = async (item) => {
    let inputData = {
      id: item?.id,
      type: item?.type,
    };
    await generateEvent(inputData);
  };
  const displaylearnVideo = (item) => {
    return (
      <VideoPlayer
        URL={item?.link}
        handleStart={() => {
          handleEventCreation(item);
        }}
      />
    );
  };
  const displayLearnArticle = (data) => {
    return (
      <a href={data?.link} target="_blank" rel="noopener noreferrer">
        <img
          src={data?.url}
          className="learn-image"
          alt="Learn Article"
          onClick={() => {
            handleEventCreation(data);
          }}
        />
      </a>
    );
  };
  const displayVideos = (title, data, videosData) => {
    return (
      <div>
        <div className="videos-container">
          {data?.slice(0, videosData).map((learnItem, index) => (
            <div className="videos-details" key={index}>
              {learnItem?.type == strings?.ai_expert?.video && (
                <div>{displaylearnVideo(learnItem)}</div>
              )}
              {learnItem?.type == strings?.ai_expert?.article && (
                <div>{displayLearnArticle(learnItem)}</div>
              )}
              {learnItem?.type == strings?.ai_expert?.podcast && (
                <div>{displayLearnArticle(learnItem)}</div>
              )}
              <div className="video-title">
                <img src={learnItem?.learnImage} className="img" alt="Image" />
                <h5 className="text">{learnItem?.title}</h5>
              </div>
              <h6 className="video-description">
                {videoDescriptions[index]
                  ? cleanHtmlTags(learnItem?.description)
                  : learnItem?.description.length > 60
                    ? `${cleanHtmlTags(learnItem?.description).substring(
                        0,
                        60,
                      )}...`
                    : cleanHtmlTags(learnItem?.description)}
                {learnItem?.description.length > 60 && (
                  <button
                    className="show-more-button"
                    onClick={() => toggleDescription(index)}
                  >
                    {videoDescriptions[index] ? 'Show less' : 'Show more'}
                  </button>
                )}
              </h6>
            </div>
          ))}
        </div>
        <div style={{ paddingLeft: '40%' }}>
          {isLoading && (
            <Spinner
              animation="border"
              style={{ color: COLORS.GRAY, alignItems: 'center' }}
            />
          )}
        </div>
        {moreOrLess()}
      </div>
    );
  };

  return (
    <Container>
      <div className="prompt-main">
        <p className="header-text">{ALLNAMES.LEARNFROMEXPERTS}</p>
      </div>
      {displayVideos(ALLNAMES?.LEARNVIDEOS, videosData, displayedVideos)}
    </Container>
  );
};

export default LearnFromExperts;
