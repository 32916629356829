import cardImage from '../assets/everydayAi.svg';
import dailyPrompt from '../assets/promptday.png';
import promptLibrary from '../assets/promptList.png';
import expertsLearnign from '../assets/learningExpert.png';

import curiosityGify from '../assets/Animation_Json files/Animation_Json files/Curiosity Triggers.json';
import questionGify from '../assets/Animation_Json files/Animation_Json files/Question Bank.json';
import learningGify from '../assets/Animation_Json files/Animation_Json files/Lesson Plans.json';
import tipsGify from '../assets/Animation_Json files/Animation_Json files/Learning Activities.json';
import lessonGify from '../assets/Animation_Json files/Animation_Json files/Engagement Tips.json';
import { routesLinks } from './RouteConstants';

export const STRINGS = {
  noSubject: 'noSubject',
  active: 'Active',
  home: {
    Menu: 'Menu',
    curiosity_triggers: {
      name: 'curiosity',
      action: 'Generate',
      navigation: routesLinks.curiositytriggers,
      animation: curiosityGify,
    },
    question_bank: {
      name: 'question',
      action: 'Build',
      navigation: routesLinks.classroomquestions,
      animation: questionGify,
    },
    lesson_plan: {
      name: 'lesson',
      action: 'Create',
      navigation: routesLinks.lessonplan,
      animation: lessonGify,
    },
    learning_activities: {
      name: 'learning',
      action: 'Design',
      navigation: routesLinks.learningactivities,
      animation: learningGify,
    },
    student_tips: {
      name: 'tip',
      action: 'Discover',
      navigation: routesLinks.engagementtips,
      animation: tipsGify,
    },
    everyday_ai: {
      displaytext: 'Everyday Ai',
      description: 'making you future ready',
      image: cardImage,
      action: 'Discover',
      navigation: routesLinks.aiexpert,
      _id: '1',
    },
    everyday_ai_cards: [
      {
        displaytext: 'Prompt of the Day',
        description: 'Daily dose of magic',
        navigation: routesLinks.promptoftheday,
        _id: '1',
        image: dailyPrompt,
      },
      {
        displaytext: 'Prompts Library',
        description: 'Ideas matter',
        navigation: routesLinks.promptlibrary,
        _id: '2',
        image: promptLibrary,
      },
      {
        displaytext: 'Ai Expert',
        description: 'Get future-ready',
        navigation: routesLinks.aiexpert,
        _id: '3',
        image: expertsLearnign,
      },
    ],
  },
};

export const CURIOSITYTRIGGERS = [
  'Classical',
  'Thought Provoking',
  'Humorous',
  'Gamified',
];
export const EVERYDAYAICARDS = [
  {
    title: 'Prompt of the Day',
    subTitle: 'Daily dose of magic',
    navigation: routesLinks.promptoftheday,
    _id: '1',
  },
  {
    title: 'Prompts Library',
    subTitle: 'More magic',
    navigation: routesLinks.promptlibrary,
    _id: '2',
  },

  {
    title: 'Ai Expert',
    subTitle: 'Get future-ready',
    navigation: routesLinks.aiexpert,
    _id: '3',
  },
];
export const YOUTUBEVIDEOS = [
  'https://www.youtube.com/watch?v=9plZI1Q9vOQ',
  'https://youtu.be/qiAd4fNyG48',
  'https://youtu.be/hrR7_ZzUUY0',
  'https://youtu.be/lXs7fHNF2ho',
  'https://youtu.be/YtE0OsRWeYI',
  'https://www.youtube.com/watch?v=azf2UP9_vnY',
  'https://youtu.be/pOLmD_WVY-E',
  'https://youtu.be/m2eINI4WXkc',
  'https://www.youtube.com/watch?v=suI67lKRlfo',
  'https://youtu.be/P9WFpVsRtQg',
  'https://www.youtube.com/watch?v=aCVvwMJPmuo',
];

export const PROMPTYPES = {
  ALL: 'All',
  FAVOURITES: 'My Favourites',
  JOURNEY: 'Midjourney',
  CHATGPT: 'ChatGPT',
};
export const PROVOKING_STATEMENT = 'This is a text about outcome below:';
export const LESSONPLANNING_STATEMENT =
  'Here is the lesson planning which you can use in teaching :';
export const CURIOSITY_TRIGGERS = 'Curiosity Triggers';
export const SAMPLE_DATA = `Resources and Development: Everything available in our environment which can be used to satisfy our needs provided.\\n
  It is technological accessible, economically feasible and culturally acceptable can be termed as ‘resource’.\\n
  The process of transformation of things available in our environment involves an interactive relationship between Nature technology and institutions.Human being interact with nature through technology and create institutions to accelerate their economic development of resources. 
  Resources functions of human activities. Human beings themselves are essential component of resources. They transform material available in our environment into the sources and use them. These resources and development can be classified in the following ways:-
  \\n(a) on the basis of origin: biotic and abiotic
  \\n(b) on the basis of exhaustibility: renewable and nonrenewable
  \\n(c) on the basis of ownership: individual, community, National and international
  \\n(d) on the basis of status of development: potential, developed, stock and reserves.`;
export const AGE_GROUPS = ['6 - 8', '8 - 11', '11 - 14'];
export const TIPS_CATEGORIES = [
  { title: 'Behavioural Scenarios' },
  { title: 'Learning Scenarios' },
  { title: 'Emotional Scenarios' },
];
export const TOSELECT_SCENARIO = 'Select Scenario';
export const ENGAGEMENT_TIP = 'Here is the engagement tip which you can use :';
export const CONSENT = 'I Consent I want to be a AI Driven Teacher';

export const COUNTRY_CODES = [
  {
    country: 'India ',
    countryCode: '+91',
    mobileNumberLength: 10,
  },
  {
    country: 'United Arab Emirates ',
    countryCode: '+971',
    mobileNumberLength: 9,
  },
  // {
  //   country: 'Canada ',
  //   countryCode: '+1',
  //   mobileNumberLength: 10,
  // },
  // {
  //   country: 'Saudi Arabia ',
  //   countryCode: '+966',
  //   mobileNumberLength: 9,
  // },
  // {
  //   country: 'Singapore ',
  //   countryCode: '+65',
  //   mobileNumberLength: 8,
  // },
  // {
  //   country: 'United Arab Emirates ',
  //   countryCode: '+971',
  //   mobileNumberLength: 9,
  // },
  // {
  //   country: 'United Kingdom ',
  //   countryCode: '+44',
  //   mobileNumberLength: 10,
  // },
  // {
  //   country: 'United States of America ',
  //   countryCode: '+1',
  //   mobileNumberLength: 10,
  // },
];
