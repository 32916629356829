import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { COUNTRY_CODES } from './CONSTANTS';
import { saveBrowserHistory } from '../apis/callAPi';

export const getUserId = async () => {
  let userId = null;
  try {
    userId = await localStorage.getItem('userId');
  } catch (e) {
    console.log(e);
  }
  return userId;
};
export const getBrowserFingerprint = async () => {
  const visitorId = await getCurrentBrowserFingerPrint();
  return visitorId;
};

export const mobileNumberValidation = (input, countryCode) => {
  let res = COUNTRY_CODES?.filter((item) => {
    return (
      countryCode == item?.countryCode &&
      input?.length == item?.mobileNumberLength
    );
  });
  return res?.length > 0;
};

export const updatingCookies = async (setCookie, attempts) => {
  setCookie(
    'myCookies',
    {
      noOfAttempts: attempts,
      lastAttempted: new Date(),
    },
    {
      path: '/',
    },
  );
};
export const ENABLED_SUBJECTS = {
  'Grade 1': ['english', 'mathematics'],
  'Grade 2': ['english', 'mathematics'],
  'Grade 3': ['english', 'mathematics', 'evs'],
  'Grade 4': ['english', 'mathematics', 'evs'],
  'Grade 5': ['english', 'mathematics', 'evs'],
  'Grade 6': ['english', 'mathematics', 'science', 'social science'],
  'Grade 7': ['english', 'mathematics', 'science', 'social science'],
  'Grade 8': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 9': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 10': [
    'english',
    'mathematics',
    'social science',
    'physics',
    'chemistry',
    'biology',
  ],
  'Grade 11': [
    'english',
    'mathematics',
    'economics',
    'physics',
    'chemistry',
    'business studies',
    'biology',
  ],
  'Grade 12': [
    'english',
    'mathematics',
    'economics',
    'physics',
    'chemistry',
    'business studies',
    'biology',
  ],
};

export const getExpirationDate = (secondsToAdd) => {
  const currentDate = new Date();
  let minutes = secondsToAdd / 60;
  minutes = minutes - 2;
  currentDate.setSeconds(currentDate.getSeconds() + minutes * 60);
  const formattedDate = currentDate.toISOString();
  return formattedDate;
};

export const saveBrowserDetails = async (searchParams) => {
  const browserParams = {};
  for (let [key, value] of searchParams.entries()) {
    browserParams[key] = value;
  }
  await saveBrowserHistory(browserParams);
};
