/* eslint-disable */
import React from "react";
import styles from "./styles/terms-and-conditions.module.css";
import ContactUsCard from "./contactus-card";

export default function TermsContent() {
  return (
    <div className={styles["sub-container"]}>
      <div className={styles.title}>Terms Of Service</div>
      <div className={styles["header"]}>
        <div className={styles["header-left"]}>
          <div className={styles["sub-title"]}>
            {`THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.`}
          </div>
          <div className={styles["sub-title"]}>
            {`THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND CONSUMER PROTECTION (E-COMMERCE) RULES, 2020 THAT REQUIRES PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF SERVICE FOR ACCESS OR USAGE OF THIS WEBSITE.`}
          </div>
          <div>
            <div className={styles["sub-title"]}>INTRODUCTION</div>
            <ul className={styles["info-text"]}>
              <li>{`Welcome to www.coschool.ai (hereinafter referred to as the “Website” or “Coschool”), owned and operated by People Combine Basics Private Limited, a company incorporated under the Companies Act, 2013 and/or its affiliates (“Company” “People Combine” “we” / “our” / “us”). This Website offers AI driven services designed to enhance the learning experience for teachers, and students.  It provides a suite of features, including Vin, Ai Tutor, Ai Assistant, which facilitate teachers and students in experiencing world-class learning by providing them with a one stop end-to-end solution for Users registered on the Website. To ensure this, it is essential that we create a safe and secure environment where teachers freely share content and ideas. Creating such a safe and secure environment is central to the success of our endeavors.`}</li>
              <li>{`Please read these terms of services (“Terms of Services” or “Terms”) carefully. By accessing, browsing, registering or otherwise using the Website, or any other websites or applications of Company that link to these Terms, and/or by accessing, using or availing any of the services and/or the contents including the information, books, reports, journals, data, study, reading material, graphics, audio and videos (“Material”)  provided or made available on and through the Website you (“you” or “your”)  represent that you have read and understood the Terms an/or any supplementary documents under it and thereby expressly agree to be bound by the Terms. If you do not agree with the Terms mentioned herein, please exit this Website and restrict your access.`}</li>
              <li>{`This acceptance constitutes a binding and enforceable agreement between the User and Company. The Terms of Service do not alter in any way the terms or conditions of any other written agreement you may have with People Combine for any other services. `}</li>
              <li>{`We reserve the right, at our sole discretion, to modify, amend, or change the Terms of Service at any time without any prior notice. Notice of any material change will be posted on this page with an updated effective date. In certain circumstances as required under applicable law, we may notify you of a change to the Terms of Service via SMS or WhatsApp; however, you are responsible for regularly checking this page for any changes. Your continued access or use of our Service constitutes your ongoing consent to any changes, and as a result, you will be legally bound by the updated Terms of Service. If you do not accept a change to the Terms of Service, you must stop accessing or using Website immediately. By continuing to use Coschool after any such changes are notified and made, you are expressing and acknowledging the acceptance of such changes. As long as You comply with these Terms, the Company grants you a personal, non-exclusive, non-transferable, revocable, non-assignable, limited right to access and use the Website.`}</li>
              <li>{`If you are a parent or legal guardian and are providing consent for your Child to register with the Service, you agree to be bound by these Terms of Service in respect of such Child’s use of the Service.`}</li>
              <li>{`It is clarified that the Coschool Privacy Policy, that is provided separately on the Website, forms an integral part of these Terms of Service and should be read in conjunction. Words and expressions used in this Terms of Service but not defined herein but defined in the Information Technology Act 2000 (“IT Act”), Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011 (“SPDI Rules”), Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules 2021 (“Intermediary Guidelines”), or the Coschool Privacy Policy will have the meanings assigned to them thereunder. `}</li>
              <li>{`We ensure compliance with all applicable laws, including the Digital Personal Data Protection Act 2023.`}</li>
              <li>{`Illegality or unenforceability of one or more Terms of Service shall not affect the legality and enforceability of the other terms of the Terms of Service.`}</li>
            </ul>
          </div>
        </div>
        <div className={styles["header-right"]}>
          <ContactUsCard />
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>DEFINITIONS</div>
        <ul className={styles["info-text"]}>
          <li>{`“Agreement” means the Terms of Service and any supplementary documents referred to herein.`}</li>
          <li>{`“Child” / “Children”/ “Minor” means a person who has not completed the age of 18 years.`}</li>
          <li>{`“Confidential Information” means any information that: (a) if disclosed in writing, is labelled as “confidential” or “proprietary”; (b) if disclosed orally, is designated confidential at disclosure; or (c) whether it being written or oral and whether it being designated as confidential or not, by its nature, and/or the circumstances of its disclosure, makes it reasonably likely that it is confidential. Confidential Information may include, but is not limited to, trade secrets, discoveries, ideas, concepts, know-how, techniques, future product plans, designs, specifications, data, computer programs, business activities and operations, customer lists, reports, studies, personnel information, financial information, information disclosed by demonstration of any product and other technical and business information.`}</li>
          <li>{`“Intellectual Property Rights” means all copyright and related rights, design rights, registered designs, patents, trade and service marks (registered and unregistered), database rights, technical know-how, rights in confidential information and all other intellectual property rights throughout the world for the full term of the rights concerned that are owned by People Combine.`}</li>
          <li>{`“Service(s)” means access to web portals provided on the Website, including but not limited to, Ai Assistant, Ai Tutor, and Vin, which facilitate lesson planning, generating question banks, documentation, evidence collection, assessment, engagement tips, learning tips, curiosity triggers and any other tools or features that may be developed in the future.`}</li>
          <li>{`“User(s)” includes students, teachers, parents that use the Services. It shall also mean and include any natural or legal person who has agreed to these Terms of Service.`}</li>
          <li>{`“User Account Information” means information requested by Coschool in relation to a User in order to set up and register the Users Account, for the Services.`}</li>
          <li>{`“Force Majeure Event” shall mean any event beyond the Company’s reasonable control, including, but not limited to, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts, or industrial action of any kind, riots, insurrection, war, acts of government, foreign or governmental order, quarantine, an outbreak of an epidemic, pandemic, or other communicable diseases, computer hacking, civil disturbances, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, destructive or corrupting code or program, mechanical or technical errors/failures or power shut down, faults or failures in telecommunication etc. and any other similar events not within the control of the Company and which the Company is not able to overcome.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>ELIGIBILITY</div>
        <ul className={styles["info-text"]}>
          <li>{`Use of the Coschool is available only to persons who can enter into legally binding contracts under the Indian Contract Act, 1872. `}</li>
          <li>{`If you represent an entity, organization, or any other legal person then you hereby confirm and represent that you have the necessary power and authority to bind such entity, organization, or legal person to these Terms.`}</li>
          <li>{`The Website is open to individuals of all ages, including Minors. However, Minors are required to register with the permission of their legal guardian or in accordance with the laws and regulations of their respective jurisdictions.`}</li>
          <li>{`Minors must obtain permission from their legal guardian or comply with relevant laws and regulations before registering on the Website. By registering as a Minor, you confirm that you have obtained the necessary consent from your legal guardian or complied with applicable laws and regulations.`}</li>
          <li>{`Failure to register with the permission of your legal guardian or in compliance with relevant laws and regulations renders you ineligible to use the Website. In such cases, the Company shall not be liable for any consequences arising from unauthorized use of the Website by Minors. The legal guardians of the Minors shall remain responsible for the compliance to the provisions of these Terms.`}</li>
          <li>{`Individuals who are undischarged insolvents or declared to be of unsound mind are not permitted to use the Website. Undischarged insolvents are individuals who have been declared insolvent by a court and have not been discharged from their debts. Persons of unsound mind are individuals who have been declared legally incompetent or incapable of managing their own affairs due to mental illness or incapacity. If you are an undischarged insolvent or a person of unsound mind, you are hereby notified that you are prohibited from using the Website. The Company reserves the right to verify the eligibility of Users and may take appropriate action to enforce this prohibition.`}</li>
          <li>{`By using the Website, you acknowledge and agree that you are not an undischarged insolvent or a person of unsound mind, and you warrant that you meet the eligibility criteria outlined herein. Any violation of this caveat may result in the termination of your access to Coschool.`}</li>
          <li>{`The Company, at any time, reserves the right to terminate any User’s registration and/or refuse to provide such person with access to the Website if it is brought to the Company’s notice or if it is discovered that such person is not eligible to use the Website.`}</li>
          <li>{`Those who access the Website from outside India are responsible for compliance with local laws, if and to the extent local laws are applicable. The Company shall render the Services ordered only within India (“Territory”) and shall not be liable to perform any Services outside the Territory. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>ACCEPTANCE OF TERMS</div>
        <ul className={styles["info-text"]}>
          <li>{`The User is required to carefully read these Terms of Service, as it contains the terms and conditions governing the User’s use of the Website and any content such as text, data, information, software, graphics, audio, video or photographs etc. that the Company may make available through the Website along with any services that the Company may provide through the Website. By further accessing or using the Website, the User is agreeing to these Terms and entering into a legally binding contract with the Company. The User may not use the Website if the User does not accept the Terms or is unable to be bound by these Terms. `}</li>
          <li>{`In case you are using the Services in an individual capacity, you represent that you have the authority and permission to enter into this Terms of Service on your own behalf or on behalf of a Minor (in the case of a Legal Guardian of a Minor).`}</li>
          <li>{`In order to use the Website, the User must first agree to the Terms of Service. The User can accept the Terms by:`}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`logging into the Website in the manner indicated herein; or`}</li>
            <li>{`actually using the Website. In this case, the User understands and agrees that the Company will treat the User’s use of the Website as acceptance of the Terms from that point onwards.`}</li>
          </ol>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>ACCOUNT REGISTRATION</div>
        <ul className={styles["info-text"]}>
          <li>{`Access to the Website for unregistered users will be limited to information about the Services.`}</li>
          <li>{`In order to use the Website, as part of the sign-up process, the User will have to create an account on the Website (“User Account”), which can be done by signing up through mobile number and one time password (OTP) or through such other means as may be indicated by the Website from time to time.`}</li>
          <li>{`While signing-up with the Company to use the Website, the User shall not:`}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`create a User Account for anyone other than the User, unless such person’s prior permission has been obtained;`}</li>
            <li>{`use a User Account that is the name of another person with the intent to impersonate that person; or`}</li>
            <li>{`create more than one User Account on the Website. `}</li>
          </ol>
          <li>{`The Company shall not and will not be liable for any loss or damage arising from the User’s failure to comply with this Clause.`}</li>
          <li>{`The User hereby confirms and undertakes that the User Account Information provided by the User while signing-up is and will be true, accurate, up-to-date, and complete at all times. The User agrees that if the User provides any information to the Company that is untrue, inaccurate, not up-to-date or incomplete or becomes untrue, inaccurate, not up-to-date or incomplete, the Company shall have the right to indefinitely suspend or terminate or block access to the User Account on the Website and refuse to provide the User access to the Website.`}</li>
          <li>{`Once registered, the User can log in to the User Account by using the mobile number provided at the time of signing-up and OTP sent on such registered mobile number. (“User Login Credentials”).`}</li>
          <li>{`The User understands that once the User registers as a ‘user’ on the Website, the User may receive multimedia text messages as SMS, WhatsApp, Facebook messenger, Instagram, Telegram and phone calls from the Company on the registered mobile number and the User may receive e-mails on the User’s registered e-mail address. These messages, e-mails and calls could relate to the User’s registration, transactions that the User carries out through the Website and promotions that are undertaken by the Company. The User, hereby, by way of accepting these Terms consent to the receipt of such communication from the Company`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>ACCOUNT CONFIDENTIALITY</div>
        <ul className={styles["info-text"]}>
          <li>{`You are responsible for maintaining the confidentiality of User Login Credentials  and for any activity that occurs in or through User Account. We will not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your User Login Credentials. You should take all necessary steps to ensure that the password is kept confidential and secure. In case you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner, you should inform us immediately at info@coschool.ai.`}</li>
          <li>{`You also agree and confirm that you will provide accurate, current and complete information whenever prompted by Coschool or when required by the Coschool’s registration form (“Registration Data”). You agree to maintain and promptly update your Registration Data to keep it accurate, current and complete at all times. We reserve the right to confirm and validate the information and other details provided by you at any point of time under applicable laws. If you provide any information that is untrue, inaccurate, incomplete, or not current or if we have reasonable grounds to suspect that such information is not in accordance with the Terms of Service (whether wholly or in part thereof), we reserve the right to reject your registration and/or indefinitely suspend or terminate your membership and to refuse to provide you access to the Service.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>CONFIDENTIALITY AND PRIVACY</div>
        <ul className={styles["info-text"]}>
          <li>{`We are focused on preserving the privacy of our Users. We require Users to provide only that information which is essential for availing and using the Website and Service. In order to avail the Service, you will need to provide Registration Data prior to getting registered. As an User Account holder, you are required to provide us with accurate personal information. Any personal information (including sensitive personal data or information) collected by us shall not be disclosed to any third party unless such a disclosure is authorized or required by law, or you have expressly consented to such a disclosure. By accepting the Terms of Service, you grant us consent to share your personal information with our partners, affiliates, employees and agents for administrative purposes.`}</li>
          <li>{`Any and all Confidential Information exchanged between People Combine and the Users shall be kept strictly confidential and not disclosed to any third party without prior written consent of the owner of the Confidential Information. Confidential Information shall be treated and stored carefully and appropriately at all times so that the Confidential Information is not inadvertently made available to any third party or otherwise disclosed in breach of this Agreement.`}</li>
          <li>{`Notwithstanding the aforesaid, this Agreement shall not prohibit the disclosure of Confidential Information to the extent that such disclosure is permitted or required by law, regulation or order of a court or other governmental authority. Further, we may disclose Confidential Information on a need-to-know basis to our contractors with whom we have executed written agreements requiring them to maintain such information in strict confidence and use it only to facilitate the performance of their services in connection with the Service.`}</li>
          <li>{`All the data that the User enters in Coschool belongs to the User, however, Users may not copy, make transcriptions or recordings or in any other way reproduce or duplicate any document or other medium containing Confidential Information or Intellectual Property Rights, without our written consent. Upon termination of this Agreement, the User may request the us to provide a copy of all their data.`}</li>
          <li>{`We will comply with applicable data protection regulations as well as the Privacy Policy in relation to any User’s personal data that it may collect and process for the purposes of supplying the Service. We will process any such personal data in accordance with the Privacy Policy, available online at coschool.ai`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>USER’S POSTS AND REVIEWS</div>
        <ul className={styles["info-text"]}>
          <li>{`By submitting, providing, uploading, posting, e-mailing, transmitting or otherwise making available on the Website or through the Services any content to the Company, including any information, data, reading material, graphics, audio, video and content (“User Content”), You: (i) grant to the Company a non-exclusive, unconditional, perpetual, non-revocable, worldwide, royalty-free and sub-licensable license and right to access, use, copy, view, download, reproduce, modify, adapt, distribute, publish, transmit, make available, and display the User Content in connection with the Services throughout the world, and create derivate works of the User Content in any manner as deemed appropriate by the Company in any form including but not limited to print, broadcast, online and across any and all platforms owned by the Company, any and all platform owned by the Company on any social media channels including but not limited to LinkedIn, Instagram, Facebook and X; (ii) waive your moral rights under section 57 of the Indian Copyright Act 1957; (iii) grant us the right to use the name that you submit in connection with such content, if required; and (iv) agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your right to be identified as the author of such content and your right to object to any usage or derogatory treatment of such content. You agree to perform all further acts necessary to perfect any of the above rights granted by you to People Combine.`}</li>
          <li>{`The User, being the originator of the User Content, is responsible for the User Content that the User uploads, posts, publishes, transmits or otherwise makes available on the Website. The User represents that the User exclusively owns the copyright in the User Content and has obtained all relevant consents and approvals in order to post any User Content. The User further represents that all such User Content will be in accordance with applicable law. The User acknowledges that the Company does not endorse any User Content on the Website and is not responsible or liable for any User Content. The Company reserves the right to disable access to the User Content on the Website. You are solely responsible for your interactions with other Users of the Services and any other parties with whom you interact through the Services. You agree that Company shall not be responsible for any liabilities incurred as the result of such interaction. You agree that You use all User Content and interact with other Users at your own risk.`}</li>
          <li>{`In case a third party appears in a User Content, the User confirms that the User has obtained prior consent of such third party before uploading or posting such User Content. The User further agrees that the User shall indemnify the Company against any and all claims raised by such third party with respect to the User Content.`}</li>
          <li>{`The User represents and warrants that the User owns or otherwise controls all of the rights to the User Content that the User posts or that the User otherwise provides on or through the Website; and that, as at the date that the User Content is posted or submitted on the Website: (a) the User Content is accurate; (b) use of the User Content the User supplies does not breach these Terms of Service; and (c) that such User Content is lawful. `}</li>
          <li>{`The User further represents and warrants that while posting any User Content on the Website the User shall not use any offensive, libellous, derogatory, hateful or racially, or upload Objectionable Content. Further, the User shall not post any content on the Website that is obscene, pornographic, constitutes an “indecent representation of women” as provided in The Indecent Representation of Women (Prohibition) Act, 1986.`}</li>
          <li>{`You acknowledge that Company has no obligation to review, monitor or pre-screen User Content on the Services, although Company reserves the right in its sole discretion to review, monitor, pre-screen, refuse, modify, or remove any User Content (a) that violates any law or regulation; (b) that violates these Terms of Service; (c) that otherwise creates or may create liability for Company; or (d) for any other reason Company determines in its sole discretion. Except as expressly set forth in these Terms, Company does not approve, endorse or make any representations or warranties with respect to User Content.`}</li>
          <li>{`Although Company takes security very seriously and works very hard to ensure safety of its Users, including by using various industry standard measures to protect the Services, no method of transmission or electronic storage on the Internet is 100% secure. Therefore, Company cannot guarantee the security of any User Content. Except as expressly set forth herein or otherwise agreed to by Company in writing, (a) Company has no obligation to store any User Content and (b) Company has no responsibility or liability for (i) the deletion or accuracy of any User Content, (ii) the failure to store, transmit or receive transmission of User Content; or (iii) the security, privacy, storage or transmission of other communications originating with or involving use of the Services.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          SUPPLY OF SERVICES AND WARRANTIES
        </div>
        <ul className={styles["info-text"]}>
          <li>{`Coschool is a free and non-profitable service portal provided to teachers for generating lesson plans, question banks, engagement tips, learning tips, curiosity triggers, etc., and students to take guided practice lessons on sub-topic and ask any doubts.`}</li>
          <li>{`By signing into the Service and creating an account, you warrant and represent that you are bound to the terms under this Agreement. You further warrant and represent that: `}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`any User Account Information that you submit to on the Website and in the course of availing the Services is true, accurate and complete;`}</li>
            <li>{`you shall comply with any laws and regulations that apply to the use of Coschool (in particular, local regulatory regimes on privacy and data protection);`}</li>
            <li>{`you confirm to accept and comply with the Terms of Service and other relevant terms, and consent to the processing of any personal data as set forth in this Terms of Service and/or Privacy Policy;`}</li>
            <li>{`you shall be responsible for all hardware, communication networks, and other equipment necessary for use of Coschool, and the due installation thereof;`}</li>
            <li>{`you take sole responsibility for all activities conducted through each of your login;`}</li>
            <li>{`you shall not copy, translate, create a derivative work of, reverse engineer, reverse assemble, disassemble, or decompile the Service or any part thereof or otherwise attempt to discover any source code or modify the Service in any manner or form;`}</li>
            <li>{`you shall not use Coschool in a manner that is contrary to applicable law or in violation of any third party rights of privacy or Coschool’s Intellectual Property Rights;`}</li>
            <li>{`you shall not use or knowingly permit the use of any security testing tools in order to probe, scan or attempt to penetrate or ascertain the security of the Service; `}</li>
            <li>{`you shall not engage in service bureau use, outsourcing, renting, reselling, sublicensing, concurrent use of a single User login, or time-sharing of the Service;`}</li>
            <li>{`you shall not access Coschool by any means other than through the Website; and`}</li>
            <li>{`you shall not use or allow Users or third parties to use Coschool in any unauthorized manner or in breach of the Terms of Service.`}</li>
          </ol>
          <li>{`Use of Coschool requires you to register with the portal to be able to directly share the content onto your WhatsApp. `}</li>
          <li>{`The User understands that once the User registers as a ‘user’ on the Website, the User may receive multimedia text messages as SMS, WhatsApp, and phone calls from the Company on the registered mobile number and the User may receive e-mails on the User’s registered e-mail address. These messages, e-mails and calls could relate to the User’s registration, eligibility and promotions that are undertaken by the Company. The User, hereby, by way of accepting these Terms consent to the receipt of such communication from the Company.`}</li>
          <li>{`We reserve the right to make changes to the features and functionality of the Service or the Website as we see fit at our sole discretions. Notwithstanding the aforesaid, we will notify our Users of any material changes in advance where it is reasonably possible to do so and where such prior notice would not adversely affect our commercial interests.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          OBJECTIONABLE CONTENT AND COMPLAINTS
        </div>
        <ul className={styles["info-text"]}>
          <li>{`We, at People Combine, maintain a zero-tolerance policy towards ‘objectionable content’ or Users who harass or negatively impact other Users. You undertake not to host, display, upload, modify, publish, transmit, update or share any objectionable content including information that:`}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`belongs to another person and to which you do not have any right;`}</li>
            <li>{`is insulting or harassing on the basis of gender, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another's privacy including bodily privacy, racially, ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force, in any manner whatsoever;`}</li>
            <li>{`harms minors/ children in any way;`}</li>
            <li>{`infringes any patent, trademark, copyright or other proprietary rights of any person anywhere in the world;`}</li>
            <li>{`violates any law for the time being in force;`}</li>
            <li>{`deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;`}</li>
            <li>{`impersonates another person;`}</li>
            <li>{`contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;`}</li>
            <li>{`threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation;`}</li>
            <li>{`creates liability for People Combine or causes People Combine to lose (in whole or in part) the services of People Combine’s ISPs or other service providers;`}</li>
            <li>{`is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person;`}</li>
            <li>{`involves the transmission of “junk mail”, “chain letters”, or unsolicited mass mailing or “spamming”;`}</li>
            <li>{`contains any trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data or personal information;`}</li>
            <li>{`engages in commercial activities without the Company’s prior written consent such as engages in contests, sweepstakes, barter, advertising etc.;`}</li>
            <li>{`engages in commercial activities without the Company’s prior written consent such as engages in contests, sweepstakes, barter, advertising etc.;`}</li>
            <li>{`is in the nature of political campaigning, unsolicited or unauthorized advertising, promotional and/or commercial solicitation, chain letters, pyramid schemes, mass mailings and/or any form of ‘spam’ or solicitation; and/or`}</li>
            <li>{`is illegal in any other way;`}</li>
          </ol>
          <ol style={{ listStyleType: "none" }}>
            <li>(collectively, “Objectionable Content”). </li>
          </ol>
          <li>{`If you believe that any content on Coschool constitutes as Objectionable Content, you can notify us immediately at info@coschool.ai. We will take reasonable steps to ensure that all flagged content, containing any Objectionable Content, is reviewed and removed (if required) within 24 hours of the Objectionable Content being reported. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>RIGHT TO ACCESS COSCHOOL</div>
        <ul className={styles["info-text"]}>
          <li>{`Subject to Your compliance with these Terms of Service and/or payment of applicable Services fees, if any, Company grants you a limited right to access and make personal use of this Website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Company, as may be applicable. This right does not include any resale or commercial use of this Website or its contents; any collection and use of any Service listings, descriptions, or prices; any derivative use of this Website or its contents; any downloading or copying of User registration information for the benefit of another User; or any use of data mining, robots, or similar data gathering and extraction tools.`}</li>
          <li>{`This Website or any portion of this Website (including but not limited to any copyrighted material, trademarks, or other proprietary information) shall not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Company, as may be applicable.`}</li>
          <li>{`You shall not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Company without express written consent. You shall not use any meta tags or any other "hidden text" utilising Company’s names or trademarks without the express written consent of Company, as applicable. Any unauthorised use terminates the permission or right granted by Company to you.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>LINKS TO THIRD PARTY WEBSITES</div>
        <ul className={styles["info-text"]}>
          <li>{`The Website may contain links and interactive functionality interacting with the websites of third parties. The Company is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such website. Before enabling any sharing functions to communicate with any such website or otherwise visiting any such website, the Company strongly recommends that the User reviews and understands the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>TERMINATION OF AGREEMENT</div>
        <ul className={styles["info-text"]}>
          <li>{`The User’s Account in the Website can be terminated at any time by: `}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`the User, by ceasing to access or use the website platform; or by`}</li>
            <li>{`the Company in its sole discretion, for no reason or any reason including the User’s violation of these Terms or lack of use of Services or abandonment of the User Account.`}</li>
          </ol>
          <li>{`The User acknowledges that the termination of Services may come into effect without any prior notice, and the Company shall thereafter, immediately deactivate or delete the User’s Account and all related information and/or bar any further access to the User’s Account or the Services, subject to applicable law.`}</li>
          <li>{`The disclaimer of warranties, the limitation of liability, Clauses in the Terms that by their nature should survive termination of Your usage of the Website, and the governing law provisions shall survive any termination of these Terms.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>INTELLECTUAL PROPERTY RIGHTS</div>
        <ul className={styles["info-text"]}>
          <li>{`People Combine and its licensors, as applicable, shall remain as the exclusive owner of all intellectual property rights in the Service including but not limited to any Material, information, data, text, logos, photographs, videos, audio clips, animations, written posts, articles, comments, underlying technology, software, scripts, graphics, themes, and interactive features generated, provided or otherwise made accessible on the Service. Users may not remove any legends or statements in the Service, or any Materials provided therewith regarding People Combine’s and its licensors proprietary rights. `}</li>
          <li>{`The Company reserves the right to change or modify the Services and/or the Materials (as the case may be) on the Website from time to time at its sole discretion.`}</li>
          <li>{`Coschool’s trademarks, service marks, trade names, logos, domain names, and any other features of the Service are the sole property of People Combine and its licensors. The license granted to the Users herein does not grant any rights to our intellectual property rights or any other features of the Service, whether for commercial or non-commercial use. You shall not display, print or download extracts from the Website, whether for Your personal or non-personal use, and You shall not commercialise any intellectual property belonging to the Company in any manner.`}</li>
          <li>{`You shall not (whether directly or indirectly including through the use of any program) create a database in an electronic or other form by downloading and storing all or any part of the pages from the Website.`}</li>
          <li>{`We respect to the intellectual property of others, if you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please bring it to our notice by sending an email at info@coschool.ai. We will address your concerns and take necessary steps, if required.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>DISCLAIMER</div>
        <ul className={styles["info-text"]}>
          <li>
            THE SERVICES PROVIDED ON THIS WEBSITE, INCLUDING AI ASSISTANT, AI
            TUTOR, AND VIN, OR SUCH OTHER TOOLS/FEATURES, UTILIZE ADVANCED
            ARTIFICIAL INTELLIGENCE (“AI”) TECHNOLOGY TO ENHANCE THE LEARNING
            EXPERIENCE. HOWEVER, THE COMPANY MAKES NO REPRESENTATIONS OR
            GUARANTEES REGARDING THE ACCURACY, COMPLETENESS, OR RELIABILITY OF
            THE RESPONSES AND INFORMATION GENERATED BY THESE AI DRIVEN SERVICES.
            USERS SHOULD BE AWARE THAT NOT ALL QUERIES ASKED TO AI ASSISTANT, AI
            TUTOR, VIN OR SUCH OTHER TOOLS/FEARTURE AVAILABLE ON THE WEBSITE
            WILL RECEIVE ACCURATE OR COMPLETE ANSWERS. THE COMPANY RECOMMENDS
            THAT USERS INDEPENDENTLY VERIFY ANY INFORMATION OR ADVICE OBTAINED
            FROM THESE SERVICES BEFORE RELYING ON IT FOR EDUCATIONAL OR
            DECISION-MAKING PURPOSES. THE COMPANY SHALL NOT BE LIABLE FOR ANY
            ERRORS, OMISSIONS, OR INACCURACIES IN THE INFORMATION PROVIDED BY
            THESE AI SERVICES OR FOR ANY RELIANCE PLACED ON SUCH INFORMATION BY
            THE USER.
          </li>
          <li>
            THE WEBSITE AND ALL INFORMATION, CONTENT, MATERIALS AND SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO THE USER THROUGH THE
            WEBSITE (COLLECTIVELY, THE “CONTENTS”) ARE PROVIDED BY THE COMPANY
            ON AN “AS IS,” “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR
            WARRANTIES OF ANY KIND. THE COMPANY MAKES NO REPRESENTATIONS OR
            WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
            THE WEBSITE, THE ACCURACY OR COMPLETENESS OF THE CONTENTS AND THE
            ACCURACY OF THE INFORMATION. THE COMPANY SHALL HAVE NO
            RESPONSIBILITY FOR ANY DAMAGE TO THE USER’S COMPUTER SYSTEM OR LOSS
            OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS,
            DOCUMENT OR INFORMATION. THE USER EXPRESSLY AGREES THAT THE USE OF
            THE WEBSITE IS AT THE USER’S SOLE RISK. THE COMPANY WILL NOT BE
            LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
            WEBSITE OR THE CONTENTS INCLUDING, WITHOUT LIMITATION, DIRECT,
            INDIRECT, CONSEQUENTIAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS
            OTHERWISE SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW,
            THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES
            WITH RESPECT TO THE WEBSITE AND ITS CONTENTS, WHETHER EXPRESS OR
            IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE,
            MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE OR USE.
          </li>
          <li>
            THE USER FURTHER ACKNOWLEDGES AND AGREES THAT COMPANY DOES NOT
            WARRANT THAT THE ACCESS TO THE SERVICES THROUGH THE WEBSITE OR
            VARIOUS NETWORK CHANNELS OF THIRD-PARTY SERVICE PROVIDERS INCLUDING
            THE INFRASTRUCTURE MAINTAINED BY THE USER, ALL OF WHICH ARE BEYOND
            THE COMPANY’S CONTROL, WILL BE UNINTERRUPTED, TIMELY, ERROR-FREE.
            THE SERVICES WILL ACCORDINGLY NOT BE CONSIDERED UNAVAILABLE FOR ANY
            OUTAGES DUE TO: (I) ACTS OR OMISSIONS OF THE USERS; (II) FAILURES OF
            EQUIPMENT OF THE USER, NETWORK UNAVAILABILITY OR BANDWIDTH
            LIMITATIONS ON HOSTING SERVERS SUBSCRIBED TO BY THE COMPANY AND/OR
            THE USER; OR (III) ISSUES ARISING FROM BUGS OR OTHER PROBLEMS IN THE
            WEBSITE, SOFTWARE, FIRMWARE OR HARDWARE OF THE COMPANY, USER AND/OR
            ANY THIRD-PARTY SERVICE PROVIDER. PLEASE NOTE THAT INFORMATION
            PROVIDED ON THE WEBSITE HAS NOT BEEN EVALUATED AND/OR APPROVED BY
            ANY STATUTARY BODY OR AUTHORITY IN INDIA.
          </li>
          <li>
            THE USER AGREES AND ACKNOWLEDGES THAT BY PROVIDING THE SERVICES, THE
            COMPANY IS MERELY EXTENDING A FACILITY TO ASSIST THE USERS TO
            ENHANCE THEIR EDUCATIONAL EXPERIENCE AND THE COMPANY MAKES NO
            REPRESENTATIONS OR GUARANTEES THAT AS A RESULT OF THE SERVICES TO BE
            PROVIDED BY THE COMPANY, (A) USERS’ ACADEMIC PERFORMANCE WILL
            INCREASE; AND/OR (B) USERS’ OPERATIONAL PERFORMANCE WILL INCREASE.
            IT IS AGREED BY THE USER THAT THE COMPANY GIVES NO ASSURANCE ABOUT
            THE ACCURACY, RESULTS OF USE, COMPLETENESS, OR ADEQUACY OF THE
            SERVICES WHICH THE USER RECEIVES FROM THE COMPANY AND THE USER WILL
            RELY EXCLUSIVELY ON THE RESULTS OF ITS OWN INVESTIGATIONS CARRIED
            OUT WITH RESPECT TO THE USE AND UTILIZATION OF THE SERVICES BY THE
            USER. THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY NATURE
            WHATSOEVER RESULTING FROM THE USE OF THE SERVICES BY THE USER.
          </li>
          <li>
            THE USER FURTHER AGREES THAT THE SERVICES HEREIN IS MERE
            SUPPLEMENTAL AND NOT A SUBSTITUTION TO THE REAL AND TRADITIONAL
            EDUCATION SYSTEM. THE COMPANY MAKES NO GUARANTEES WITH RESPECT TO
            THE AVAILABILITY OR UPTIME OF ANY OF THE SERVICES. IN THE EVENT OF
            DEGRADATION OR INSTABILITY OF COMPANY’S SYSTEM OR AN EMERGENCY, THE
            COMPANY MAY, IN ITS SOLE DISCRETION, TEMPORARILY SUSPEND USER’S
            ACCESS TO THE SERVICES UNDER THIS AGREEMENT PROVIDED HOWEVER AS SOON
            AS REASONABLY PRACTICAL, THE COMPANY SHALL PROVIDE REASON FOR THE
            SUSPENSION TO ENABLE THE USER TO TAKE REMEDIAL MEASURES IN ORDER TO
            ENABLE THE COMPANY TO RESTORE THE SERVICES.
          </li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>INDEMNITY</div>
        <ol className={styles["info-text"]} style={{ listStyleType: "none" }}>
          <li>{`You shall indemnify and hold harmless People Combine, and its respective officers, directors, agents and employees, from any claim or demand or loss, or actions including attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms of Service or any document incorporated by reference, or your violation of any applicable laws, rules, regulations or the rights of any third party. This indemnification includes, without limitation, liability relating to copyright infringement, defamation, invasion of privacy, trademark infringement and any other breach of this Terms of Service.`}</li>
        </ol>
      </div>
      <div>
        <div className={styles["sub-title"]}>LIMITATION OF LIABILITY</div>
        <ul className={styles["info-text"]}>
          <li>{`The content or any information available on Coschool is provided on an “as is” and “as available” basis and without warranties of any kind either expressed or implied, to the fullest extent permissible pursuant to applicable laws, including but not limited to:`}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`any implied warranty or merchantability, fitness for a particular purpose;`}</li>
            <li>{`that the contents of the Service will be uninterrupted or error-free;`}</li>
            <li>{`that defects, if any on the Service will be corrected;`}</li>
            <li>{`the Service being constantly available or available at all;`}</li>
            <li>{`installation or uninstallation choices in relation to the Service being successfully executed in all cases;`}</li>
            <li>{`your personal ability to use the Service;`}</li>
            <li>{`your satisfaction with the use of the Service;`}</li>
            <li>{`that all bugs or errors in relation to the Service will be corrected;`}</li>
            <li>{`that the Service will be compatible with all devices and all networks;`}</li>
            <li>{`that the Service is fit for a particular purpose or use;`}</li>
          </ol>
          <li>{`People Combine, its officers, directors, employees, affiliates and agents and any other service provider(s) responsible for providing access to Coschool in connection with this Agreement will not be liable for any acts or omissions, including of a third party, or for any unauthorized interception of data or breaches of this Agreement attributable in part to the acts or omissions of third parties, or for damages that result from the operation systems, equipment, facilities or services provided by third parties that are interconnected with People Combine.`}</li>
          <li>{`Content on Coschool may include links to other mobile applications and/or websites (some of which may be automated search results) which may contain materials that are objectionable, unlawful, or inaccurate. We do not endorse or support these links or the products and services they provide. We are not responsible or liable for the content or accuracy of such links.`}</li>
          <li>{`We may stop provision of the Service (or any part thereof), permanently or temporarily, to you or to Users generally or may modify or change the nature of the Service and/or these Terms of Service at our sole discretion, without any prior notice to you. Your use of the Service following any such modification constitutes your acceptance to be bound by the Agreement (or as it may be modified).`}</li>
          <li>{`You agree that you are responsible for all charges you incur through use of the Website.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>MISCELLANEOUS</div>
        <ul className={styles["info-text"]}>
          <li>{`Any notice or other communication to be given or served under or in connection with this Agreement shall be given in writing online.`}</li>
          <li>{`The Terms of Service, along with the Coschool Privacy Policy, and any other guidelines made applicable to the Website from time to time, constitute the entire agreement between People Combine and the Users with respect to the Users’ access to or use of the Website and Service.`}</li>
          <li>{`Users may not assign, mortgage, charge any of its rights or sub-contract or otherwise delegate any of its obligations under this Agreement, except with the written consent of the People Combine.`}</li>
          <li>{`All provisions of this Agreement that can be reasonably interpreted to survive upon the full performance, expiry, termination, cancellation or voidance of the Agreement (in particular provisions on intellectual property rights and confidentiality) shall survive said performance, expiry, termination, cancellation or voidance.`}</li>
          <li>{`In the event of a Force Majeure Event, the Company shall not be liable for any unavailability or interruption of the Services provided on the Website. The Company disclaims any responsibility or liability for any delay or failure in the performance of its obligations under these Terms of Service due to the occurrence of a Force Majeure Event.`}</li>
          <li>{`It is expressly agreed by the parties that the Company may assign all or part of its rights or duties under these Terms without such assignment being considered a change to these Terms and without notice to the Users, except to the extent provided by law.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>MONITORING CONTENT</div>
        <ol className={styles["info-text"]} style={{ listStyleType: "none" }}>
          <li>{`You acknowledge and agree that People Combine reserves the right to, and may from time to time, monitor any and all information transmitted or received through the Website and Services, as required under applicable law.`}</li>
        </ol>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          APPLICABLE LAW AND DISPUTE RESOLUTION
        </div>
        <ol className={styles["info-text"]} style={{ listStyleType: "none" }}>
          <li>{`These Terms of Service shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall exclusively be in Hyderabad, Telangana, India. In the event of any dispute arising out of these Terms the same shall be settled by a binding arbitration conducted by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and Conciliation Act, 1996. The venue of arbitration shall be Hyderabad, Telangana, India.`}</li>
        </ol>
      </div>
      <div>
        <div className={styles["sub-title"]}>GRIEVANCE REDRESSAL MECHANISM</div>
        <ol className={styles["info-text"]} style={{ listStyleType: "none" }}>
          <li>{`In accordance with the Information Technology Act, 2000 and Rules made thereunder, the name and contact details of the Grievance Officer are provided below:`}</li>
          <table>
            <tr>
              <td>Name</td>
              <td>Vishwa Paresh Talati</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                Plot No.99, Road No.72, Jubilee Hills Co-operative Society,
                Jubilee Hills, Shaikpet, 500033, Hyderabad, Telangana, India
              </td>
            </tr>
            <tr>
              <td>Cell</td>
              <td>+91-7075780899</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>info@coschool.ai</td>
            </tr>
          </table>
        </ol>
      </div>
      <div>
        <div className={styles["sub-title"]}>CONTACT INFORMATION</div>
        <ol className={styles["info-text"]} style={{ listStyleType: "none" }}>
          <li>{`If you have any questions about these Terms of Service, please feel free to write to us at: info@coschool.ai and we will reach out to you as soon as possible.`}</li>
        </ol>
      </div>
    </div>
  );
}
