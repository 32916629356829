import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function showToast(message, type) {
  console.log(message);
  try {
    if (type == 'success') {
      toast.success(message);
    } else {
      toast.error(message);
    }
  } catch (e) {
    console.log(e);
  }
}
