import { routesLinks } from './RouteConstants';

export const MASTER_CATGORIES = [
  {
    _id: '64c0a6e9acd3585f8a979d93',
    title: 'Generate Curiosity Triggers',
    description: 'Spark interest in the lesson',
    displaytext: 'Curiosity Triggers',
    __v: 0,
    status: true,
    path: routesLinks?.curiositytriggers,
  },
  {
    _id: '64c0a723acd3585f8a979d95',
    title: 'Design Learning Activities',
    description: 'Ensure no student is left behind',
    displaytext: 'Learning Activities',
    __v: 0,
    status: true,
    path: routesLinks?.learningactivities,
  },
  {
    _id: '64c0a7a4acd3585f8a979d97',
    title: 'Build Question Paper',
    description: 'Make assessment creation a joyride',
    displaytext: 'Classroom Questions',
    __v: 0,
    status: true,
    isVariation: true,
    path: routesLinks?.classroomquestions,
  },
  {
    _id: '64c0a7c1acd3585f8a979d99',
    title: 'Get Engagement Tips',
    description: 'Enhance student connections',
    displaytext: 'Engagement Tips',
    __v: 0,
    status: true,
    path: routesLinks?.engagementtips,
  },
  {
    _id: '64c0a7dfacd3585f8a979d9b',
    title: 'Create Lesson Plan',
    description: 'Infuse your unique style',
    displaytext: 'Lesson Plan',
    __v: 0,
    status: true,
    path: routesLinks?.lessonplan,
  },
];
