// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anek Latin&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms {
  margin-left: 36px;
  gap: 20px;
}

.link-button {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 24px;
}
.footer-container {
  color: #919191;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding-top: 48px;
  padding-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  line-height: 14.52px;
}

@media (max-width: 992px) {
  .terms {
    margin-left: 0px;
    margin-top: 8px;
  }
  .footer {
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
  }
  .footer-container {
    color: #919191;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding-top: 48px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    line-height: 14.52px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AACA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,qBAAqB,EAAE,qBAAqB;EAC5C,cAAc,EAAE,mCAAmC;EACnD,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,cAAc;IACd,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,oBAAoB;EACtB;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Anek%20Latin&display=swap\");\n.terms {\n  margin-left: 36px;\n  gap: 20px;\n}\n\n.link-button {\n  text-decoration: none; /* Remove underline */\n  color: inherit; /* Inherit text color from parent */\n  background: none;\n  border: none;\n  cursor: pointer;\n  margin-right: 24px;\n}\n.footer-container {\n  color: #919191;\n  font-family: \"Inter\", sans-serif;\n  font-size: 12px;\n  font-weight: 500;\n  padding-top: 48px;\n  padding-bottom: 24px;\n  display: flex;\n  flex-wrap: wrap;\n  line-height: 14.52px;\n}\n\n@media (max-width: 992px) {\n  .terms {\n    margin-left: 0px;\n    margin-top: 8px;\n  }\n  .footer {\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: center;\n  }\n  .footer-container {\n    color: #919191;\n    font-family: \"Inter\", sans-serif;\n    font-size: 12px;\n    font-weight: 500;\n    padding-top: 48px;\n    padding-bottom: 24px;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-items: center;\n    line-height: 14.52px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
