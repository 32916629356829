/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "../../pages/UnifiedHome/styles/school-enquiry.module.css";
import {
  saveSchoolEnquiry,
  SendOTPForSchoolEnquiry,
  VerifyForSchoolEnquiry,
} from "../../apis/auth";
import { showToast } from "../../components/CustomToast";
import Vin from "../../assets/common-home/vin.svg";
import { useNavigate } from "react-router-dom";
import { routesLinks } from "../../utils/RouteConstants";

const initialBlock = {
  name: "",
  phoneNumber: "",
  email: "",
  city: "",
  designation: "",
  schoolName: "",
};

const OtpText = `OTP will be sent to your mobile number for verification`;
const submittedText =
  "We will be in touch soon with your personal school solution!";
export default function SchoolEnquiryForm() {
  const [schoolDetails, setSchoolDetails] = useState(initialBlock);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [authData, setAuthData] = useState({});
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setIsSubmitted(false);
    setSchoolDetails(initialBlock);
    setStep(0);
  }, []);

  const isValidEmail = (email = "") =>
    /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  const isValidMobile = (number = "") =>
    /^\d{10}$/.test(number) || number === "";
  const isNonEmpty = (text = "") => text?.length > 0;
  const isValidText = (text = "") => /^[a-zA-Z\s]+$/.test(text);

  const handleChange = (key, value) => {
    let existing = { ...schoolDetails };
    if (key === "phoneNumber") {
      if (value !== "" && !/^\d{1,10}$/.test(value)) {
        return;
      }
    } else if (["name", "city", "schoolName"].includes(key)) {
      if (value !== "" && !isValidText(value)) {
        return;
      }
    }

    existing[key] = value;
    setSchoolDetails(existing);
  };

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const handleVerifyOTP = async () => {
    let { success, message } = await VerifyForSchoolEnquiry(authData, otp);
    if (success) {
      setStep(2);
      handleSubmit();
    } else {
      showToast(message ? message : "Something went wrong !");
    }
  };

  const handleSubmit = async () => {
    setIsSubmitted(false);
    let { success = false, data = {} } = await saveSchoolEnquiry(schoolDetails);
    if (success) {
      setIsSubmitted(true);
      // showToast(data?.message, "success");
    } else {
      showToast(data?.message ? data?.message : "Something went wrong !");
    }
  };

  const handleSendOTP = async () => {
    let { success, data } = await SendOTPForSchoolEnquiry(
      schoolDetails?.phoneNumber,
    );
    if (success) {
      setSeconds(59);
      setAuthData(data?.data);
      setStep(1);
    } else {
      showToast(data?.message ? data?.message : "Something went wrong !");
    }
  };
  const canSubmit =
    isNonEmpty(schoolDetails?.name) &&
    isValidMobile(schoolDetails?.phoneNumber) &&
    isValidEmail(schoolDetails?.email) &&
    isNonEmpty(schoolDetails?.city) &&
    isNonEmpty(schoolDetails?.designation) &&
    isNonEmpty(schoolDetails?.schoolName);

  const bottomBox = () => {
    return (
      <div className={styles["bottom-box"]}>
        {step == 1 && (
          <div className={styles["otp-box"]}>
            <InputBox
              label="Enter OTP"
              value={otp}
              isShow={false}
              setValue={setOtp}
            />
          </div>
        )}
        {step == 0 && <div className={styles["otp-text"]}>{OtpText}</div>}
        {step == 0 && (
          <CustomButton
            label="Send OTP"
            handleClick={handleSendOTP}
            disabled={!canSubmit}
          />
        )}
        {step == 1 && resend()}
        {step == 1 && (
          <CustomButton
            label="Submit"
            handleClick={handleVerifyOTP}
            disabled={(!canSubmit && isNonEmpty(otp)) || isSubmitted}
          />
        )}
      </div>
    );
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const resend = () => {
    return (
      <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          {seconds > 0 && (
            <div>
              <b style={{ color: "#130159", textDecoration: "underline" }}>
                {seconds > 0 && `Resend `}
              </b>
              <span style={{ color: "#130159", marginRight: "4px" }}>
                {seconds > 0 && formatTime(seconds)}
              </span>
            </div>
          )}

          {seconds === 0 && (
            <div style={{ color: "#130159" }} onClick={() => handleSendOTP()}>
              Resend
            </div>
          )}
        </div>
      </div>
    );
  };

  const inputsForm = () => {
    return (
      <div className={styles.inputs}>
        <InputBox
          label="Name"
          value={schoolDetails?.name || ""}
          setValue={(value) => handleChange("name", value)}
        />
        <InputBox
          label="Mobile Number"
          value={schoolDetails?.phoneNumber || ""}
          setValue={(value) => handleChange("phoneNumber", value)}
        />
        <InputBox
          label="Email ID"
          value={schoolDetails?.email || ""}
          setValue={(value) => handleChange("email", value)}
        />
        <InputBox
          label="City"
          value={schoolDetails?.city || ""}
          setValue={(value) => handleChange("city", value)}
        />
        <InputBox
          label="Designation"
          value={schoolDetails?.designation || ""}
          setValue={(value) => handleChange("designation", value)}
        />
        <InputBox
          label="School Name"
          value={schoolDetails?.schoolName || ""}
          setValue={(value) => handleChange("schoolName", value)}
        />
      </div>
    );
  };
  return (
    <div className={styles["equiry-box"]}>
      {step != 2 && (
        <div className={styles["inputs"]}>
          <label className={styles["enquiry-text"]}>Let's get in touch</label>
        </div>
      )}
      {step != 2 && inputsForm()}

      {step == 2 && <SubimittedBox />}
      {bottomBox()}
    </div>
  );
}

const SubimittedBox = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={styles["submitted"]}>
        <img src={Vin} className={styles["submitted-img"]} alt="Vin" />
        <div className={styles["submitted-container"]}>
          <label className={styles["submitted-title"]}>
            Details submitted!
          </label>
          <label className={styles["submitted-text"]}>{submittedText}</label>
        </div>
      </div>
      <CustomButton
        label="Back to home"
        handleClick={() => {
          navigate(routesLinks.home);
        }}
      />
    </React.Fragment>
  );
};

const InputBox = ({ label, value, setValue, isShow = true }) => (
  <div className={styles["input-container"]}>
    <div className={styles["input-label"]}>
      {label}
      {isShow && <span style={{ color: "red" }}>*</span>}
    </div>
    <input
      className={styles["input-box"]}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={label === "Mobile Number" ? "tel" : "text"}
      autocomplete="new-password"
    />
  </div>
);

const CustomButton = ({ label, handleClick, disabled }) => (
  <div
    className={`${styles["custom-btn"]} ${disabled ? styles["disabled"] : ""}`}
    onClick={!disabled ? handleClick : null}
  >
    {label}
  </div>
);
