import { routesLinks } from '../../utils/RouteConstants';

export const aboutUs = {
  blockOne: {
    title: 'The Golden Ratio',
    text1: `At Coschool, every calculation leads to the golden ratio in education…`,
    text2: `1:1 teacher student ratio.`,
    text3: `Leveraging Generative AI and drawing on three decades of experience in education, we created Coschool to empower educators in their mission to ensure no student is left behind. With learning through conversation at the core of our solution, we emphasise truly personalised and adaptive learning aimed at achieving learning without knowledge gaps.`,
  },
  blockTwo: {
    sectionOne: {
      title: `Teacher Empowerment`,
      text: `Customizable classroom resources and quick actionables enabling teachers to provide personalised student support`,
    },
    sectionTwo: {
      title: `Student Centricity`,
      text: `Real-time adaptive learning ensuring every student learns without knowledge gaps`,
    },
    sectionThree: {
      title: `Parent Partnership`,
      text: `Collaborative engagement and actionable insights to bridge home and school learning environments`,
    },
  },
  blockThree: {
    imageOne: {
      name: 'Naga Tummala',
      role: 'Chief Visionary & CEO',
      quote: `“Rethinking education isn't just a possibility—it's our responsibility”`,
    },
    imageTwo: {
      name: 'Raj Yarlagadda',
      role: 'Growth Architect',
      quote: `“Every challenge hides an opportunity for growth”`,
    },
    content: `Naga and Raj are not only educators at heart but also lifelong learners. Their friendship started on the school playground 50 years ago. With a combined passion for education and innovation, they've dedicated their lives to reimagining learning. Alongside their love for food and travel, their deepest joy comes from contributing to societal advancement through education.`,
  },
  blockFour: {
    desc: `Our new chapter has just begun, yet the ripples of impact are already visible. With tools for teachers and students already enhancing the learning experience, our comprehensive solution for schools is on its way. The future looks promising.`,
    cardOne: {
      title: 'Are you a student ?',
      btnTxt: 'Learn from Vin',
      path: '',
    },
    cardTwo: {
      title: 'Are you a teacher ?',
      btnTxt: `Here's your Assistant`,
      path: routesLinks.teacher,
    },
    cardThree: {
      title: 'Do you represent a school ?',
      btnTxt: 'Let’s get in touch',
      path: routesLinks.schoolpartner,
      index: 3,
    },
  },
  titles: {
    coschoolFormula: 'The Coschool Formula',
    leadersLegacy: 'Meet Our Founders ',
    nextChapter: 'The Next Chapter in Education',
    careerOpportunities: 'Want to join the Coschool team?',
    revolText: `Be part of the revolution – stay connected for the big reveal.`,
  },
  buttons: {
    careerPage: 'Apply here',
  },
};
