import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import COLORS from '../utils/COLORS';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BUTTONVARIANTS, PROMPTCONSTANTS } from '../utils/NAMES';
import { PROMPTYPES } from '../utils/CONSTANTS';
import CustomButton from '../components/CustomButton';
import Pagination from '../components/Pagination';
import {
  listButtonSize,
  listContainerStyles,
  paddingEachPromptType,
  paginationAlignment,
  prompsListValueHolder,
  promptTypeButtonStyle,
  promptTypesStyles,
  promptsListContainerStyles,
  promptsTypesPadding,
} from './promptsList_styles';
import { getAllPrompts } from '../apis/callAPi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context';
import { strings } from '../utils/strings';
import '../styles/prompts.css';
import { routesLinks } from '../utils/RouteConstants';

const Home = () => {
  let navigate = useNavigate();
  const { userInfo } = useAuth();

  const [activeButton, setActiveButton] = useState('all');
  const [promptType, setprompType] = useState('');
  const [pageLimit, setPageLimit] = useState(10);
  const [promptList, setPromptList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    if (buttonId == 'all') {
      setprompType('');
    } else {
      setprompType(buttonId);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setPageLimit(10);
    if (userInfo?.name) {
      fetchAllPrompts();
    }
  }, [currentPage, promptType, userInfo]);
  const fetchAllPrompts = async () => {
    let pageNumber = (currentPage - 1) * pageLimit;
    const response = await getAllPrompts(pageLimit, pageNumber, promptType);
    if (response?.success) {
      setPromptList(response?.data?.data);
      const count1 = response?.data?.count;
      let pages = Math.ceil(parseInt(count1) / pageLimit);
      setTotalPages(pages);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const ViewPrompt = ({ id, data }) => {
    return (
      <div
        role="button"
        tabIndex="0"
        style={paddingEachPromptType}
        onClick={() => {
          navigate(routesLinks.promptoftheday, {
            state: { selectedPrompt: data },
          });
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            navigate(routesLinks.promptoftheday, {
              state: { selectedPrompt: data },
            });
          }
        }}
      >
        <div style={prompsListValueHolder} key={id}>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.prompt,
              }}
            />
          </div>
          <div
            style={{
              ...paddingEachPromptType,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex' }}>
              {/* <span style={favouriteButtonStyles}>
                <MdOutlineFavorite size={18} style={listButtonSize} />
              </span> */}
              <CustomButton
                variant={BUTTONVARIANTS?.SECONDARY}
                styleComponent={promptTypeButtonStyle}
                // onClick={() => handleButtonClick('mid')}
              >
                {data?.subCategory}
              </CustomButton>
            </div>
            <div>
              <AiOutlineArrowRight size={15} style={listButtonSize} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const promptTypes = (value, title) => {
    return (
      <CustomButton
        variant={BUTTONVARIANTS?.SECONDARY}
        styleComponent={{
          ...promptTypesStyles,
          color: activeButton === value ? COLORS.BLACK : COLORS.WHITE,
          backgroundColor:
            activeButton === value ? COLORS.PINK : COLORS.MEDIUMGRAY,
        }}
        onClick={() => handleButtonClick(value)}
      >
        {title}
      </CustomButton>
    );
  };
  const promptsData = () => {
    return (
      <Container
        fluid
        style={{
          ...promptsListContainerStyles,
          overflowX: 'auto',
          minWidth: '100%',
        }}
      >
        <Container style={{ backgroundColor: COLORS.GRAYBRAND }}>
          {promptList.map((item, index) => (
            <div key={item?.id} style={{ backgroundColor: COLORS.GRAYBRAND }}>
              <ViewPrompt id={item?.id} data={item} index={index} />
            </div>
          ))}
        </Container>
        <div style={paginationAlignment}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    );
  };
  const displayWarning = () => {
    return (
      <Container
        fluid
        style={{
          ...promptsListContainerStyles,
          overflowX: 'auto',
          minWidth: '100%',
        }}
      >
        <Container style={{ backgroundColor: COLORS.GRAYBRAND }}>
          <p style={{ color: '#919191' }}>{strings.default.prompt_message}</p>
        </Container>
      </Container>
    );
  };
  return (
    <Container>
      <div className="prompt-main">
        <p className="header-text">{PROMPTCONSTANTS.ALLPROMPTS}</p>
      </div>
      <div style={listContainerStyles}>
        <div style={{ backgroundColor: COLORS.GRAYBRAND }}>
          <Container>
            {window.innerWidth >= 768 && false && (
              <div style={promptsTypesPadding}>
                {promptTypes('all', PROMPTYPES.ALL)}
                {promptTypes('favourites', PROMPTYPES.FAVOURITES)}
                {promptTypes('midJourney', PROMPTYPES.JOURNEY)}
                {promptTypes('ChatGPT', PROMPTYPES.CHATGPT)}
              </div>
            )}
            {window.innerWidth < 768 && false && (
              <div>
                <div style={promptsTypesPadding}>
                  {promptTypes('all', PROMPTYPES.ALL)}
                  {promptTypes('favourites', PROMPTYPES.FAVOURITES)}
                  {promptTypes('midJourney', PROMPTYPES.JOURNEY)}
                </div>
                {promptTypes('ChatGPT', PROMPTYPES.CHATGPT)}
              </div>
            )}
          </Container>
        </div>
        {userInfo?.name ? (
          <div>{promptsData()}</div>
        ) : (
          <div>{displayWarning()}</div>
        )}
      </div>
    </Container>
  );
};

export default Home;
