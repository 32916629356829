import COLORS from '../utils/COLORS';
export const promptTypesStyles = {
  backgroundColor: COLORS.BLACK,
  color: COLORS.WHITE,
  cursor: 'pointer',
  borderWidth: '0px',
  borderRadius: '0px',
  padding: '11px 16px',
  fontFamily: "'Inter', sans-serif",
  margin: '10px',
};
export const promptAiStyles = {
  fontWeight: 'bold',
  fontStyle: 'normal',
  color: COLORS.PINK,
  paddingTop: '56px',
  paddingBottom: '8px',
};
export const listContainerStyles = {
  fontFamily: "'Inter', sans-serif",
};
export const allPromptsTextStyle = {
  fontWeight: 'bold',
  fontStyle: 'normal',
  color: COLORS.WHITE,
  paddingBottom: '20px',
};
export const promptsTypesPadding = {
  paddingBottom: '20px',
  gap: '8px',
  marginLeft: '-10px',
  display: 'flex',
};
export const promptsListContainerStyles = {
  backgroundColor: COLORS.GRAYBRAND,
  color: 'white',
  paddingBottom: '20px',
};
export const prompsListValueHolder = {
  borderWidth: '0px',
  borderRadius: '0px',
  padding: '20px',
  transition: 'color 0.3s, background-color 0.3s',
  color: COLORS.WHITE,
  backgroundColor: COLORS.OUTTPUT_HOLDER,
};
export const favouriteButtonStyles = {
  display: 'inline-block',
  width: '24px',
  height: '24px',
  lineHeight: '24px',
  textAlign: 'center',
  color: COLORS.RED,
};
export const promptTypeButtonStyle = {
  height: '26px',
  width: '16 px',
  marginLeft: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
  borderRadius: '0px',
  borderWidth: '0 px',
  padding: '4px 16px',
  backgroundColor: COLORS.GRAYBRAND,
  borderColor: COLORS.GRAYBRAND,
  color: COLORS.WHITE,
};
export const listButtonSize = {
  verticalAlign: 'middle',
  marginRight: '5px',
};
export const paddingEachPromptType = {
  flex: 1,
  // display: 'flex',
  paddingTop: '20px',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const paginationAlignment = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '30px',
};
