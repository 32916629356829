import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import IconTint from 'react-icon-tint';

import '../styles/button.css';

const ISButton = ({
  label,
  imageSrc,
  imagePosition,
  isEnable = true,
  iconColor,
  containerStyle = '',
  handleClick,
  module = '',
}) => {
  let disableStyle = !isEnable ? (module == 'Login' ? '' : 'disable-btn') : '';
  return (
    <Button
      className={`button ${containerStyle} ${disableStyle}`}
      style={{ cursor: isEnable ? 'pointer' : 'not-allowed' }}
      onClick={handleClick}
    >
      {imagePosition === 'left' && (
        <IconTint
          src={imageSrc}
          color={iconColor}
          className="button-image"
          style={{ marginRight: '6px' }}
        />
      )}
      {label}
      {imagePosition === 'right' && (
        <IconTint
          src={imageSrc}
          color={iconColor}
          className="button-image"
          style={{ marginLeft: '6px' }}
        />
      )}
    </Button>
  );
};

export default ISButton;
