import '../styles/customModal.css';
import React, { useEffect } from 'react';
import { images } from '../utils/images';
import warningGif from '../assets/warning_gif.json';
import Lottie from 'react-lottie';
import { useMediaQuery } from 'react-responsive';

const CustomModal = ({ showModal, onHide }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);
  return (
    <>
      {showModal && (
        <div className="popup">
          <div className="popup-content">
            <img
              src={images.common_Images.close}
              alt="close"
              className="popup-close"
              onClickCapture={onHide}
            />
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: warningGif,
              }}
              width={isDesktop ? '30%' : '55%'}
            />
            <p className="popup-message">
              {
                'Classroom Questions are on the way! We are crafting the perfect questions for all your students, check back later.'
              }
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomModal;
