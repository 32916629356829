export const ALLNAMES = {
  COTEACHER: 'CoTeacher',
  HOME: 'Home',
  ABOUT_US: 'About Us',
  AIASSISTANT: 'Ai Assistant',
  EVERYDAYAI: 'Everyday Ai',
  LOGIN: 'Login',
  LOGOUT: 'Log Out',
  AIPOWERED: 'Ai Assistant for Teachers',
  TEACHING: 'for Teachers',
  ASSISTANCE: 'Assistance',
  EMPOWRING: 'Make your class funny, thought provoking, gamified or classical',
  PROMPTOFTHEDAY: 'Prompt of the Day',
  LESSONPLANNING: 'Lesson Planning',
  LEARNFROMEXPERTS: 'Ai Expert',
  LEARNVIDEOS: 'Classroom activities using GPT',
  LEARNIMAGES: 'Generating Interesting Images',
  learn_category: 'Co-School',
};
export const BUTTONVARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const PROMPTCONSTANTS = {
  PROMPT: 'Prompt',
  SEEALLPROMPTS: 'Prompt Library',
  PROMPTPURPOSE: 'Prompt purposepurpose',
  PURPOSEVALUE:
    'Generate a set of funny activities about resources chapter for VII grades.',
  OUTPUT: 'Output',
  ALLPROMPTS: 'Prompt Library',
};
export const CURIOSITYCONSTANTS = {
  IAMTEACING: 'I am teaching',
  CLASSINTENTION: 'Today I wish my class should be:',
  DOMYTASK: 'Do My Task',
};
export const SHARE_PDF_ONAPP = 'Share to WhatsApp as PDF';
export const SYLLABUS = {
  CURRICULUM: 'Curriculum',
  GRADE: 'Grade',
  SUBJECT: 'Subject',
  TOPIC: 'Select Topic',
};
export const SHARE = 'share';
export const LOGIN = 'Log In';
export const NEXT = 'Next';
