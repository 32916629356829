export const strings = {
  seo_mapping: {
    home: {
      title: 'Coschool - Empower Teaching with AI',
      description:
        'Empower teachers with our AI tool for CBSE based lesson planning, learning activities, curiosity learning, fun classroom experiences, and effective learning assessments. ',
      keywords: [
        'ai for educators',
        'teaching artificial intelligence',
        'ai for teachers',
        'chatgpt for teachers',
        'ai tools for teachers',
        'chatgpt in education',
        'tools teachers use in the classroom',
        'chatgpt for teaching',
        'ai educators',
        'tools teachers use',
        'teacher ai',
      ],
    },
    lesson_plan: {
      title:
        'Lesson plan - Generator intuitive lesson plans efficiently/effortlessly',
      description:
        'Generate personalized CBSE Curriculum lesson plans and elevate your classroom efficiency to save time, improve outcomes, & meet individual student needs.',
      keywords: [
        'lesson plan',
        'ncert lesson plan for teachers',
        'lesson plan format',
        'sample lesson plan format',
        'lesson plans for teachers',
        'lesson plans for teachers pdf',
        'lesson plan example',
        'sample of lesson plan',
        'lesson plan pdf',
        'Lesson plan generator',
        'teaching plan',
        'lesson plan format in english',
        'Lesson plan cbse',
        'Lesson plan for history 6 ncert',
      ],
    },
    curiosity_triggers: {
      title: 'Curiosity triggers - Coschool: Curiosity-Driven Learning',
      description:
        'Use gamified approaches to improve student focus and boost academic outcomes through engaging Ai strategies for CBSE students.',
      keywords: [
        'curiosity learning',
        'questions for students',
        'education questions for students',
        'student centered teaching',
        'activity based learning',
        'activity based learning examples',
        'project based learning ideas',
        'game based learning',
        'gamified learning',
      ],
    },
    enagagement_tips: {
      title: 'Engagement tips - Master Effective Classroom Management',
      description:
        'Learn how to engage cbse students with AI-powered strategies that center students, improve learning outcomes, and reduce management problems in the classroom. ',
      keywords: [
        'classroom activities',
        'fun activities for students in the classroom',
        'educational activities for students',
        'classroom activities for students',
        'student engagement',
        'engagement strategies',
        'creative ways to engage students',
        'classroom management techniques',
        'effective classroom management strategies for new teachers',
        'student engagement activities',
        'fun activities for students',
        'fun class activities',
        'gamification in education',
        'classroom games',
        'classroom management',
        'classroom management strategies',
      ],
    },
    learning_activities: {
      title:
        'Learning activities - Interactive Learning Activities: Ai-Powered Personalization',
      description:
        'Generate personalized CBSE based learning activities to gamify your classroom and adapt to the needs of individual  students to improve learning outcomes',
      keywords: [
        'learning outcomes',
        'student centered teaching',
        'project based learning',
        'personalized learning',
        'learning activities in lesson plan',
        'sample of learning outcomes',
        'learning objectives in lesson plan',
        'learning plan',
        'learning outcomes in lesson plan',
        'activity based learning examples',
        'problem based learning',
        'student centered learning',
        'learning outcomes examples',
        'learning outcomes and learning objectives',
        'learning outcomes of a lesson plan',
      ],
    },
    classroom_questions: {
      title: `Classroom Questions - Assessment for Learning - Personalized Assessment Resources`,
      description:
        'Create CBSE Curriculum-aligned assessments with practice questions that meet the needs of all students. Best Teaching Resources that can save a lot of time.',
      keywords: [
        'assessment for learning',
        'assignments for students',
        'learning assessment',
        'Teacher resource for assessments',
        'assessment tools for teachers',
        'question bank',
        'personalised practice questions',
        'Curriculum-based question bank',
      ],
    },
    daily_prompt: {
      title: 'Prompt of the Day - ai tool for teacher ',
      description:
        'Boost creativity and critical-thinking in students with Prompt of the Day! Bringing Ai magic to your classroom to inspire and engage.',
      keywords: [
        'chatgpt for teacher',
        'chatgpt for teachers',
        'chatgpt education',
        'chatgpt in education',
        'chatgpt for teaching',
        'chatgpt teacher',
        'gpt in education',
        'teaching chatgpt',
        'Teacher aid',
        'Tools for teacher',
      ],
    },
    prompt_library: {
      title: 'Prompts library - Comprehensive Ai Teaching Resources',
      description:
        'Better classroom interactions, seamless engagement, and dynamic insights with Coschool’s Ai solutions for teachers.',
      keywords: [
        'chatgpt for teacher',
        'chatgpt for teachers',
        'chatgpt education',
        'chatgpt in education',
        'chatgpt for teaching',
        'chatgpt teacher',
        'gpt in education',
        'teaching chatgpt',
        'Creative writing prompts',
        'Teaching tool',
        'Teacher website',
        'Teacher aid',
      ],
    },
    ai_expert: {
      title:
        ' Ai expert - Explore The Potential of AI in Teaching: The Ai Expert',
      description:
        'Empower yourself with expertise in AI-powered pedagogy to transform your classroom and get future-ready with the ultimate guide on how to harness the power of Ai.',
      keywords: [
        'ai in teaching',
        'websites for teachers',
        'educational websites for teachers',
        'teaching resources for teachers',
        'digital education tools',
        'tools teachers use in the classroom',
        'educational sites for teachers',
        'digital tools for teaching and learning',
        'youtube for teachers',
        'encouraging videos for teachers',
        'tools used by teacher',
        'teaching tools for teachers',
        'educational tools',
        'free online training for teachers',
        'online teaching tools for teachers',
      ],
    },
  },
  default: {
    co_teacher: 'Co-Teacher',
    generate: 'Generate',
    unleash_message: 'Login to unleash full potential.',
    max_attempts: 3,
    prompt_message: 'Please login to view all prompts.',
    unlimited_message: 'Please login for unrestricted usage.',
    user_attemps:
      'You’ve used up 3/3 unregistered generations. Login to continue.',
    message_sent:
      'Message sent. It may take up to 5 min before you receive it. ',
    whatsapp_sent_status: 'It may take up to 5 min before you receive it.',
  },
  titles: {
    curiosity_triggers: 'Curiosity Triggers',
    learning_activities: 'Learning Activities',
    lesson_plan: 'Lesson Plan',
    engagement_tips: 'Engagement Tips',
    question_paper: 'Classroom Questions',
    prompt: 'Prompt of the Day',
  },
  login: {
    register_or_login: 'Register or Login',
    login: 'Login',
    mobile_number: 'WhatsApp Number',
    send_sms: 'Send Code',
    sms_code: 'Enter Code',
    next: 'Next',
    title: 'Welcome to Coschool',
    edit_name: 'Edit Name',
    description: '',
    your_name: 'Enter Your Name',
    register: 'Register',
    aggrement_title: 'I agree to',
    terms: 'Terms of Services',
    privacy: 'Privacy Policy',
    otp_sent: 'OTP sent successfully !!',
  },
  curiosity: {
    do_my_task: 'Do my task',
    select_variation: 'I want my class to be:',
    streaming_title: 'This is a text about outcome below:',
  },
  prompt: {},
  about: {
    title: 'About Us',
    setction_one_title: 'Ultra-Personalisation',
    section_one_content_part1: `Artificial Intelligence is the new electricity," says Jack Ma. Sundar Pichai compares it to the discovery of fire. It's not just them; many experts believe that Ai will change everything in our lives. Its impact will be so profound that even visionaries like ChatGPT's founder, Sam Altman, can't fully grasp how it will develop.`,
    section_one_content_part2: `While the world outside embraces the potential of Ai, how much have our classrooms truly evolved? And teachers, you are the true heroes of any classroom — how significantly have your tools and resources evolved and empowered you to help you make the most of this new era?`,
    setction_two_title: 'Introducing Coschool',
    section_two_content: `We're here to bridge that gap by bringing the Ai magic right into your classroom. Coschool is the world’s smartest Ai assistant for teachers, one that never gets tired and is ready to assist you anytime, any day. With Coschool, you can make lesson plans that suit your teaching style, create inclusive learning activities, and spark curiosity that makes your class a fun place to learn. Our Ai Assistant helps you make your class thought-provoking, gamified, humorous, and more - all to match your style and what your students love. `,
    section_two_content1:
      'With Coschool, you can become super heroes in no time!',
    setction_three_title: 'Meet the Visionaries',
    section_three_content: `Mr. Naga Tummala and Mr. Raj Yarlagadda—two names that have been synonymous with excellence and innovation in education for over three decades. Their combined expertise and passion gave birth to Coschool where learning is not just effective, but also joyful. And word on the street is, they're the life of any party!`,
    setction_four_title: 'There’s More Coming',
    section_four_content: `Our Ai Assistant for Teachers is just the tip of the iceberg! Stay with us as we're gearing up to introduce more revolutionary Ai solutions for students, parents, and schools. With Coschool, the future of education is set to shine even brighter!`,
    our_team: 'Our Team',
    team_description: 'Team Believes...',
  },
  ai_expert: {
    video: 'Video',
    article: 'Articles',
    podcast: 'Podcast',
  },
};
