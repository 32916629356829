/* eslint-disable */

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/UnifiedHome/styles/navbar.module.css";
import TeacherLogo from "../../assets/common-home/teacher-logo.svg";
import Logo from "../../assets/common-home/logo.svg";
import { routesLinks } from "../../utils/RouteConstants";

export default function NavBar({ isTeacherHovered }) {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };
  const navLink = (item, path) => {
    let isActive = path == window.location.pathname;
    return (
      <Nav.Link
        className={`mx-2 ${styles["nav-link"]} ${
          isActive ? styles["active"] : ""
        }`}
        onClick={() => {
          if (path) {
            handleNavigate(path);
          } else {
            window.open(
              "https://coschool.medium.com/",
              "_blank",
              "noopener,noreferrer",
            );
          }
        }}
        style={isTeacherHovered ? { color: "#FFFFFF" } : {}}
      >
        {item}
      </Nav.Link>
    );
  };
  return (
    <Navbar expand="lg">
      <Navbar.Brand
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(routesLinks.home);
        }}
      >
        <img
          src={isTeacherHovered ? TeacherLogo : Logo}
          alt="Coschool"
          className={styles.logo}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {navLink("About Us", "/about-us")}
          {navLink("Blog")}
          {navLink("Careers", "/careers")}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
