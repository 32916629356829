/* eslint-disable */
import React from "react";

export default function VinDesc({ styles = {} }) {
  return (
    <div className={styles.vin}>
      <div className={styles["vin-text"]}>Hi, I’m Vin!</div>
      <div className={styles["vin-desc"]}>
        I am here to make teaching impactful and learning more personalised.
      </div>
    </div>
  );
}
