/* eslint-disable */
import React from "react";
import styles from "./styles/terms-and-conditions.module.css";
import MailIcon from "../../../assets/common-home/mail.svg";
import MobileIcon from "../../../assets/common-home/mobile.svg";
import LocationIcon from "../../../assets/common-home/location.svg";

export default function ContactUsCard() {
  return (
    <div className={styles["contact-card"]}>
      <div className={styles["card-title"]}>Have more questions? Contact:</div>
      <div>
        <div className={styles["sub-title"]}>Grievance Officer</div>
        <div className={styles["info-text"]}>Vishwa Paresh Talati</div>
      </div>
      <IconWithText
        icon={LocationIcon}
        text={
          <ol style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li className={styles["address-text"]}>
              Plot No.99, Road No.72, Jubilee Hills
            </li>
            <li className={styles["address-text"]}>
              Co-operative Society, Jubilee Hills,
            </li>
            <li className={styles["address-text"]}>
              Shaikpet, 500033, Hyderabad,
            </li>
            <li className={styles["address-text"]}> Telangana, India,</li>
          </ol>
        }
      />
      <IconWithText
        icon={MobileIcon}
        text={<div className={styles["address-text"]}>+91-7075780899</div>}
      />
      <IconWithText
        icon={MailIcon}
        text={
          <div className={styles["address-text"]} style={{ color: "#1C6B98" }}>
            info@coschool.ai
          </div>
        }
      />
      <div className={styles["info-text"]}>
        {`In case your grievance was not handled in compliance of the applicable laws by the Company, you have the right to file appropriate complaints (as per the prescribed rules) with the Data Protection Board of India under the provisions of the Digital Personal Data Protection Act, 2023.`}
      </div>
    </div>
  );
}

const IconWithText = ({ icon, text }) => {
  return (
    <div className={styles["icon-with-text"]}>
      <img src={icon} alt="icon" />
      <div style={{ marginTop: "4px" }}>{text}</div>
    </div>
  );
};
