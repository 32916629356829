// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anek Latin&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  overflow: hidden; /* Add this to prevent scrolling when the popup is open */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background-color: #262626b2;
  overflow-y: auto;
}
.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #353535;
  width: 50%;
  align-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.popup-close {
  top: 10px;
  right: 10px;
  align-self: flex-end;
  padding: 15px;
  padding-bottom: 0px;
  cursor: pointer;
}
.popup-message {
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  margin: 5%;
  margin-top: 0%;
}
.popup-image {
  max-width: 30%;
}
@media (max-width: 768px) {
  .popup-content {
    width: 90%;
    background-color: #353535;
  }
  .popup-message {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/customModal.css"],"names":[],"mappings":"AACA;EACE,gBAAgB,EAAE,yDAAyD;AAC7E;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,UAAU;EACV,qBAAqB;EACrB,mBAAmB;EACnB,0CAA0C;EAC1C,sBAAsB;AACxB;AACA;EACE,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,UAAU;EACV,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Anek%20Latin&display=swap\");\n.body {\n  overflow: hidden; /* Add this to prevent scrolling when the popup is open */\n}\n\n.popup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1001;\n  background-color: #262626b2;\n  overflow-y: auto;\n}\n.popup-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #353535;\n  width: 50%;\n  align-content: center;\n  align-items: center;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);\n  box-sizing: border-box;\n}\n.popup-close {\n  top: 10px;\n  right: 10px;\n  align-self: flex-end;\n  padding: 15px;\n  padding-bottom: 0px;\n  cursor: pointer;\n}\n.popup-message {\n  color: #ffffff;\n  font-weight: 400;\n  font-size: 18px;\n  margin: 5%;\n  margin-top: 0%;\n}\n.popup-image {\n  max-width: 30%;\n}\n@media (max-width: 768px) {\n  .popup-content {\n    width: 90%;\n    background-color: #353535;\n  }\n  .popup-message {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
