const COLORS = {
  WHITE: 'white',
  BLACK: 'black',
  GRAY: '#F3F3F3',
  LIGHTGRAY: '#BABABA',
  WHITESMOKE: '#F1F1F1',
  GREEN: '#7ABC40',
  MEDIUMGRAY: '#353535',
  GRAYBRAND: '#262626',
  GRAYLIGHTEST: '#919191',
  OUTTPUT_HOLDER: '#373737',
  BUTTON_BG_GRAY: '#454545',
  POWDERY: '#FFBFB7',
  PINK: '#FFBFB7',
  PROMPT_GRAY: '#515151',
  RED: '#FF4104',
};
export default COLORS;
