import React, { useEffect, useState } from 'react';
import { getCurriculum, getGrades, getSubjects } from '../apis/callAPi';
import ISButton from './ISButton';
import ISDropdown from './ISDropdown';
import '../styles/curiosity.css';
import Login from './Login';
import { useAuth } from '../context';
import { savePreferences } from '../apis/pereferences';
import { useCookies } from 'react-cookie';
import { strings } from '../utils/strings';
import { ENABLED_SUBJECTS } from '../utils/common-utils';
import { useMediaQuery } from 'react-responsive';
const normalizeString = (str) => str?.trim()?.toLowerCase().replace(/\s+/g, '');
const UserPreference = ({
  syllabus,
  handleSyllubus,
  screenName,
  isStreaming,
  setSyllabusData,
  syllabusData,
  browserParams,
}) => {
  const isEngagementTip = screenName == strings.titles.engagement_tips;
  const isDesktop = useMediaQuery({ minWidth: 992 });

  let { userInfo } = useAuth();
  const [curriculumData, setCurriculumData] = useState([]);
  const [curriculum, setCurriculum] = useState('');
  const [selectedCurriculum, setSelectedCurriculum] = useState({});
  const [gradesData, setGradesData] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});

  const [grade, setGrade] = useState('');
  const [subjectsData, setSubjectsData] = useState([]);
  const [subject, setSubject] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    setCurriculum('');
    fetchCurriculum();
  }, []);

  useEffect(async () => {
    if (Object.keys(selectedSubject)?.length > 0) {
      let data = {
        curriculum: selectedCurriculum,
        grade: selectedGrade,
        subject: selectedSubject,
      };
      if (userInfo?.name) {
        await handleSavePrefences(data);
      }
      handleSyllubus(data);
      setSyllabusData(data);
    }
  }, [selectedSubject]);

  const fetchCurriculum = async () => {
    let response = await getCurriculum();
    if (response?.success) {
      setCurriculumData(response?.data?.data);
      handleCurriculum(response?.data?.data[0]);
    }
  };
  const handleCurriculum = (selectedValue) => {
    setGradesData([]);
    setGrade('');
    setSelectedGrade({});
    setSelectedCurriculum(selectedValue);
    setCurriculum(selectedValue?.title || selectedValue?.name);
    setSyllabusData({ curriculum: selectedValue });
    fetchGrades(selectedValue?.id);
  };
  const fetchGrades = async (curriculumId) => {
    let response = await getGrades(curriculumId);
    if (response?.success) {
      setGradesData(response?.data?.data);
      if (cookies?.preferences?.grade) {
        handleGrade(cookies?.preferences?.grade);
      }
      if (browserParams?.grade) {
        let data = response?.data?.data?.find((item) =>
          item?.title?.includes(browserParams?.grade),
        );
        data = { name: data?.name, id: data?.id };
        handleGrade(data);
      }
    }
  };
  const handleGrade = (selectedValue, action) => {
    setSubjectsData([]);
    setSubject('');
    setSelectedGrade(selectedValue);
    setGrade(selectedValue?.title || selectedValue?.name);
    !isEngagementTip && fetchSubjects(selectedValue, action);

    setSyllabusData({ ...syllabusData, grade: selectedValue });
    handleSyllubus({
      curriculum: selectedCurriculum,
      grade: selectedValue,
    });
  };
  const fetchSubjects = async (grade, action) => {
    let response = await getSubjects(grade?.id);
    if (response?.success) {
      let subData = response?.data?.data;
      let enabledSubjects = ENABLED_SUBJECTS[grade?.name];
      subData = subData?.filter((item) => {
        let isExists =
          enabledSubjects?.filter((subItem) =>
            normalizeString(subItem)?.includes(normalizeString(item?.name)),
          )?.length > 0;
        return isExists;
      });
      setSubjectsData(subData);

      if (cookies?.preferences?.subject && action == undefined) {
        handleSubject(cookies?.preferences?.subject);
      }

      if (browserParams?.subject) {
        let data = subData?.find((item) =>
          normalizeString(item?.title)?.includes(
            normalizeString(browserParams?.subject),
          ),
        );
        data = { name: data?.name, id: data?.id };
        handleSubject(data);
      }
    }
  };
  const handleSubject = (selectedValue) => {
    setSubject(selectedValue?.title || selectedValue?.name);
    setSelectedSubject(selectedValue);
    setSyllabusData({ ...syllabusData, subject: selectedValue });
  };
  const handleSave = async (data) => {
    if (userInfo?.name) {
      await handleSavePrefences(data);
    }
  };
  const handleSavePrefences = async (syllabus) => {
    let { curriculum, grade, subject } = syllabus;
    if (
      curriculum?.id != undefined &&
      grade?.id != undefined &&
      subject?.id != undefined
    ) {
      let body = {
        curriculum: {
          id: curriculum?.id,
          name: curriculum?.name,
        },
        grade: {
          id: grade?.id,
          name: grade?.name,
        },
        subject: {
          id: subject?.id,
          name: subject?.name,
        },
      };
      let { success } = await savePreferences(body);
      if (success) {
        setCookie('preferences', body, { path: '/' });
      }
    }
  };
  const isButtonEnable = () => {
    let { curriculum, grade, subject } = syllabus;
    return (
      curriculum?.id != undefined &&
      grade?.id != undefined &&
      subject?.id != undefined
    );
  };
  const isButtonEnabled = isButtonEnable();
  return (
    <div className={isDesktop ? 'dropdowns' : 'dropdowns-mobile'}>
      <ISDropdown
        placeholder={'Curriculum'}
        selectedValue={curriculum}
        handleSelect={(data) => {
          !isStreaming && handleCurriculum(data);
        }}
        data={curriculumData}
      />
      <ISDropdown
        placeholder={'Grade'}
        selectedValue={grade}
        handleSelect={(data) => {
          !isStreaming && handleGrade(data, 'selected');
        }}
        data={gradesData}
        parentData={selectedCurriculum}
      />
      {!isEngagementTip && (
        <ISDropdown
          placeholder={'Subject'}
          selectedValue={subject}
          handleSelect={(data) => {
            !isStreaming && handleSubject(data);
          }}
          data={subjectsData}
          parentData={{ curriculum: selectedCurriculum, grade: selectedGrade }}
        />
      )}
      {!isEngagementTip && false && (
        <ISButton
          label="Save preferences"
          handleClick={() => {
            isButtonEnabled && handleSave();
          }}
          containerStyle={'save-preference-btn'}
          isEnable={isButtonEnabled}
        />
      )}

      <Login
        show={showLogin}
        onHide={() => {
          setShowLogin(false);
        }}
        handleShare={() => {
          handleSavePrefences();
        }}
      />
    </div>
  );
};

export default UserPreference;
