/* eslint-disable */

import React, { useEffect, useState } from "react";
import styles from "../../pages/UnifiedHome/styles/jobs.module.css";
import { Form, InputGroup } from "react-bootstrap";
import LocationImage from "../../assets/jobs/location.svg";
import LocationSearchImage from "../../assets/jobs/arrow-right.svg";
import keyWordSearchImage from "../../assets/jobs/search.svg";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "./helpers";
import { getJobsList } from "../../apis/callAPi";
import { STRINGS } from "../../utils/CONSTANTS";
export default function JobPostings() {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const [jobsListData, setjobsListData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [keywordSearch, setKeywordSearch] = useState("");
  const [departmentWiseData, setDepartmentWiseData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [hireTypeData, setHireTypeData] = useState([]);
  useEffect(() => {
    togenerateToken();
  }, []);
  const togenerateToken = async () => {
    let listData = await getJobsList();
    if (listData?.success) {
      listData = listData?.data;
      listData = listData?.results?.filter((item) =>
        item?.job_status
          ?.toLowerCase()
          ?.includes(STRINGS?.active?.toLowerCase()),
      );
      const departmentJobCount = listData?.reduce((acc, job) => {
        const { department } = job;
        if (department) {
          acc[department] = (acc[department] || 0) + 1;
        }
        return acc;
      }, {});
      const jobTypeCount = listData?.reduce((acc, job) => {
        const { job_type } = job;
        if (job_type) {
          acc[job_type] = (acc[job_type] || 0) + 1;
        }
        return acc;
      }, {});
      const hireTypeCounts = listData.reduce(
        (acc, job) => {
          if (job.remote_job === "No") {
            acc.Onsite += 1;
          } else {
            acc.Remote += 1;
          }
          return acc;
        },
        { Remote: 0, Onsite: 0 },
      );
      setDepartmentWiseData(departmentJobCount);
      setJobTypeData(jobTypeCount);
      setHireTypeData(hireTypeCounts);
      setSearchedData(listData);
      setjobsListData(listData);
    }
  };
  const handleSearch = (toFind, value) => {
    const lowerCaseValue = value?.toLowerCase();
    let filteredData = [...jobsListData];

    if (toFind) {
      switch (toFind.toLowerCase()) {
        case "location":
          filteredData = filteredData.filter(
            (job) =>
              job?.city?.toLowerCase()?.includes(lowerCaseValue) ||
              job?.country?.toLowerCase()?.includes(lowerCaseValue),
          );
          break;

        case "keyword":
          filteredData = filteredData.filter((job) =>
            job?.job_title?.toLowerCase()?.includes(lowerCaseValue),
          );
          break;

        case "department":
          filteredData = filteredData.filter((job) =>
            job?.department?.toLowerCase()?.includes(lowerCaseValue),
          );
          break;

        case "jobtype":
          filteredData = filteredData.filter((job) =>
            job?.job_type?.toLowerCase()?.includes(lowerCaseValue),
          );
          break;

        case "hiretype":
          if (lowerCaseValue.includes("onsite")) {
            filteredData = filteredData.filter(
              (job) => job?.remote_job?.toLowerCase() === "no",
            );
          } else if (lowerCaseValue.includes("remote")) {
            filteredData = filteredData.filter(
              (job) => job?.remote_job?.toLowerCase() === "yes",
            );
          }
          break;

        default:
          break;
      }
    }

    setSearchedData(filteredData);
  };

  const searchJobs = (placeholder, data, setData) => {
    let displayImage = placeholder?.toLowerCase()?.includes("location")
      ? LocationSearchImage
      : keyWordSearchImage;
    return (
      <InputGroup className={styles["search-box"]}>
        <Form.Control
          type="text"
          placeholder={placeholder || "Search with keyword"}
          value={data}
          className={styles["seach-area"]}
          onChange={(e) => {
            setData(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(placeholder, data);
            }
          }}
        />
        <img
          src={displayImage}
          alt="location"
          className={styles["location-img"]}
          onClick={() => {
            handleSearch(placeholder, data);
          }}
        />
      </InputGroup>
    );
  };
  const handleClick = (data) => {
    window.open(data?.apply_job, "_blank", "noopener,noreferrer");
  };
  const jobDetails = (data) => {
    return (
      <div
        className={styles["job-data-container"]}
        onClick={() => {
          handleClick(data);
        }}
        style={{ cursor: "pointer" }}
      >
        <p className={styles["job-name"]}>{data?.job_title}</p>
        <div style={{ display: "flex", gap: "8px" }}>
          <img
            src={LocationImage}
            alt="location"
            className={styles["location-img"]}
          />
          <p className={styles["job-details"]}>
            {" "}
            {`${data?.city || "Hyderabad"} , ${data?.country} `}
          </p>
        </div>
        <div className={styles["job-data"]}>
          <p
            className={styles["job-details"]}
          >{`Recruitment ID : ${data?.job_code} | ${data?.department}`}</p>
          <p
            className={styles["view-more"]}
            style={isMobile ? { opacity: 0 } : { opacity: 1 }}
          >
            {"View more"}
          </p>
        </div>
      </div>
    );
  };
  const display_aggrgateData = (title, data, searchParam) => [
    <>
      <div
        className={styles["department-title"]}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSearchedData(jobsListData);
        }}
      >
        {title}
      </div>
      {Object.entries(data).map(([record, count]) => (
        <>
          {count > 0 && (
            <p
              key={record}
              className={styles["job-details"]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setKeywordSearch("");
                setLocationSearch("");
                handleSearch(searchParam, record);
              }}
            >
              {`${record}(${count})`}
            </p>
          )}
        </>
      ))}
    </>,
  ];
  return (
    <div className={styles.container}>
      <div className={styles["roles-listing"]}>
        {searchJobs("Location", locationSearch, setLocationSearch)}
        {isMobile && searchJobs("Search", keywordSearch, setKeywordSearch)}
        {!isMobile &&
          display_aggrgateData(
            "All Departments",
            departmentWiseData,
            "Department",
          )}
        {!isMobile && display_aggrgateData("Job Types", jobTypeData, "jobType")}
        {!isMobile &&
          display_aggrgateData("Hire Types", hireTypeData, "hireType")}
      </div>
      <div className={styles["jobs-listing"]}>
        {!isMobile && (
          <>
            {searchJobs("Search with keyword", keywordSearch, setKeywordSearch)}
            <div className={styles["job-title"]}>Job openings</div>
          </>
        )}
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          {searchedData?.map((item) => {
            return jobDetails(item);
          })}
        </div>
      </div>
    </div>
  );
}
