import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
// import ISButton from '../components/ISButton';
import Forward from '../assets/nextIcon.png';
import '../styles/home-mobile.css';
import MobileCard from '../components/MobileCard';
import Carousel from 'react-bootstrap/Carousel';
import { STRINGS } from '../utils/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import IconTint from 'react-icon-tint';
import CustomModal from '../components/CustomModal';

let intervalId;

const HomeMobileView = ({ categories }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [categories.length]);

  const CarouselItem = (item, index) => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}
      >
        <div>
          <div className="display-text">{item?.displaytext}</div>
          <div className="content-text">{item?.description}</div>
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: item?.animation,
          }}
          isStopped={currentIndex !== index}
          isPaused={currentIndex !== index}
          height={200}
          width={200}
        />
        <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
          <div className="mobile-bottom-container">
            <span className={`mobile-bottom-text`} style={{ color: 'white' }}>
              {item?.action}
            </span>
            <IconTint src={Forward} color={'#7abc40'} alt="Image" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="mobile">
      <div
        className="main-card"
        onClick={() => {
          if (
            categories[currentIndex]?.displaytext
              ?.toLowerCase()
              .includes(STRINGS?.home?.question_bank?.name)
          ) {
            setShowWarningModal(true);
          } else {
            navigate(`${categories[currentIndex]?.navigation}`);
          }
        }}
      >
        <Carousel
          activeIndex={currentIndex}
          pause={false}
          controls={false} // Disable default controls
          indicators={false} // Optional: Disable indicators as well
        >
          {categories?.map((item, index) => {
            return (
              <Carousel.Item key={index + 'pos'}>
                {CarouselItem(item, index)}
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div style={{ position: 'absolute', bottom: '16px', left: '20px' }}>
          <div className="dot-container">
            {categories.map((item, index) => (
              <div
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentIndex(index);
                  clearInterval(intervalId); // Clear the interval
                  intervalId = setInterval(() => {
                    setCurrentIndex(index);
                  }, 3000); // Start a new interval
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <MobileCard
        item={STRINGS?.home?.everyday_ai}
        card={'everydayAi'}
        type={'ai assistant'}
        index={0}
      />
      <div>
        {categories?.map((item, index) => {
          return (
            <div key={'pos' + index}>
              <MobileCard
                item={item}
                card={'modules'}
                type={'ai assistant'}
                index={index + 1}
                setShowModal={setShowWarningModal}
              />
            </div>
          );
        })}
      </div>
      {showWarningModal && (
        <CustomModal
          showModal={showWarningModal}
          onHide={() => {
            setShowWarningModal(false);
          }}
        />
      )}
    </div>
  );
};

export default HomeMobileView;
