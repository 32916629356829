/* eslint-disable */

import React from "react";
import {
  COPYRIGHT_TEXT,
  PRIVACY_POLICY_TEXT,
  TERMS_CONDITIONS_TEXT,
} from "./helpers";
import { useNavigate } from "react-router-dom";
import { routesLinks } from "../../utils/RouteConstants";

export default function Footer({ styles = {} }) {
  const navigate = useNavigate();
  return (
    <div className={styles.bottom}>
      <div className={styles["bottom-text"]}>{COPYRIGHT_TEXT}</div>
      <div className={styles["links"]}>
        <div
          onClick={() => {
            navigate(routesLinks.privacypolicy);
          }}
          className={`${styles["bottom-text"]} ${styles.link}`}
        >
          {PRIVACY_POLICY_TEXT}
        </div>
        <div
          className={`${styles["bottom-text"]} ${styles.link}`}
          onClick={() => {
            navigate(routesLinks.termsandconditions);
          }}
        >
          {TERMS_CONDITIONS_TEXT}
        </div>
      </div>
    </div>
  );
}
