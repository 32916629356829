/* eslint-disable */
import React from "react";
import styles from "../../pages/UnifiedHome/styles/bottom-nav.module.css";
import {
  bottomNavCompanyInfo,
  bottomNavIcons,
  bottomNavSupportInfo,
  locations,
} from "../../utils/common-utils";
import { useNavigate } from "react-router-dom";
import LinkedImage from "../../assets/bottom-nav/linkedstartup.svg";

export default function BottomNav() {
  const navigate = useNavigate();
  const handleAction = (externalPath, path) => {
    if (externalPath == undefined) {
      navigate(path);
    } else {
      window.open(externalPath, "_blank", "noopener,noreferrer");
    }
  };
  const renderCompanyDetails = () => {
    return (
      <div className={styles["sub-grid"]}>
        <div className={styles["grid-item"]}>
          <BoldText label="Company" />
          {bottomNavCompanyInfo?.map((item, index) => {
            return (
              <NormalText
                key={index}
                label={item?.name}
                onClick={() => {
                  handleAction(item?.externalPath, item?.path);
                }}
              />
            );
          })}
        </div>
        <div className={styles["grid-item"]}>
          <BoldText label="Support" />
          {bottomNavSupportInfo?.map((item, index) => {
            return (
              <NormalText
                key={index}
                label={item?.name}
                onClick={() => {
                  handleAction(item?.externalPath, item?.path);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };
  const renderOfficeDetails = () => {
    return (
      <div className={styles["grid-item"]}>
        <BoldText label={"Our offices"} />
        <div
          className={styles["column"] + " " + styles["office"]}
          onClick={() => {
            handleAction(locations?.main_office, undefined);
          }}
        >
          <BoldText label={"Main Office"} />
          <NormalText label={"Wework Office, 1st Floor, Rajapushpa Summit"} />
          <NormalText label={"Nanakramguda Rd, Financial District"} />
          <NormalText label={"Manikonda, Telangana - 500032"} />
        </div>
        <div
          className={styles["column"] + " " + styles["office"]}
          onClick={() => {
            handleAction(locations?.jubilee_office, undefined);
          }}
        >
          <BoldText label={"Jubilee Hills Office"} />
          <NormalText label={"Plot No. 471, 2nd Floor"} />
          <NormalText label={"Road Number 36, Jubilee Hills"} />
          <NormalText label={"Hyderabad, Telangana - 500033"} />
        </div>
      </div>
    );
  };

  const BoldText = ({ label }) => {
    return <div className={styles["text-bold"]}>{label}</div>;
  };
  const NormalText = ({ label, onClick }) => {
    return (
      <div
        onClick={() => {
          onClick && onClick();
        }}
        className={styles["text-normal"]}
      >
        {label}
      </div>
    );
  };
  const renderBottomDetails = () => {
    return (
      <div className={styles["bottom-container"]}>
        <NormalText
          label={
            "Copyright 2024, People Combine Basics Pvt Ltd, All Rights Reserved"
          }
        />
        <div className={styles["bottom-icons"]}>
          {bottomNavIcons?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  handleAction(item?.action);
                }}
              >
                <img src={item?.icon} className={styles["icon"]} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className={styles["container"]}>
      <div className={styles["grid"]}>
        {renderCompanyDetails()}
        {renderOfficeDetails()}
        <div className={styles["grid-item"]}>
          <img src={LinkedImage} className={styles["linkedin-startup-img"]} />
        </div>
      </div>
      {renderBottomDetails()}
    </div>
  );
}
