/* eslint-disable */

import React, { useState, useEffect } from "react";
import { images } from "../utils/images";
import { strings } from "../utils/strings";
import {
  getExpirationDate,
  mobileNumberValidation,
} from "../utils/common-utils";
import { sendOTP, updateUserName, verifyOTP } from "../apis/auth";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import ISButton from "../components/ISButton";
import { Dropdown } from "react-bootstrap";

import "../styles/login.css"; // Make sure this path is correct
import { useAuth } from "../context";
import { getPreferences } from "../apis/pereferences";
import { useCookies } from "react-cookie";
import { COUNTRY_CODES } from "../utils/CONSTANTS";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red; /* You can customize the color of the spinner */
`;

const Login = ({ show, onHide, handleShare }) => {
  const [cookies, setCookie] = useCookies();

  const { setUserInfo } = useAuth();

  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({
    country: "India",
    countryCode: "+91",
    mobileNumberLength: 10,
  });

  const [formData, setFormData] = useState({
    mobileNumber: "",
    code: "",
    name: "",
    isValidated: false,
    countryCode: "+91",
  });
  const [otpResponse, setOTPResponse] = useState({});
  const [userResponse, setUserResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(false);

  useEffect(() => {
    setFormData({
      mobileNumber: "",
      code: "",
      name: "",
      isValidated: false,
      countryCode: "+91",
    });
  }, []);

  useEffect(() => {
    let res = mobileNumberValidation(
      formData?.mobileNumber,
      formData?.countryCode,
    );
    setIsValidMobileNumber(res);
  }, [formData]);

  const handleClose = () => {
    onHide();
  };
  const handleChange = (value, name) => {
    if (!/^[0-9]*$/.test(value) && name == "mobileNumber") {
      return;
    } else if (
      value?.length > selectedCountry?.mobileNumberLength &&
      name == "mobileNumber"
    ) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendOTP = async () => {
    const { success, data } = await sendOTP(
      formData?.mobileNumber,
      formData?.countryCode,
    );
    if (success) {
      setOTPResponse(data);
    } else {
      alert("Unable send otp !!");
    }
  };
  const handleSaveToken = async (response) => {
    try {
      await localStorage.setItem("userId", response?.userId);
      await localStorage.setItem("token", response?.idToken);
    } catch (e) {
      console.log(e);
    }
  };

  const saveUserDetails = async (response) => {
    try {
      response = {
        ...response,
        expirationDate: getExpirationDate(response?.expiresIn),
      };
      await localStorage.setItem("userInfo", JSON.stringify(response));
      await localStorage.setItem("userData", response?.name);
      await localStorage.setItem("userId", response?.userId);
      await localStorage.setItem("token", response?.idToken);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRegister = async () => {
    setLoader(true);
    const { success, data } = await updateUserName(formData?.name);
    setLoader(false);
    console.log(success, data);
    if (success) {
      handleClose();
      let userData = { ...userResponse, name: formData?.name };
      saveUserDetails(userData);
      setUserInfo(userData);
      handleShare && handleShare();
    } else {
      alert("Unable update username !!");
    }
  };
  const handleVerifyOTP = async (code) => {
    setLoader(true);
    let { authId, session } = otpResponse;
    const { success, data } = await verifyOTP(authId, code, session);
    setLoader(false);
    if (success) {
      setUserResponse(data);
      setOTPResponse({});
      if (data?.status == "1") {
        setUserInfo(data);
        saveUserDetails(data);
        handleShare && handleShare();
        fetchUserPreferences();
        handleClose();
      } else {
        handleSaveToken(data);
        let name = data?.name == "user" ? "" : data?.name;
        name = name || "";
        setFormData({ ...formData, isValidated: true, name });
      }
    } else {
      alert("Invalid otp/otp expired, please click on send sms again !!");
      handleUpdateFormData("code", "");
      setOTPResponse({});
    }
  };
  const handleVerify = () => {
    if (formData?.name == "" || formData?.name == undefined) {
      alert("Your name required !!");
    } else if (!isPrivacyChecked || !isTermsChecked) {
      alert("Please check terms and conditions !");
    } else {
      handleRegister();
    }
  };

  const fetchUserPreferences = async () => {
    let { success, data } = await getPreferences();
    if (success) {
      console.log(cookies);
      let { curriculum, grade, subject } = data;
      let body = {
        curriculum: {
          id: curriculum?.id,
          name: curriculum?.name,
        },
        grade: {
          id: grade?.id,
          name: grade?.name,
        },
        subject: {
          id: subject?.id,
          name: subject?.name,
        },
      };
      setCookie("preferences", body, { path: "/" });
    }
  };

  const handleUpdateFormData = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
  };
  const mandatory_field = () => {
    return <span className="mandatory">*</span>;
  };
  const handleCodeChange = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^\d{0,4}$/.test(inputValue);
    if (isValidInput) {
      handleUpdateFormData("code", inputValue);
    }
  };
  const handleSelect = (item) => {
    setFormData({
      ...formData,
      countryCode: item?.countryCode,
    });
    setSelectedCountry(item);
  };
  let { mobileNumber, code, name, isValidated, countryCode } = formData;
  return (
    <div id="login">
      {show && (
        <div className="login-modal">
          <div className="modal-content">
            {!isValidated && (
              <>
                <div className="login">
                  <p className="login-title">
                    {strings.login.register_or_login}
                  </p>
                  <button
                    type="login-modal-button"
                    className="custom-image-button"
                    onClick={handleClose}
                  >
                    <img
                      src={images.common_Images.close}
                      alt="close"
                      className="close-button"
                    />
                  </button>
                </div>
                <div className="form-group">
                  <div className="form-label">
                    {strings.login.mobile_number}
                    {mandatory_field()}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle className="login-dropdown">
                        {countryCode}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="login-dropdown-menu">
                        {COUNTRY_CODES?.map((item, index) => {
                          return (
                            <Dropdown.Item
                              key={item}
                              className={`login-dropdown-menu-item ${
                                formData?.countryCode == item?.countryCode
                                  ? "menu-active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleSelect(item);
                              }}
                            >
                              {`${item?.country}(${item?.countryCode})`}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      className="input"
                      name="mobileNumber"
                      value={mobileNumber}
                      onChange={(e) => {
                        handleChange(e.target.value, "mobileNumber");
                      }}
                    />
                  </div>
                </div>
                <ISButton
                  label={strings.login.send_sms}
                  handleClick={() => {
                    if (isValidMobileNumber) {
                      handleUpdateFormData("code", "");
                      handleSendOTP();
                    }
                  }}
                  containerStyle={isValidMobileNumber ? "active-login-btn" : ""}
                  isEnable={isValidMobileNumber}
                  module="Login"
                />
                {code?.length == 0 && otpResponse?.session ? (
                  <p className="otp-success">{strings.login.otp_sent}</p>
                ) : (
                  <p className="otp-success" />
                )}
                <div className="form-group">
                  <div className="form-label">{strings.login.sms_code}</div>
                  <input
                    className="input"
                    name="code"
                    value={code}
                    onChange={(e) => {
                      handleChange(e.target.value, "code");
                    }}
                  />
                </div>
                {code?.trim()?.length == 4 && otpResponse?.session && (
                  <button
                    className="login-modal-button filled"
                    onClick={() => {
                      otpResponse?.session && handleVerifyOTP(code);
                    }}
                  >
                    {loader ? (
                      <ClipLoader loading={true} css={override} size={24} />
                    ) : (
                      strings.login[
                        otpResponse?.status == "1" && handleShare
                          ? "login"
                          : "next"
                      ]
                    )}
                  </button>
                )}
              </>
            )}
            {isValidated && (
              <>
                <div className="login">
                  <p className="login-title">{strings.login.title}</p>
                  <span>
                    <img
                      src={images.common_Images.close}
                      alt="close"
                      className="close-button"
                      onClick={handleClose}
                    />
                  </span>
                </div>
                <div className="form-group">
                  <div className="form-label">
                    {strings.login.your_name}
                    {mandatory_field()}
                  </div>
                  <input
                    className="input"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      handleChange(e.target.value, "name");
                    }}
                  />
                </div>
                <div style={{ marginBottom: "8px" }}>
                  <span className="checkbox-label">
                    {strings.login.aggrement_title}
                  </span>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      style={{ marginTop: -10 }}
                      checked={isTermsChecked}
                      onChange={(event) => {
                        setIsTermsChecked(event.target.checked);
                      }}
                    />
                    <span
                      className="checkbox-label"
                      style={{
                        color: "#7abc40",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("/terms-of-service", "_blank");
                      }}
                    >
                      {strings.login.terms}
                    </span>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      checked={isPrivacyChecked}
                      onChange={(event) => {
                        setIsPrivacyChecked(event.target.checked);
                      }}
                    />
                    <span
                      className="checkbox-label"
                      style={{
                        color: "#7abc40",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("/privacy", "_blank");
                      }}
                    >
                      {strings.login.privacy}
                    </span>
                  </div>
                </div>
                <button
                  className="login-modal-button filled"
                  onClick={() => {
                    handleVerify();
                  }}
                >
                  {loader ? (
                    <ClipLoader loading={true} css={override} size={24} />
                  ) : (
                    strings.login.register
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
/* eslint-enable */
