import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import ISButton from '../components/ISButton';
import Forward from '../assets/nextIcon.png';
import BackWard from '../assets/previousIcon.png';
import '../styles/home-web.css';
import WebCard from '../components/WebCard';
import Carousel from 'react-bootstrap/Carousel';
import { STRINGS } from '../utils/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/CustomModal';

let intervalId;

const HomeWeb = ({ categories }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [categories.length]);

  const CarouselItem = (item, index) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="left-side" style={{ position: 'absolute' }}>
          <div className="display-text">{item?.displaytext}</div>
          <div className="content-text">{item?.description}</div>
          <ISButton
            label={item?.action}
            imageSrc={Forward}
            imagePosition={'right'}
            iconColor={'#7abc40'}
            containerStyle="forward-btn-no-border"
          />
        </div>
        <div
          className="right-side"
          style={{
            marginLeft: `${window.innerWidth * 0.8 * 0.3}px`,
            marginTop: '16px',
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: item?.animation,
            }}
            isStopped={currentIndex !== index}
            isPaused={currentIndex !== index}
            height={300}
            width={300}
            style={{ marginLeft: '120px', marginTop: '-20px' }}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="custom-card-row">
        <div
          className="card-main"
          onClick={() => {
            if (
              categories[currentIndex]?.displaytext
                ?.toLowerCase()
                .includes(STRINGS?.home?.question_bank?.name)
            ) {
              setShowWarningModal(true);
            } else {
              navigate(`${categories[currentIndex]?.navigation}`);
            }
          }}
        >
          <Carousel
            activeIndex={currentIndex}
            pause={false}
            controls={false} // Disable default controls
            indicators={false} // Optional: Disable indicators as well
          >
            {categories?.map((item, index) => {
              return (
                <Carousel.Item key={index + 'pos'}>
                  {CarouselItem(item, index)}
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div style={{ position: 'absolute', bottom: '16px', left: '28px' }}>
            <div className="dot-container">
              {categories.map((item, index) => (
                <div
                  key={index}
                  className={`dot ${index === currentIndex ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex(index);
                    clearInterval(intervalId); // Clear the interval
                    intervalId = setInterval(() => {
                      setCurrentIndex(index);
                    }, 4000); // Start a new interval
                  }}
                ></div>
              ))}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              bottom: '16px',
              right: '28px',
            }}
          >
            <div
              className="btn-container"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex(
                  currentIndex > 0 ? currentIndex - 1 : categories.length - 1,
                );
                clearInterval(intervalId); // Clear the interval
                intervalId = setInterval(() => {
                  setCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % categories.length,
                  );
                }, 4000); // Start a new interval
              }}
            >
              <img src={BackWard} alt="backward" />
            </div>
            <div
              className="btn-container"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex(
                  (prevIndex) => (prevIndex + 1) % categories.length,
                );
                clearInterval(intervalId); // Clear the interval
                intervalId = setInterval(() => {
                  setCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % categories.length,
                  );
                }, 4000); // Start a new interval
              }}
            >
              <img src={Forward} alt="forward" />
            </div>
          </div>
        </div>
        <div className="card-10">
          <WebCard item={STRINGS?.home?.everyday_ai} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '26px',
          maxWidth: '100%',
          margin: '0 auto',
          marginTop: '20px',
        }}
      >
        {categories?.map((item, index) => {
          return (
            <div
              key={'pos' + index}
              style={{
                flex: '0 0 calc(33.33% - 20px)',
              }}
            >
              <WebCard
                item={item}
                type={'ai assistant'}
                setShowModal={setShowWarningModal}
              />
            </div>
          );
        })}
        {/* Empty space for the remaining 33.3% */}
        <div style={{ flex: '0 0 calc(33.33% - 20px)' }}></div>
      </div>
      {showWarningModal && (
        <CustomModal
          showModal={showWarningModal}
          onHide={() => {
            setShowWarningModal(false);
          }}
        />
      )}
    </>
  );
};

export default HomeWeb;
