/* eslint-disable */
import React from "react";
import styles from "./styles/terms-and-conditions.module.css";
import ContactUsCard from "./contactus-card";

export default function PrivacyContent() {
  return (
    <div className={styles["sub-container"]}>
      <div className={styles.title}>Our Privacy Policy</div>
      <div className={styles["header"]}>
        <div className={styles["header-left"]}>
          <div className={styles["sub-title"]}>
            {`THIS PRIVACY POLICY (“POLICY”) DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.`}
          </div>
          <div className={styles["sub-title"]}>
            {`THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER THAT REQUIRES PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF SERVICE FOR ACCESS OR USAGE OF THIS WEBSITE.`}
          </div>
          <div className={styles["info-text"]}>
            {`We, People Combine Basics Private Limited, are a company incorporated under the laws of the Republic of India and/or its affiliates with its registered office located at Plot No.99, Road No.72, Jubilee Hills Co-operative Society, Jubilee Hills, Shaikpet, 500033, Hyderabad, Telangana, India, (“Company” / “we” / “our” / “us”), committed to protecting the privacy of our users (“you” / “your” / “yourself”) who are using our website https://coschool.ai/ (“Website”) and accessing our services provided on the Website (“Services”). Please note that this Privacy Policy applies to this Website and all products and Services offered on and through this Website. It is our mission to help teachers deliver meaningful learning experiences and to provide students with access to quality learning tools. To achieve this, we build easy to use tools that help make things simple for teachers and students and empower them to focus on the important aspects of learning. It is hence critical that we create a safe and secure environment where Users freely share content and ideas. Creating such a safe and secure environment is central to the success of our endeavours. Our practices and procedures in relation to the collection, sharing, processing and use of your data / information have been set out in this Policy.`}
          </div>
          <div className={styles["info-text"]}>
            {`This Policy shall be deemed to be incorporated into the terms of service of the Website (the “Terms of Service”) and shall be read in addition to the Terms of Service. In the event of any conflict between this Policy and the Terms of Service, the interpretation placed by the Company shall be final and binding on you. `}
          </div>
          <div className={styles["info-text"]}>
            {`This Policy is being framed in view of the Information Technology Act, 2000 read with Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and Regulation 3(1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 and the Digital Personal Data Protection Act, 2023.`}
          </div>
          <div className={styles["info-text"]}>
            {`By accessing, browsing or otherwise using the Website and/or by giving the Company your information and/or by explicitly giving your consent (as indicated and recorded on the Website), you expressly agree to be bound by the terms of this Policy. If you do not agree with the terms mentioned herein, please exit this Website and restrict your access. By accepting this Policy, you understand and agree to the collection, use, sharing and processing of personal information as described herein. If you provide the Company with personal information about someone else, you confirm that (a) such information is accurate and up-to-date; (b) such person is aware that you have provided their information; and (c) they consent to both the disclosure and the use/processing of their information in accordance with this Policy. This Policy applies to all current and former visitors, who access this Website. `}
          </div>
          <div className={styles["info-text"]}>
            {`If you are unwilling or unable to be legally bound by this Policy, please do not use the Website. You cannot accept this Policy if: (a) you are not lawfully entitled to use the Website; or (b) if you are not of legal age to form a binding agreement with the Company in accordance with the applicable laws.`}
          </div>
        </div>
        <div className={styles["header-right"]}>
          <ContactUsCard />
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>INTRODUCTION</div>
        <ul className={styles["info-text"]}>
          <li>{`This Privacy Policy governs the use of data collected by us to provide Services through our Website.`}</li>
          <li>{`The aim of this Privacy Policy is to ensure that you understand what Information (as defined below) we collect about you, the reasons for collection and use, and the entities we may share it with. By providing your consent to this Privacy Policy, you agree to the collection, use and transfer of your Information as set out in this Privacy Policy.. If you do not agree, please refrain from using the Website. You can contact us anytime with queries about this Privacy Policy at support@coschool.ai.`}</li>
          <li>{`We ensure compliance with all applicable laws, including the Digital Personal Data Protection Act, 2023.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>DEFINITIONS</div>
        <ul className={styles["info-text"]}>
          <li>{`“Child / Children” means a person who has not completed the age of 18 years.`}</li>
          <li>{`“Large Language Models” or “LLMs” refer to advanced artificial intelligence models designed to process and analyse natural language data on a vast scale. These models possess significant computational power and have been trained on extensive datasets to understand and generate human-like language patterns, responses, and context.`}</li>
          <li>{`“Messages” means the messages sent to Users from Coschool via SMS and WhatsApp or any other medium.`}</li>
          <li>{`“Users” includes you, students, teachers, parents, and enterprises (schools) that use the Service. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>SCOPE OF THE POLICY</div>
        <ul className={styles["info-text"]}>
          <li>{`By accessing or using the Website or otherwise giving Us your information, you undertake that you have the capacity to enter into a legally binding contract vide this Policy, which constitutes a legally binding document between you and the Company under the applicable laws, in particular, the Indian Contract Act, 1872 and Digital Personal Data Protection Act, 2023. The Company will collect and process your personal and third party data carefully, only for the purposes described in this Policy and only to the extent necessary as defined herein and within the scope of the applicable legal regulations. This Policy seeks to ensure that any personal information or third party information handled by the Company is managed in a way that is ethical, compliant and adheres to best industry practices.`}</li>
          <li>{`Please read the terms and conditions of this Policy carefully, before accessing or using this Website. By accessing or using the Website including its intermediary services, you agree to the terms of this Policy. If you are accepting this Policy on behalf of another person or company or other legal entity, you represent and warrant that you have full authority to bind such person, company or legal entity to these terms.`}</li>
          <li>{`This Policy describes the types of information the Company collects, why and how the Company uses the information, with whom the Company shares it, and the choices you can make about the Company’s use of the information. This Policy also describes the measures the Company takes to protect the security of the information and how you can contact the Company about its privacy practices.`}</li>
          <li>{`This Policy describes the Company’s current data protection policies and practices and may be amended/updated from time to time. Any changes to this Policy will become effective upon posting of the revised Policy on the Website. It is recommended that you regularly check this Policy to apprise yourself of any updates. Your continued use of Website or provision of data or information thereafter will imply your unconditional acceptance of such updates to this Policy.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>APPLICABILITY TO CHILDREN</div>
        <div
          className={styles["info-text"]}
        >{`When a Child creates an account, we collect their information in compliance with applicable laws, by seeking the verifiable parental consent of a parent or legal guardian (“Parent”) (as may be applicable) for creation of that account in accordance with applicable law. The creation of an account is done through a one-time-password (“OTP”) that will be sent to phone number being used to create that account. When our Website is used by a school in an educational setting, we rely on the school to provide the requisite consent through contractual arrangements, on behalf of the parent, for us to collect information from a User under the age of 18 for educational purposes. Failure to create an account on the Website with the permission of your Parent or in compliance with relevant laws and regulations renders you ineligible to use the Website. In such cases, the Company shall not be liable for any consequences arising from unauthorized use of the Website by Child. Parent or legal guardians of the Child shall remain responsible for the compliance to the provisions of these Privacy Policy.`}</div>
      </div>
      <div>
        <div className={styles["sub-title"]}>COMPLIANCE</div>
        <ul className={styles["info-text"]}>
          <li>{`We collect minimal information from you and only use the Information collected for the purposes explicitly called out in the below clause 7 of this Privacy Policy. `}</li>
          <li>{`The data collected is stored securely in a third-party cloud storage service provider, i.e., Amazon Web Services (AWS) servers in India as per industry standards. All details with regard to the nature of the data collected and the purpose for collecting it can be found in this Privacy Policy. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>CONSENT</div>
        <ul className={styles["info-text"]}>
          <li>{`By mere use of the Website, you expressly consent to the Company’s use, access, processing and disclosure of your Information and third-party information in accordance with this Policy. If you do not agree with the terms of this Policy, please do not use this Website. `}</li>
          <li>{`In case you wish to avail any or all of the Services provided on the Website, the Company shall provide a method for sign-up and login, which may include mobile number and OTP based login, or any other mechanism as may be available on the Website (“Login Credentials”) as set out in the Terms of Service. You may be required to fill in the Information on the Website including full name, email address, mobile number and/or a one-time password sent on your mobile number to set up your account on the Website. You hereby explicitly agree that your use and access of the Login Credentials shall be governed by the Terms of Service read with the terms of this Policy and any other agreement that you may enter into with the Company. You should not share your Login Credentials, one-time password or other security information for your account with anyone. If We receive instructions using your Login Credentials, We will consider that you have authorized the instructions.`}</li>
          <li>{`Your acceptance of this Policy signifies your acceptance and consent to the processing of your Information by the Company and forms a valid and binding agreement between you and the Website/Company for Information processing by the User as per the terms of this Policy (as per Section 6 (1) of the Digital Personal Data Protection Act, 2023).`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          TYPES OF INFORMATION COLLECTED BY THE COMPANY
        </div>
        <ul className={styles["info-text"]}>
          <li>{`Personal Information: Personal Information means any information that may be used to identify a natural person, including, but not limited to, the first and last names, mobile number, e-mail addresses, or any other information. The Company limits the collection of Personal Information which is necessary for its intended purpose.`}</li>
          <li>{`Assessment Information: Assessment Information refers to any data, records, or information related to an individual’s assessment results and score sheets to track their progress, provide personalized feedback, and customize learning experiences, subjects, data on students' progress within courses, and other educational activities, to gauge learning effectiveness and to offer personalized recommendations, aggregated and anonymized learning analytics data, data in relation to students' interactions with our Website, such as time spent on activities, resources accessed, and communication with instructors or peers or other Users, to enhance the learning experience and provide support when needed.`}</li>
          <li>{`Non-Personal Information: Non-personal information means information that does not specifically identify an individual or business, but includes information from you, such as your browser type, your internet service provider (ISP), operating system & version and your internet protocol (IP) address, device location, information about your mobile device including hardware model, preferred language, unique devise identifier, your profession, field of work, information you provide us when you contact Us for help; information you enter into our system when using the Website, such as while asking doubts, participating in discussions and taking tests and any other information you may give on and through the Website. The Company may gather any non-personal information regarding how many people visit the Website, the pages you visit, how long you visited our Website, and the date and time you used the Website. The Company may also collect Non-Personal Information that you voluntarily provide, such as information included in response to a questionnaire, or a survey conducted by the Company.`}</li>
          <li>{`Usage Information: Usage Information includes without limitation all data and information collected automatically through the Website (or through the third party analytics service providers), by use and access of the Website in the nature of system administrative data, statistical and demographical data, and operational information and data generated by or characterizing use of the Website including without limitation, Non-Personal Information, cookies, your search queries, comments, search results selected, number of clicks, pages viewed and the order of those pages, error logs, Website traffic, time spent on the Website, number of visits to the Website and other similar information and behaviour indicating the mode and manner of use of the Website. `}</li>
          <li>{`Personal Information, Assessment Information, Non-Personal Information and Usage Information hereinafter shall be referred to as “Information”. The Company limits the collection of Information which is necessary for its intended purpose.`}</li>
          <li>{`We only collect the data that is necessary for providing the Service. At all times, we endeavour to minimise the data that we collect from you.`}</li>
          <li>{`We collect Personal Information from all Users who create an account on Website.`}</li>
          <li>{`We also collect log data from all the visitors to our Website and teachers who willingly leaving data for our marketing campaigns.`}</li>
          <li>{`You represent that the Information you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide the same. Your providing Information and our consequent storage, collection, usage, transfer, access or processing of the same shall not be in violation of any third-party agreements, laws, judgments, orders or decrees. `}</li>
          <li>{`Please note that you will be required to give Personal Information as specified above, only if you sign up on the Website. Therefore, there is no compulsion on those who simply browse the Website to provide any Personal Information without availing any of the Services. At all points, it is advised that you exercise strict caution while submitting any Personal Information about you or your family members. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          WHY DO WE COLLECT THIS INFORMATION?
        </div>
        <ul className={styles["info-text"]}>
          <li>
            {`We use the data collected for the purposes of providing the Services to the Users and personalizing User experience as laid out in this Privacy Policy and the Terms of Service of Website. The data collected is used for the following purposes:`}
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>{`Allow Users to retrieve, view and edit teaching plans.`}</li>
              <li>{`Allow Users to access and use our various features such as learning activities, curiosity triggers, engagement tips, lesson planning, question banks etc.`}</li>
              <li>{`Send notifications about activities and updates on your account.`}</li>
              <li>{`Analyse usage information to investigate, prevent, secure and detect activities on the Website that we believe may violate the law or applicable regulations.`}</li>
              <li>{`Send notifications about activities and updates on the Users’ accounts.`}</li>
              <li>{`We might use the data or content of the User for targeting their experience with specific topics or subjects.`}</li>
              <li>{`Provide customer support to Users.`}</li>
              <li>{`Derive insights from usage trends to develop new features or to improve the existing ones.`}</li>
            </ol>
          </li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          WHAT IS THE DATA NOT COLLECTED FOR?
        </div>
        <ul className={styles["info-text"]}>
          <li>{`We do not allow advertising of data or sharing data for advertising on Website.`}</li>
          <li>{`We do not display advertisements, share data for the purpose of displaying advertisements, or allow data collection by advertisers or data brokers.`}</li>
          <li>{`We do not sell data to any third-party for any purpose.`}</li>
          <li>{`We do not allow for the profiling of our Users for targeted online advertisements.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>DUTIES OF USERS</div>
        <ul className={styles["info-text"]}>
          <li>{`Each of the users of the Website is bound by certain obligations while sharing their Information as per the provisions of the Digital Personal Data Protection Act, 2023 and such obligations include (apart from the obligations prescribed under the Terms of Service of the Website:`}</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>{`comply with the provisions of all applicable laws for the time being in force while exercising rights under the provisions of the Digital Personal Data Protection Act, 2023;`}</li>
            <li>{`to ensure not to impersonate another person while providing their own personal data/Information for a specified purpose; `}</li>
            <li>{`to ensure not to suppress any material information while providing their personal data/Information for any document, unique identifier, proof of identity or proof of address issued by the State or any of its instrumentalities;`}</li>
            <li>{`to ensure not to register a false or frivolous grievance or complaint with the Company or the Data Protection Board of India; and `}</li>
            <li>{`to furnish only such information as is verifiably authentic, while exercising the right to correction or erasure under the provisions of Digital Personal Data Protection Act, 2023.`}</li>
          </ol>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>DATA RETENTION</div>
        <div className={styles["info-text"]}>
          {`We will store your data only for as long as it is required for providing the Service or as mandated by law or required by third parties as detailed in clause 12 below. `}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          THIRD PARTIES AND SHARING OF DATA
        </div>
        <ul className={styles["info-text"]}>
          <li>{`The Company may engage third party vendors and/or contractors to perform certain support services for the Company, including, without limitation, fulfilment of your requests, software maintenance services, advertising and marketing services, web hosting services and such other related services which are required by the Company to provide its services efficiently. These third parties may have limited access to Information. If they do, this limited access is provided so that they may perform these tasks for the Company and they are not authorized by the Company to otherwise use or disclose Information, except to the extent required by law. The Company does not make any representations concerning the privacy practices or policies or terms of use/service of such websites/third parties, nor does it control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on and with such websites/third parties. `}</li>
          <li>{`The Website may contain links and interactive functionality interacting with the websites of third parties or third parties including without limitation the payment gateways, advertisements, social media Websites. The Company is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such websites or third parties. Before enabling any sharing functions to communicate with any such websites or otherwise visiting any such websites, the Company strongly recommends that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>THIRD PARTY ANALYTICS</div>
        <ul className={styles["info-text"]}>
          <li>{`In order to improve your experience on our Website, we collect and use aggregate data about usage patterns of how you use our Website. For example, how a User interacts with various features on a page, the options selected by the User, the amount of time that the User spends on a specific page, etc. This is done to streamline the existing User experience and to provide Users with a better experience of using the Service.`}</li>
          <li>{`We use a small number of third party services to collect and analyse this data. These services are contractually obligated only to use data about your usage of Services to provide analytics-related services to us and are prohibited from sharing it or using it for other purposes.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>DATA SECURITY</div>
        <ul className={styles["info-text"]}>
          <li>{`We implement industry-standard security measures to protect the confidentiality and integrity of the data we collect and process. Access to the LLMs is restricted to authorized personnel only and we follow the principle of least privilege.`}</li>
          <li>{`We securely store your Personal Information only as long as required for lawful purposes or as mandated by law.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          COSCHOOL’S USE OF ARTIFICIAL INTELLIGENCE
        </div>
        <div className={styles["info-text"]}>
          {`Our use of AI in our Service does not showcase any kind of bias or discrimination in generating its results.`}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          COMPLIANCE WITH LAWS FOR USERS OUTSIDE INDIA
        </div>
        <ul className={styles["info-text"]}>
          <li>{`While we strive to ensure compliance with applicable laws and regulations, it is not feasible for us to address the specific laws of every region. However, we are committed to protecting the privacy and Personal Information of all our users.`}</li>
          <li>{`Acceptance of Privacy Policy: By accessing and using our services as a user outside India, you acknowledge and agree to the terms of this Policy and our data practices.`}</li>
          <li>{`Data Subject Rights: As a user outside India, you may have certain rights regarding your Personal Information under the applicable laws of your region. While we strive to respect these rights to the extent required by law, please note that our ability to fully address specific legal requirements may be limited. We will, however, make reasonable efforts to assist you in exercising your rights as permitted by applicable laws.`}</li>
          <li>{`Compliance with Local Laws: While we endeavour to comply with applicable laws and regulations, including privacy and data protection laws, in all regions we operate, it is important to note that certain laws may have extraterritorial application. We encourage users outside India to familiarize themselves with the local laws that may apply to their Personal Information and to assess the risks associated with using our services.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>COOKIE POLICY</div>
        <ul className={styles["info-text"]}>
          <li>{`When you visit the Website, it may store or retrieve information on your browser, mostly in the form of cookies. The information that is stored might be about you, your preferences or your device and is mostly used to make the Website work as you expect it to. While the information does not directly identify you, it can provide you with a more personalized web experience. As we respect your right to privacy, you can choose not to allow some types of cookies through your browser settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. `}</li>
          <li>{`All browsers are different, so visit the “help” section of your browser to learn about cookie preferences and other privacy settings that may be available. Please note that if you choose to remove or reject cookies or clear local storage this could affect the features, availability, and functionality of our Service.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>
          VIEWING, EDITING OR WITHDRAWING YOUR INFORMATION
        </div>
        <div className={styles["info-text"]}>
          {`You may write to us at info@coschool.ai to access, review, amend or correct your Personal Information or withdraw your consent to provide Personal Information. However, we are not responsible for the authenticity of the Personal Information provided by you. Access to or correction, updating or deletion of your Personal Information may be denied or limited by the Company if it would violate another person’s rights and/or is not otherwise permitted by applicable law.`}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>DELETING YOUR ACCOUNT</div>
        <div className={styles["info-text"]}>
          {`You have the right to “forget ability”, i.e., we will remove all your information from our systems if you so wish, if you would like to delete your account or any content submitted to Coschool, you can sign up or you can send an email to info@coschool.ai. We will notify you with a message before deleting your account from our database. `}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>DATA PROTECTION PRACTICES </div>
        <ul className={styles["info-text"]}>
          <li>{`We follow the latest, industry standards to protect your data. Some measures that are in place include the use of highly secure, access-controlled data centres, data encryption in transit, and encryption data at rest etc. Despite these measures, in the event of a security breach, we will notify the affected Users within the amount of time as required by the applicable law.`}</li>
          <li>{`Please be advised that, while the Company strives to protect Information and privacy, the Company cannot guarantee or warranty its absolute security when Information is transmitted over the internet into the Website. The Company will annually evaluate this necessity considering your privacy and our relation while keeping the applicable legislation in mind.`}</li>
          <li>{`Access to your online account on the Website is via. your Login Credentials which is password protected and this helps to secure your account information. You are solely responsible for maintaining the confidentiality of your Login Credentials. To ensure safety of your Information, you are advised against sharing your Login Credentials with anyone. If you suspect any unauthorized use of your account, you must immediately notify the Company by sending an email to info@coschool.ai. You shall be liable to indemnify the Company for any loss suffered by the Company due to such unauthorized use of your account.`}</li>
          <li>{`For any loss or theft of Information, due to unauthorized access to your device through which you use the Website or other reasons solely attributable to you, the Company shall not be held liable or responsible under any circumstance whatsoever. Further, the Company shall not be responsible for any breach of security or for any actions of any third parties/vendors or events that are beyond the Company’s reasonable control including but not limited to acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of internet service or telephone service of the user, etc. `}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>CHANGES TO THE PRIVACY POLICY</div>
        <div className={styles["info-text"]}>
          {`We may make changes to this Privacy Policy from time to time to account for changes to our practices or as required by applicable laws. We will notify you of any such changes by posting the revised Privacy Policy with an updated date of revision on our Website. If we change this Privacy Policy in a way that we believe will materially affect your rights, we will notify you of such changes via an SMS or WhatsApp and publish the updated Privacy Policy on our website. If you continue to use our service after you receive notice of changes to this Privacy Policy, we will assume that you have accepted these changes. For previous versions of the Privacy Policy, please reach out to us at info@coschool.ai.`}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>LIMITATION OF LIABILITY</div>
        <ul className={styles["info-text"]}>
          <li>{`The Company shall not be liable to you for any loss of profit, anticipated savings, goodwill, reputation or business opportunities or any type of direct or indirect, incidental, economic, compensatory, punitive, exemplary or consequential losses arising out of performance or non-performance of its obligations under this Policy.`}</li>
          <li>{`The Company is not responsible for any actions or inactions of any third parties, that receives your Personal Information.`}</li>
          <li>{`Notwithstanding anything contained in this Policy or elsewhere, the Company shall not be held responsible for any loss, damage or misuse of your Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event and/or any third party. The term “Force Majeure Event” shall mean any event that is beyond the reasonable control of the Company and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, civil disturbances, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, epidemic, pandemic or national/state lockdown due to any reason and any other similar events not within the control of the Company and which the Company is not able to overcome.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>GOVERNING LAW</div>
        <div className={styles["info-text"]}>
          {`Without reference to any conflict of laws principles this Policy shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall exclusively be in Hyderabad, Telangana, India. In the event of any dispute arising out of this Policy, the same shall be settled by binding arbitration conducted by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and Conciliation Act, 1996. The seat and venue of arbitration shall be Hyderabad, Telangana, India.`}
        </div>
      </div>
      <div>
        <div className={styles["sub-title"]}>GRIEVANCE REDRESSAL MECHANISM</div>
        <ul className={styles["info-text"]}>
          <li>{`In accordance with the Information Technology Act, 2000 and Rules made thereunder along with Section 13 of the Digital Personal Data Protection Act, 2023, you have the right to readily available means of grievance redressal in relation to your Information collected by the Website. Therefore, the Website/Company respects your rights and is hereby furnishing the name and contact details of the Grievance Officer, as provided below:`}</li>
          <ol style={{ listStyleType: "none" }}>
            <li>{`Name: Vishwa Paresh Talati`}</li>
            <li>{`Address: Plot No.99, Road No.72, Jubilee Hills Co-operative Society, Jubilee Hills, Shaikpet, 500033, Hyderabad, Telangana, India,`}</li>
            <li>{`Cell: +91-7075780899`}</li>
            <li>{`E-mail: info@coschool.ai`}</li>
          </ol>
          <li>{`In case your grievance was not handled in compliance of the applicable laws by the Company, you have the right to file appropriate complaints (as per the prescribed rules) with the Data Protection Board of India under the provisions of the Digital Personal Data Protection Act, 2023.`}</li>
        </ul>
      </div>
      <div>
        <div className={styles["sub-title"]}>CONTACT INFORMATION</div>
        <div className={styles["info-text"]}>
          {`If you have any questions about this Privacy Policy, please feel free to write to us at: info@coschool.ai and we will reach out to you as soon as possible.`}
        </div>
      </div>
    </div>
  );
}
