import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ISButton from '../components/ISButton';
import '../styles/prompts.css';
import Streaming from '../components/Streaming';
import { getPromptOfTheDay } from '../apis/callAPi';
import { strings } from '../utils/strings';
import { routesLinks } from '../utils/RouteConstants';

const PromptOfTheDay = () => {
  const [promptData, setPromptData] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const [displayButton, setDisplayButton] = useState(true);

  const { state: input } = useLocation();

  useEffect(() => {
    fetchPromptOfTheDay();
  }, []);

  const fetchPromptOfTheDay = async () => {
    if (input?.selectedPrompt) {
      setPromptData(input?.selectedPrompt);
    } else {
      const response = await getPromptOfTheDay();
      if (response?.success) {
        setPromptData(response?.data);
      }
    }
  };

  let ActiveColor = isComplete == false ? 'active-task-btn' : 'task-btn';
  return (
    <Container>
      <div className="prompt-main">
        <p className="header-text">{strings?.titles?.prompt}</p>
        <div
          className="all-prompts-btn"
          onClick={() => {
            window.location.href = routesLinks.promptlibrary;
          }}
        >
          Prompt Library
        </div>
      </div>

      <div className="prompt-title-container">
        <p className="prompt-title">{`Purpose`}</p>
        <p className="prompt-title-info">{promptData?.title}</p>
      </div>
      <div
        className="prompt-title-container"
        style={{ backgroundColor: '#515151b3' }}
      >
        <p className="prompt-title">{`Ai tool`}</p>
        <p className="prompt-title-info">{promptData?.subCategory}</p>
      </div>
      <div className="prompt-container">
        <p className="prompt-title">{`Prompt`}</p>
        <p
          className="prompt-title-info"
          dangerouslySetInnerHTML={{
            __html: promptData?.prompt,
          }}
        />
      </div>
      {displayButton && (
        <ISButton
          label={strings?.default?.generate}
          handleClick={() => {
            if (isComplete == false) {
              setIsComplete(true);
            }
          }}
          isEnable={isComplete == false}
          containerStyle={`${ActiveColor} btn-extra-height`}
        />
      )}
      <Streaming
        syllubusMapping={promptData}
        categoryId={''}
        prompt={''}
        screenType={strings?.titles?.prompt}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        promptData={promptData?.output || []}
        displayButton={displayButton}
        setDisplayButton={(display) => {
          setDisplayButton(display);
        }}
      />
    </Container>
  );
};
export default PromptOfTheDay;
