import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';

const VideoPlayer = ({ URL, handleStart }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [isPlay, setIsPlay] = useState(false);
  const playerRef = React.useRef();
  const handlePlay = () => {
    console.log('Player started....');
    handleStart && handleStart();
    setIsPlay(true);
  };
  const handlePause = () => {
    console.log('Player paused....');
    setIsPlay(false);
  };

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        playing={isPlay}
        url={URL}
        controls={true}
        onPlay={handlePlay}
        onPause={handlePause}
        width={isDesktop ? '361px' : '100%'}
        height="189px"
        style={{ marginBottom: '20px' }}
      />
    </div>
  );
};

export default VideoPlayer;
