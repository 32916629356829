/* eslint-disable */
import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import {
  getCardDesc,
  getCardTitle,
  MAX_MOBILE_WIDTH,
  MOBILE_ANIMATION_DELAY,
  STUDENT_URL,
} from "./helpers";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { routesLinks } from "../../utils/RouteConstants";

const CardItem = ({ color, setValue, styles }) => {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  const navigate = useNavigate();

  const handleNavigate = (path = "") => {
    if (isMobile) {
      setValue(true);
      setTimeout(() => {
        if (path == "") {
          window.location.href = STUDENT_URL;
        } else {
          navigate(path);
        }
        setValue(false);
      }, MOBILE_ANIMATION_DELAY);
    } else {
      if (path == "") {
        const item = localStorage.getItem("studentRef");
        const encodedItem = encodeURIComponent(item);
        if (item !== null) {
          window.location.href = `${STUDENT_URL}?item=${encodedItem}`;
        } else {
          window.location.href = `${STUDENT_URL}`;
        }
        localStorage.removeItem("studentRef");
      } else {
        navigate(path);
      }
    }
  };
  const handleClick = () => {
    if (color == "teacher") {
      handleNavigate(routesLinks.teacher);
    } else if (color == "student") {
      handleNavigate("");
    } else if (color == "school") {
      handleNavigate(routesLinks.schoolpartner);
    }
  };
  return (
    <Card
      className={styles[`${color}-card`]}
      onMouseEnter={() => setValue(true)}
      onMouseLeave={() => setValue(false)}
    >
      <div className={styles[`${color}-btn`]} onClick={handleClick}>
        {getCardTitle(color)}
      </div>
      <div className={styles[`${color}-desc`]}>{getCardDesc(color)}</div>
    </Card>
  );
};

export default CardItem;
