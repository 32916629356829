import React, { useState } from 'react';
import { images } from '../utils/images';
import { strings } from '../utils/strings';
import { updateUserName } from '../apis/auth';

import '../styles/login.css'; // Make sure this path is correct
import { useAuth } from '../context';

const EditName = ({ show, onHide }) => {
  const { userInfo, setUserInfo } = useAuth();
  const [userName, setUserName] = useState(userInfo?.name);

  const handleChange = (event) => {
    const { value } = event.target;
    setUserName(value);
  };
  const handleUserName = async (userName) => {
    try {
      let savedData = await localStorage.getItem('userInfo');
      try {
        savedData = JSON.parse(savedData);
      } catch (e) {
        savedData = {};
      }
      savedData = savedData || {};
      savedData = { ...savedData, name: userName };
      await localStorage.setItem('userInfo', JSON.stringify(savedData));
      await localStorage.setItem('userData', userName);
    } catch (e) {
      console.log(e);
    }
  };
  const handleRegister = async () => {
    await localStorage.removeItem('externalRef');
    await localStorage.removeItem('utm_params');
    const response = await updateUserName(userName);
    console.log(response, 'response');
    if (response?.success) {
      handleUserName(userName);
      let userData = { ...userInfo, name: userName };
      setUserInfo(userData);
      onHide();
    } else if (response?.status == '401') {
      onHide();
    }
  };
  return (
    <>
      {show && (
        <div className="login-modal">
          <div className="modal-content">
            <div className="login">
              <p className="login-title">{strings.login.edit_name}</p>
              <span>
                <img
                  src={images.common_Images.close}
                  alt="close"
                  className="close-button"
                  onClick={onHide}
                />
              </span>
            </div>
            <div className="form-group">
              <input
                className="input"
                name="name"
                value={userName}
                onChange={handleChange}
              />
            </div>
            <div
              onClick={() => {
                if (userName?.trim()?.length > 0) {
                  handleRegister();
                } else {
                  alert('User Name Required !!');
                }
              }}
              className="save-btn"
            >
              {'Save changes'}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditName;
