/* eslint-disable */

import React, { useRef, useState } from "react";
import styles from "../../pages/UnifiedHome/styles/about-us.module.css";
import NavBar from "./NavBar";
import imageOne from "../../assets/common-home/about-us/image-one.jpg";
import teacherEmpowerment from "../../assets/common-home/about-us/teacher_empower.svg";
import studentCentricity from "../../assets/common-home/about-us/student_centricity.svg";
import parentPartnership from "../../assets/common-home/about-us/parent_partnership.svg";
import Naga from "../../assets/common-home/about-us/naga.svg";
import Raj from "../../assets/common-home/about-us/raj.svg";
import { aboutUs } from "./constants";
import { useNavigate } from "react-router-dom";
import {
  GOLDEN_ANIMATION_OPTIONS,
  MAX_MOBILE_WIDTH,
  STUDENT_URL,
} from "./helpers";
import { useMediaQuery } from "react-responsive";
import Lottie from "react-lottie";
import { useEffect } from "react";
import { routesLinks } from "../../utils/RouteConstants";
import BottomNav from "./BottomNav";

export default function AboutUs() {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });

  return (
    <div className={styles.container}>
      <NavBar />
      <img src={imageOne} className={styles.imageOne} />
      <div className={`${styles["columnCenter"]} ${styles["blockGap"]}`}>
        <BlockOne isMobile={isMobile} />
        <BlockTwo />
        <BlockThree />
        <BlockFour />
      </div>
      <BottomNav />
    </div>
  );
}

const BlockOne = ({ isMobile }) => {
  const { title, text1, text2, text3 } = aboutUs.blockOne;
  const lottieRef = useRef(null);
  useEffect(() => {
    if (lottieRef.current) {
      const animationInstance = lottieRef.current.anim;
      if (animationInstance) {
        animationInstance.setSpeed(0.15);
      }
    }
  }, []);

  return (
    <div className={styles.blockOne}>
      <div className={styles.blockOneLeft}>
        {!isMobile && (
          <Lottie
            options={GOLDEN_ANIMATION_OPTIONS}
            width={382}
            height={282}
            ref={lottieRef}
            speed={0.15}
          />
        )}
      </div>
      <div className={styles.blockOneRight}>
        <TitleText title={title} />
        <label className={styles.blockOneText}>{text1}</label>
        <label className={styles.blockOneText}>{text2}</label>
        <label className={styles.blockOneText}>{text3}</label>
      </div>
    </div>
  );
};

const BlockTwo = () => {
  return (
    <div className={`${styles.blockTwo} ${styles.columnCenter}`}>
      <TitleText title={aboutUs.titles.coschoolFormula} />
      <div className={styles.blockTwoContainer}>
        <BlockTwoItem
          image={teacherEmpowerment}
          info={aboutUs.blockTwo.sectionOne}
          index={1}
        />
        <BlockTwoItem
          image={studentCentricity}
          info={aboutUs.blockTwo.sectionTwo}
          index={2}
        />
        <BlockTwoItem
          image={parentPartnership}
          info={aboutUs.blockTwo.sectionThree}
          index={3}
        />
      </div>
    </div>
  );
};

const BlockThree = () => {
  const { imageOne, imageTwo, content } = aboutUs.blockThree;
  return (
    <div className={`${styles.blockThree} ${styles.columnCenter}`}>
      <TitleText title={aboutUs.titles.leadersLegacy} />
      <div className={`${styles.blockThreeImageBox}`}>
        <BlockThreeItem image={Naga} info={imageOne} />
        <BlockThreeItem image={Raj} info={imageTwo} />
      </div>
      <label className={styles.blockThreeContent}>{content}</label>
    </div>
  );
};

const BlockFour = () => {
  const { desc, cardOne, cardTwo, cardThree } = aboutUs.blockFour;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${routesLinks.careers}`);
  };

  return (
    <div className={`${styles.blockFour} ${styles.columnCenter}`}>
      <TitleText title={aboutUs.titles.nextChapter} />
      <label className={styles.blockFourDesc}>{desc}</label>
      <TitleText title={aboutUs.titles.revolText} showLine={false} />
      <div className={styles.blockFourCards}>
        <BlockFourCard info={cardTwo} />
        <BlockFourCard info={cardThree} />
        <BlockFourCard info={cardOne} />
      </div>
      <div className={styles.bottomCard}>
        <label className={styles.bottomCardTitle}>
          {aboutUs.titles.careerOpportunities}
        </label>
        <div className={styles.btnContainer} onClick={handleClick}>
          <label className={styles.btn}>{aboutUs.buttons.careerPage}</label>
        </div>
      </div>
    </div>
  );
};

const TitleText = ({ title, showLine = true }) => {
  let style = styles["line-container"];
  if (!showLine) {
    style += ` ` + styles["revol-text"];
  }
  let isFirst = aboutUs.blockOne.title == title;
  return (
    <div className={style} style={{ zIndex: 1 }}>
      {showLine && (
        <div
          className={styles["rounded-circle"]}
          style={{ backgroundColor: isFirst ? "#FFBFB7" : "#bedda0" }}
        />
      )}
      <label className={styles.headerText} style={{ zIndex: 2 }}>
        {title}
      </label>
      {showLine && <HorizontalLine />}
    </div>
  );
};
function HorizontalLine() {
  return <div className={styles.line} />;
}

const BlockTwoItem = ({ image, info }) => {
  return (
    <div className={`${styles.blockTwoItem} ${styles.columnCenter}`}>
      <img src={image} className={styles.teacherEmp} />
      <div className={styles.blockTwoTextContainer}>
        <label className={styles.blockTwoHeader}>{info.title}</label>
        <label className={styles.blockTwoText}>{info.text}</label>
      </div>
    </div>
  );
};

const BlockThreeItem = ({ image, info = {} }) => {
  const { name, role, quote } = info;
  return (
    <div className={`${styles.blockThreeImage} ${styles.columnCenter}`}>
      <img src={image} className={styles.naga} />
      <div className={styles.columnCenter}>
        <label className={styles.name}>{name}</label>
        <label className={styles.role}>{role}</label>
      </div>
      <label className={styles.quote}>{quote}</label>
    </div>
  );
};

const BlockFourCard = ({ info }) => {
  const navigate = useNavigate();
  const { title, btnTxt, path, index } = info;
  let isHovered = index == 3;
  let itemStyle = `${styles.blockFourItem} ${
    isHovered ? styles.blockFourItemHover : ""
  }`;
  let btnStyle = `${styles.btn} ${isHovered ? styles.btnHover : ""}`;
  const handleNavigate = (path) => {
    if (path == "") {
      window.location.href = STUDENT_URL;
    }
    navigate(path);
  };
  return (
    <div className={itemStyle}>
      <label className={styles.cardTitle}>{title}</label>
      <div
        className={styles.btnContainer}
        onClick={() => {
          handleNavigate(path);
        }}
      >
        <label className={btnStyle}>{btnTxt}</label>
      </div>
    </div>
  );
};
