import React, { useState, useEffect, useRef } from 'react';
import Parser from 'html-react-parser';
import ISButton from './ISButton';
import Loader from '../assets/OutputLoader.png';
import WhatsappIcon from '../assets/whatsapp/whatsapp.png';
import { fetchStreamingResponse, disconnectSocket } from '../apis/streaming';
import { useAuth } from '../context';
import Login from './Login';
import Logo from '../assets/logo.png';
import { sendToWhatsapp } from '../apis/callAPi';
import { strings } from '../utils/strings';
import { useCookies } from 'react-cookie';
import { updatingCookies } from '../utils/common-utils';
import Question from '../assets/question.svg';

import '../styles/streaming.css';
//let timeoutId;

const Streaming = ({
  syllubusMapping,
  categoryId,
  prompt,
  screenType,
  isComplete,
  setIsComplete,
  promptData = [],
  handleError,
  handleReset,
  handleCancel,
  setHitCount,
  setDisplayButton,
  displayButton,
}) => {
  const divRef = useRef(null);
  const isCancelled = useRef(false);

  const isUserScrolled = useRef(false);
  const { userInfo } = useAuth();
  let isPromptPage = screenType == strings?.titles?.prompt;
  const isQuestionBank = screenType == strings.titles.question_paper;

  const [streamingText, setStreamingText] = useState('');
  const [bulkData, setBulkData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [isVisibleWhatsupStatus, setIsVisibleWhatsappStatus] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (handleCancel) {
      isCancelled.current = true;
      setBulkData([]);
      setIsComplete(false);
      disconnectSocket();
    } else {
      isCancelled.current = false;
    }
  }, [handleCancel]);

  useEffect(() => {
    const container = divRef.current;
    if (!isUserScrolled.current) {
      container.scrollTop = container.scrollHeight;
    }
  }, [streamingText]);

  useEffect(() => {
    const container = divRef.current;
    container.scrollTop = container.scrollHeight;
    container.addEventListener('scroll', function () {
      const isAtBottom =
        container.scrollHeight - container.clientHeight <=
        container.scrollTop + 5;
      if (!isAtBottom) {
        isUserScrolled.current = true;
      } else {
        isUserScrolled.current = false;
      }
    });
  }, []);

  useEffect(() => {
    if (handleReset && isComplete == false && streamingText?.length > 0) {
      setStreamingText('');
      setBulkData([]);
      disconnectSocket(socket);
    }
  }, [isComplete, handleReset]);
  useEffect(() => {
    return () => {
      disconnectSocket(socket);
    };
  }, [socket]);
  useEffect(() => {
    if (isComplete) {
      setError('');
      handleError && handleError('');
      setStreamingText('');
      setBulkData([]);
      if (isPromptPage) {
        setBulkData(promptData?.length > 0 && promptData?.split(' '));
      } else {
        handleStreaming();
      }
    }
  }, [isComplete]);

  useEffect(() => {
    if (bulkData.length > 0) {
      let { REACT_APP_WORD_SPEED, REACT_APP_WORDS_COUNT } = process.env;
      let speed = REACT_APP_WORD_SPEED ? parseInt(REACT_APP_WORD_SPEED) : 200;
      const count = REACT_APP_WORDS_COUNT ? parseInt(REACT_APP_WORDS_COUNT) : 1;
      let currentText = '';
      let index = 0;
      setIsComplete(true);
      const interval = setInterval(() => {
        if (!isCancelled.current) {
          if (index < bulkData.length) {
            let chunk = bulkData.slice(index, index + count).join(' ');
            currentText += chunk + ' ';
            setStreamingText(currentText);
            index += count;
          } else {
            clearInterval(interval);
            setIsComplete(false);
            setBulkData([]);
          }
        }
      }, speed);
      return () => clearInterval(interval);
    }
  }, [bulkData]);

  const handleStreamingText = (txt) => {
    txt = txt.replace(/\n/g, '\\n');
    setStreamingText((prevData) => prevData + txt);
  };
  const handleRedisData = (data) => {
    data = data?.split(/\s+/);
    setBulkData(data);
  };

  const handleStreaming = async () => {
    let body = {
      filter: syllubusMapping,
      categoryId,
      prompt,
    };
    //updating ccokies
    if (userInfo?.name == undefined) {
      let noOfAttempts = parseInt(cookies['myCookies']?.noOfAttempts || 0);
      noOfAttempts += 1;
      updatingCookies(setCookie, noOfAttempts);
    }
    handleError && handleError('');
    let response = await fetchStreamingResponse(
      handleStreamingText,
      handleRedisData,
      setIsComplete,
      body,
      screenType,
      setSocket,
      setHitCount,
    );
    if (response?.status == '403' || response?.status == '429') {
      handleError && handleError(response?.message || '');
    }
  };

  const renderContent = (content) => {
    let titleHeader = `<div style="font-size:18px;font-weight:bold;margin-top:8px;margin-bottom:8px">`;
    content = content
      ?.replace(/<prompt>/gi, titleHeader)
      ?.replace(/<\/prompt>/gi, '</div>');
    return <div className="stream">{Parser(content)}</div>;
  };
  const getTitle = () => {
    let title = strings.curiosity.streaming_title;
    if (
      screenType == strings.titles.curiosity_triggers ||
      screenType == strings.titles.learning_activities
    ) {
      title = `Here are your ${syllubusMapping?.variation} ${screenType}`;
    } else if (screenType == strings.titles.lesson_plan) {
      title = `Here is your ${syllubusMapping?.variation || ''} ${screenType}`;
    } else if (screenType == strings.titles.engagement_tips) {
      title = `Here is your Engagement Tip:`;
    } else if (screenType == strings.titles.question_paper) {
      title = `Here are your Classroom Questions:`;
    } else if (screenType == strings.titles.prompt) {
      title = `Here is your Sample Output:`;
    }

    return title;
  };
  const getFileName = (screenType) => {
    let fileName;
    if (
      screenType == strings.titles.curiosity_triggers ||
      screenType == strings.titles.learning_activities
    ) {
      fileName = screenType;
      fileName += '-' + syllubusMapping?.topic?.name || '';
      fileName += '-' + syllubusMapping?.grade?.name || '';
      fileName += '-' + syllubusMapping?.variation || '';
    } else if (screenType == strings.titles.lesson_plan) {
      fileName = screenType;
      fileName += '-' + syllubusMapping?.topic?.name || '';
      fileName += '-' + syllubusMapping?.grade?.name || '';
      // fileName += '-' + syllubusMapping?.variation || '';
    } else if (screenType == strings.titles.engagement_tips) {
      fileName = screenType;
      //   fileName += '-' + syllubusMapping?.topic?.name || '';
      fileName += '-' + syllubusMapping?.gradeName || '';
    } else if (screenType == strings.titles.prompt) {
      fileName = screenType;
    }
    return fileName;
  };
  const handleShare = async () => {
    setError('');
    let { success, data } = await sendToWhatsapp(
      streamingText,
      `${getFileName(screenType)}.pdf`,
      syllubusMapping,
      screenType,
    );
    setShowLogin(false);
    if (success) {
      setIsVisibleWhatsappStatus(true);
      setTimeout(() => {
        setIsVisibleWhatsappStatus(false);
        setDisplayButton(true);
      }, [5000]);
    } else {
      setError(data?.message || '');
    }
  };
  // const updateStreaming = (newText) => {
  //   setStreamingText((prevData) => prevData + ' ' + newText);
  // };
  const handleWhatsappShare = () => {
    if (userInfo?.name) {
      handleShare();
    } else {
      setShowLogin(true);
    }
  };
  let isEmptyContent = isComplete == false && streamingText?.length == 0;
  let isStarted = isComplete == true && streamingText?.length == 0;
  let isCompleted =
    isComplete == false && streamingText?.length > 0 && !handleCancel;
  // let isVisibleBottomSection = streamingText?.length > 0;
  if (bulkData?.length > 0) {
    isCompleted =
      isComplete == false &&
      streamingText?.length == bulkData?.length &&
      !handleCancel;
  }
  useEffect(() => {
    let promptCheck = screenType?.toLowerCase()?.includes('prompt');
    if (promptCheck && bulkData?.length === 0 && isCompleted) {
      setDisplayButton(false);
    } else if (
      !promptCheck &&
      socket &&
      bulkData?.length === 0 &&
      isCompleted
    ) {
      setDisplayButton(false);
    } else {
      setDisplayButton(true);
    }
  }, [bulkData, socket, isComplete, handleCancel]);
  const send_section = () => {
    let messageForm =
      screenType?.toLowerCase()?.includes('prompt') ||
      screenType?.toLowerCase()?.includes('lesson')
        ? { adjective: 'has', pronoun: 'is' }
        : { adjective: 'have', pronoun: 'are' };
    return (
      <>
        {isCompleted && !displayButton && (
          <div>
            <div>
              <ISButton
                label="WhatsApp Me"
                imageSrc={WhatsappIcon}
                imagePosition="right"
                containerStyle={
                  isCompleted ? 'active-task-btn' : 'inactive-btn'
                }
                handleClick={() => {
                  isCompleted && handleWhatsappShare();
                }}
                isEnable={isComplete == false && !handleCancel}
              />
              {!isVisibleWhatsupStatus && (
                <p className="highlight">
                  {' '}
                  {`Your ${screenType} ${messageForm?.adjective} been generated and ${messageForm?.pronoun} now available for you to download to WhatsApp.`}{' '}
                </p>
              )}
            </div>
            <div>
              {isVisibleWhatsupStatus && error == '' && (
                <p className="highlight"> {strings.default.message_sent} </p>
              )}
              <span className="error">{error}</span>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div>
      {send_section()}
      <div className="main">
        {streamingText?.length > 0 && (
          <p className="streaming-title">{getTitle()}</p>
        )}
        <div
          className={isQuestionBank ? '' : 'content'}
          ref={divRef}
          style={{
            overflowY: streamingText?.length > 0 ? 'auto' : 'hidden',
            overflowX: streamingText?.length > 0 ? 'auto' : 'hidden',
          }}
        >
          {streamingText?.length > 0 && renderContent(streamingText)}
          {isStarted && (
            <div className="loader">
              <img src={Loader} alt="Loader" />
            </div>
          )}
          {isEmptyContent && !isQuestionBank && (
            <div className="ai-icon" style={{ opacity: 0.4 }}>
              <img src={Logo} alt={'logo'} width={'160px'} height={'48px'} />
            </div>
          )}
          {isQuestionBank && (
            <div className="empty-question">
              <img src={Question} alt="Question" style={{ opacity: 0.5 }} />
              <p className="empty-text">{`It's happening! Stay tuned.`}</p>
            </div>
          )}
        </div>
        {isCompleted && (
          <div style={{ paddingBottom: '6px' }}>
            <p
              className="streaming-title"
              style={{ fontSize: '14px' }}
            >{`*This is Ai generated. Teachers are advised to verify before using.`}</p>
          </div>
        )}

        <Login
          show={showLogin}
          onHide={() => {
            setShowLogin(false);
          }}
          handleShare={() => {
            handleShare();
          }}
        />
      </div>
    </div>
  );
};

export default Streaming;
