// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getPreferences } from './apis/pereferences';
import { useCookies } from 'react-cookie';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [userInfo, setUserInfo] = useState({});
  const [cookies, setCookie] = useCookies();

  useEffect(async () => {
    getUserDetails();
    userInfo?.name && updateUserPreferences();
  }, []);

  const updateUserPreferences = async () => {
    let { success, data } = await getPreferences();
    if (success) {
      console.log(cookies);
      let { curriculum, grade, subject } = data;
      let body = {
        curriculum: {
          id: curriculum?.id,
          name: curriculum?.name,
        },
        grade: {
          id: grade?.id,
          name: grade?.name,
        },
        subject: {
          id: subject?.id,
          name: subject?.name,
        },
      };
      setCookie('preferences', body, { path: '/' });
    }
  };

  const getUserDetails = async () => {
    let data = await localStorage.getItem('userInfo');
    data = JSON.parse(data);
    setUserInfo(data);
    //for existing users if refresh token not available, we are doing explicitly logout
    if (data?.refreshToken == undefined) {
      setUserInfo({});
    }
  };

  const logout = async () => {
    await localStorage.clear();
    setUserInfo({});
  };

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
