import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import MenuBar from '../components/MenuBar';
import './layout.css';
import BGVideo from '../assets/shadow.mp4';
import Feedback from '../components/Feedback';
import Login from '../components/Login';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context';
import { routesLinks } from '../utils/RouteConstants';

const Layout = ({
  children,
  backgroundImage,
  show = false,
  isLoginRoute = false,
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  let styleComponent;

  const [isLogin, setIsLogin] = useState(isLoginRoute);

  if (backgroundImage !== 'home' && backgroundImage != undefined) {
    styleComponent = 'page-styling';
  }

  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    if (isLoginRoute) {
      logout();
    }
    const videoElement = document.getElementById('background-video');
    if (videoElement) {
      videoElement.oncanplaythrough = () => {
        setVideoLoaded(true);
      };
    }
  }, []);

  return (
    <Container
      fluid
      className={styleComponent}
      style={
        backgroundImage == 'home'
          ? {
              backgroundColor: videoLoaded ? 'transparent' : '#262626',
            }
          : { backgroundColor: backgroundImage == undefined && '#262626' }
      }
    >
      <MenuBar />
      <main>{children}</main>
      <Footer />
      {backgroundImage != undefined && backgroundImage != 'other' && (
        <video
          id="background-video"
          className="background-video"
          autoPlay
          loop
          muted
          playsInline
          style={{ display: videoLoaded ? 'block' : 'none' }}
        >
          <source src={BGVideo} type="video/mp4" />
        </video>
      )}
      <Feedback show={show} />
      <Login
        show={isLogin}
        onHide={() => {
          setIsLogin(false);
          navigate(routesLinks.teacher);
        }}
      />
    </Container>
  );
};

export default Layout;
