import { useEffect } from 'react';

function ScrollToTop({ location }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Listen for changes in location

  return null;
}

export default ScrollToTop;
