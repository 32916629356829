import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import curiosityTriggers from '../assets/curiosityTrigger.svg';
import LessonPlanImage from '../assets/lesson.svg';
import TipsImage from '../assets/engagemenTips.svg';
import QuestionImage from '../assets/question.svg';
import LearningImage from '../assets/activities.png';
import { getCategoriesData } from '../apis/callAPi';
import { STRINGS } from '../utils/CONSTANTS';
import '../styles/home-web.css';
import HomeWeb from './HomeWeb';
import { useMediaQuery } from 'react-responsive';
import { ALLNAMES } from '../utils/NAMES';
import HomeMobileView from './HomeMobileView';
import { strings } from '../utils/strings';
import { useLocation } from 'react-router-dom';
import { saveBrowserDetails } from '../utils/common-utils';

function HomePage() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    fetchCategoriesData();
  }, []);
  const fetchCategoriesData = async () => {
    saveBrowserDetails(searchParams);

    const response = await getCategoriesData();
    if (response?.success) {
      let res = response?.data?.map((item) => {
        if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.curiosity_triggers?.name)
        ) {
          return {
            ...item,
            image: curiosityTriggers,
            action: STRINGS?.home?.curiosity_triggers?.action,
            navigation: STRINGS?.home?.curiosity_triggers?.navigation,
            animation: STRINGS?.home?.curiosity_triggers?.animation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.question_bank?.name)
        ) {
          return {
            ...item,
            image: QuestionImage,
            action: STRINGS?.home?.question_bank?.action,
            navigation: STRINGS?.home?.question_bank?.navigation,
            animation: STRINGS?.home?.question_bank?.animation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.lesson_plan?.name)
        ) {
          return {
            ...item,
            image: LessonPlanImage,
            action: STRINGS?.home?.lesson_plan?.action,
            navigation: STRINGS?.home?.lesson_plan?.navigation,
            animation: STRINGS?.home?.lesson_plan?.animation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.learning_activities?.name)
        ) {
          return {
            ...item,
            image: LearningImage,
            action: STRINGS?.home?.learning_activities?.action,
            navigation: STRINGS?.home?.learning_activities?.navigation,
            animation: STRINGS?.home?.learning_activities?.animation,
          };
        } else if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.student_tips?.name)
        ) {
          return {
            ...item,
            image: TipsImage,
            action: STRINGS?.home?.student_tips?.action,
            navigation: STRINGS?.home?.student_tips?.navigation,
            animation: STRINGS?.home?.student_tips?.animation,
          };
        }
      });

      // Find the index of the object to move
      const indexToMove = res.findIndex(
        (obj) => obj.displaytext === strings.titles.question_paper,
      );
      let finalArray = [];
      if (indexToMove !== -1) {
        finalArray = [
          ...res.slice(0, indexToMove),
          ...res.slice(indexToMove + 1),
          res[indexToMove],
        ];
      }
      // setCategories(finalArray);
      let first = finalArray?.filter(
        (item) => item.displaytext != 'Lesson Plan',
      );
      let second = finalArray?.filter(
        (item) => item.displaytext == 'Lesson Plan',
      );
      finalArray = [...second, ...first];
      setCategories(finalArray);
    }
  };
  return (
    <Container>
      <div className="main-heading">{ALLNAMES?.AIPOWERED}</div>
      <div className="sub-heading">{ALLNAMES?.EMPOWRING}</div>
      {isDesktop && <HomeWeb categories={categories} />}
      {!isDesktop && <HomeMobileView categories={categories} />}
    </Container>
  );
}

export default HomePage;
