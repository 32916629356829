import React, { useState } from 'react';
import '../styles/web-card.css';
import Arrow from '../assets/arrow/arrow.png';
import { useNavigate } from 'react-router-dom';
import { STRINGS } from '../utils/CONSTANTS';

const WebCard = ({ item, type, setShowModal }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      className={`${
        type == 'ai assistant' ? 'assistant-card' : 'ai-card'
      } position-relative`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        if (
          item?.displaytext
            ?.toLowerCase()
            .includes(STRINGS?.home?.question_bank?.name)
        ) {
          setShowModal(true);
        } else {
          navigate(`${item?.navigation}`);
        }
      }}
      style={{ cursor: 'pointer', userSelect: 'none' }}
    >
      <img
        src={item.image}
        alt="Image"
        className={`${
          type == 'ai assistant' ? 'assistant-card-img-top' : 'card-img-top'
        }`}
      />
      <div className="card-body">
        <h5
          className={`${
            type == 'ai assistant' ? 'assistant-card-title' : 'card-title'
          }`}
        >
          {item?.displaytext}
        </h5>
        <p
          className={`${
            type == 'ai assistant' ? 'assistant-card-text' : 'card-text'
          }`}
        >
          {item?.description}
        </p>
        {isHovered && (
          <div className="hover-content-inner">
            <span
              className={`${
                type == 'ai assistant' ? 'assistant-hover-text' : 'hover-text'
              }`}
            >
              {item?.action}
            </span>
            <img src={Arrow} alt="Image" className="icon" />
          </div>
        )}
      </div>
    </div>
  );
};
export default WebCard;
