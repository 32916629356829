/* eslint-disable */
import React, { useState } from "react";
import NavBar from "../../../components/UnifiedHome/NavBar";
import styles from "./styles/terms-and-conditions.module.css";
import QuestionIcon from "../../../assets/common-home/question.svg";
import ContactUsCard from "./contactus-card";
import TermsContent from "./terms-content";

export default function TermsAndConditions() {
  // State to manage the visibility of the ContactUsCard
  const [isContactCardVisible, setContactCardVisible] = useState(false);

  return (
    <div className={styles.container}>
      <NavBar />
      <BreadcrumbNav />
      <TermsContent />
      {isContactCardVisible && (
        <div className={styles["contactus-card"]}>
          <ContactUsCard />
        </div>
      )}
      <FloatingIcon
        onClick={() => {
          setContactCardVisible((prev) => !prev);
        }}
      />
    </div>
  );
}

const BreadcrumbNav = () => {
  const date = `Last updated: August 8, 2024`;
  return (
    <div className={styles["breadcrumb"]}>
      <div className={styles["breadcrumb-content"]}>
        <div className={styles["breadcrumb-left"]}>
          <span>Home</span>
          {"  |   "}
          <span className={styles["active-link"]}>Terms of service</span>
        </div>
        <div className={styles["breadcrumb-right"]}>{date}</div>
      </div>
      <hr className={styles["breadcrumb-line"]} />
    </div>
  );
};

const FloatingIcon = ({ onClick }) => {
  return (
    <div className={styles["floating-icon"]} onClick={onClick}>
      <img src={QuestionIcon} alt="Question Icon" />
    </div>
  );
};
